import { Grid, FormLabel, Typography, Button, Card } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BonusAmountTypeEnum } from '../../../../enum/bonus/bonus-type-amount-enum';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
    padding: 5,
    marginBottom: '3rem',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
  },
  title2: {
    color: 'white', //theme.palette.secondary.main,
    textAlign: 'left',
    marginLeft: '.5rem',
    marginTop: '.5rem',
    fontSize: '1.3rem',
  },
  card: {
    width: '100%',
    padding: '20px 0px',
    margin: '0px 0px',
    textAlign: 'center',
  },
  icon: theme.typography.h6,
  bonusSection: {
    width: '100%',
    padding: '0px 0px',
    textAlign: 'left',
    backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/login-bg.jpg` + ')',
    backgroundSize: 'cover',
  },
  bonusAmountTitles: {
    fontSize: '.60rem',
    textAlign: 'center',
  },
  bonusAmounts: {
    textAlign: 'center',
    padding: '1rem',
    backgroundColor: '#2d2d2d',
  },
  green: {
    color: 'green',
  },
  red: {
    color: 'red',
  },
  remainingRolloverWhite: {
    fontWeight: theme.typography.fontWeightBold,
    color: 'grey',
  },
  remainingRolloverRed: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#f00',
  },
}));

export interface ExternalProps {
  playerNumber: string;
  rollover: any;
}

export interface ExternalActionProps {
  handleOpen: (data: any) => void;
  handleContOpen: (data: any) => void;
}

export const RolloverTransactionComponent = (props: ExternalActionProps & ExternalProps) => {
  const { playerNumber, rollover, handleOpen, handleContOpen } = props;
  const classes = useStyles();
  return (
    <>
      <Grid className='vip-fadeIn'>
        <Card
          classes={{
            root: clsx(classes.bonusSection),
          }}
        >
          <Grid item xs={12} style={{ padding: '5px 10px' }}>
            <FormLabel component='legend'>
              <Typography variant='h4' classes={{ root: classes.title2 }}>
                {playerNumber}
                {rollover != null && (
                  <>
                    <br />
                    <Typography color='textSecondary'>Bonus Status</Typography>
                  </>
                )}
              </Typography>
            </FormLabel>

            <Grid container>
              <Grid
                item
                xs={6}
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '.5rem',
                  textAlign: 'left',
                  verticalAlign: 'text-bottom',
                }}
              >
                {rollover != null && Object.keys(rollover).length !== 0 && (
                  <>
                    {rollover.bonus.category == 'gradedgames' && (
                      <>
                        <Typography className={classes.remainingRolloverRed} gutterBottom>
                          Graded Wager Enabled
                        </Typography>
                        <small>
                          {`Guaranteed! If any of these bets do not win, you will receive up to $${
                            rollover.bonus.type_amount_id == BonusAmountTypeEnum.FIXED
                              ? rollover.bonus.match_multiplier * 100
                              : rollover.bonus.match_multiplier * rollover.bonus.max_deposit
                          } in freeplay.`}
                        </small>
                      </>
                    )}

                    {rollover.bonus.category == 'riskfreebet' && (
                      <>
                        <Typography className={classes.remainingRolloverRed} gutterBottom>
                          Risk Free Wager Enabled
                        </Typography>
                        <small>
                          {`Place your wager if it loses you will be credited back the full risk amount up to $${
                            rollover.bonus.type_amount_id == BonusAmountTypeEnum.FIXED
                              ? rollover.bonus.match_multiplier * 100
                              : rollover.bonus.match_multiplier * rollover.bonus.max_deposit
                          }. You must wager the
                          deposit or bonus money prior to cashing out!`}
                        </small>
                      </>
                    )}
                    {rollover.bonus.category == 'deposit' && (
                      <>
                        <Typography variant='h4' className={classes.remainingRolloverRed}>
                          ${rollover.remaining_rollover}
                        </Typography>
                        <Typography color='textSecondary' gutterBottom>
                          Remaining Rollover
                        </Typography>
                        {rollover.bonus.description.includes('Risk Free') && (
                          <small>Upon claiming a bonus, you must play amount equal to deposit + bonus money to cash out.</small>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>

              {rollover !== null && (
                <Grid
                  item
                  xs={6}
                  style={{
                    paddingTop: '3.4rem',
                    paddingBottom: '.5rem',
                    textAlign: 'right',
                  }}
                >
                  <Typography variant='subtitle2' gutterBottom>
                    Claimed Date:
                    <br />
                    {rollover.start_date}
                  </Typography>
                </Grid>
              )}

              {rollover != null && rollover?.category == 'deposit' && (
                <Grid item xs={12} style={{ width: '100%' }}>
                  <Button
                    variant='contained'
                    color='primary'
                    style={{
                      marginTop: '.5rem',
                      float: 'right',
                      marginLeft: '.5rem',
                    }}
                    onClick={handleOpen}
                  >
                    View Wagers
                  </Button>
                  <Button variant='outlined' color='default' style={{ marginTop: '.5rem', float: 'right' }} onClick={handleContOpen}>
                    Contribution Table
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={4} className={classes.bonusAmounts}>
              <Typography variant='subtitle1' color='textSecondary' className={classes.bonusAmountTitles}>
                Starting Rollover
              </Typography>
              {rollover != undefined && rollover.rollover_data != undefined ? (
                <>
                  {rollover.bonus.category == 'gradedgames' && <Typography variant='h6'>$0.00</Typography>}
                  {rollover.bonus.category == 'riskfreebet' && <Typography variant='h6'>$0.00</Typography>}
                  {rollover.bonus.category == 'deposit' && <Typography variant='h6'>${rollover.starting_rollover}</Typography>}
                </>
              ) : (
                <Typography variant='h6'>$0</Typography>
              )}
            </Grid>
            <Grid item xs={4} className={classes.bonusAmounts}>
              <Typography variant='subtitle1' color='textSecondary' className={classes.bonusAmountTitles}>
                Free Play Winnings
              </Typography>
              {rollover != undefined && rollover.rollover_data != undefined ? (
                <Typography variant='h6'>$ {rollover.free_play_winnings}</Typography>
              ) : (
                <Typography variant='h6'>$0</Typography>
              )}
            </Grid>
            <Grid item xs={4} className={classes.bonusAmounts}>
              <Typography variant='subtitle1' color='textSecondary' className={classes.bonusAmountTitles}>
                Amount Wagered
              </Typography>
              {rollover != undefined && rollover.rollover_data != undefined ? (
                <Typography variant='h6'>$ {rollover.amount_wagered}</Typography>
              ) : (
                <Typography variant='h6'>$0</Typography>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};
