import { CasinoBonusesDto } from '../../models/CasinoLobbyV2/CasinoBonusesDto';
import { GameFavoriteResponseDto, GameRatingDto } from '../../models/CasinoLobbyV2/CasinoLobbyDto';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'tpiSlice';

export interface tpiSliceType {
  isLoading: boolean;
  casinoGames?: any[] | null;
  casinoCategories?: any[] | null;
  gameUrl: string | null;
  liveWageringUrl?: string | null;
  eSportsWageringUrl?: string | null;
  dgsCasinoUrl?: string | null;
  horsesUrl?: string | null;
  gameRatings?: GameRatingDto[];
  gameFavoriteUpdated?: GameFavoriteResponseDto;
  casinoBonuses: CasinoBonusesDto[];
  casinoBonus: CasinoBonusesDto | null;
  casinoLobby: any | null;
}

export const initialState: tpiSliceType = {
  isLoading: false,
  casinoGames: [],
  casinoCategories: [],
  gameUrl: '',
  liveWageringUrl: '',
  eSportsWageringUrl: '',
  dgsCasinoUrl: '',
  casinoBonuses: [],
  casinoBonus: null,
  casinoLobby: null,
};

export const tpiSliceReducer = createAndMergeSliceReducer(sliceName, initialState);
