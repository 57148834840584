import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  details: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
});

export interface ExternalProps {
  transactionRequest: any;
}

export default function WithdrawalPendingCard(props: ExternalProps) {
  const classes = useStyles();
  const { transactionRequest } = props;

  return (
    <Card>
      <div className={classes.details}>
        <CardContent>
          <Typography component='h6' variant='h6' gutterBottom>
            Your withdrawal is now processing.
            <br />
          </Typography>
          <Typography variant='subtitle1' gutterBottom>
            {transactionRequest.method == '9' && transactionRequest.amount > 1000 && (
              <>
                <Typography>
                  <b>
                    Your (Instant) Pay withdrawal of $1000 will be instantly sent. The remaining amount will need to be approved by staff.
                  </b>
                </Typography>
              </>
            )}
            <br />
            You will receive a text message when <br />
            your withdrawal has been completed, Thank you. <br />
          </Typography>
          <Typography variant='subtitle1' gutterBottom>
            You may leave this page. <br />
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
}
