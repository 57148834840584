import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { API_PATH } from '../../../../constants';
import { ICognitoReTryFlowSession } from '../../../../models/users';
import { mergeSaga } from '../../../../utils/redux-saga/merge-saga';
import { showToast } from '../../../../utils/toast/show-errors';
import { vipApi } from '../../../../utils/vip-api';
import { userReducer, UserSliceType } from '../../user.reducer';
import { getUserInfoAction } from '../get-user-info.action';
import { toggleVerifyIdentityDialogAction } from '../toggle-verify-identity-dialog.action';

const suffix = `/${userReducer.sliceName}/app`;

const COGNITO_RETRY_FLOW = `COGNITO_RETRY_FLOW${suffix}`;
export const createCognitoRetryAction = createAction(COGNITO_RETRY_FLOW);

const COGNITO_RETRY_FLOW_ERROR = `COGNITO_RETRY_FLOW_ERROR${suffix}`;
const createCognitoRetryErrorAction = createAction<string>(COGNITO_RETRY_FLOW_ERROR);
const COGNITO_RETRY_FLOW_SUCCESS = `COGNITO_RETRY_FLOW_SUCCESS${suffix}`;
const createCognitoRetrySuccessAction = createAction<ICognitoReTryFlowSession>(COGNITO_RETRY_FLOW_SUCCESS);

function* createCognitoRetryWatcher(): SagaIterator {
  yield takeEvery(COGNITO_RETRY_FLOW, createCognitoRetryWorker);
}
mergeSaga(createCognitoRetryWatcher);

function* createCognitoRetryWorker(): SagaIterator {
  try {
    const result = yield call(getApiPlayer);
    const new_window = window.open(result.data.url, 'cognito_tab');
    result.data.new_window = new_window;

    yield put(createCognitoRetrySuccessAction(result.data));
    yield put(
      toggleVerifyIdentityDialogAction({
        open: false,
        message: null,
        result: false,
      }),
    );
    yield put(getUserInfoAction());
  } catch (e: any) {
    yield call(showToast, e.response.data);

    if (e.response && e.response.data && e.response.data.statusCode && e.response.data.statusCode == -3) {
      yield put(
        toggleVerifyIdentityDialogAction({
          open: true,
          message: e.response.data.messages[0].msg,
          result: false,
        }),
      );
    }
    yield put(createCognitoRetryErrorAction(e.response.data));
  }
}

const getApiPlayer = (): Request => {
  return vipApi(API_PATH.USER.COGNITO_CREATE_RETRY_FLOW_SESSION, 'post', {});
};

const reduceHandlers = {
  [COGNITO_RETRY_FLOW]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [COGNITO_RETRY_FLOW_SUCCESS]: (slice: UserSliceType, action: Action<ICognitoReTryFlowSession>): UserSliceType => {
    console.log('action', action);

    return {
      ...slice,
      isLoading: false,
      shareable_url: action.payload.url,
      window_opened: action.payload.new_window ?? null,
    };
  },
  [COGNITO_RETRY_FLOW_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
    shareable_url: null,
    window_opened: null,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
