import React from 'react';
// import videojs from 'video.js';
import VREPlayer from 'videojs-react-enhanced';
import 'video.js/dist/video-js.css';

export interface PlayerProps {
  url: string;
}

export function Player({ url }: PlayerProps) {
  const playerOptions: VREPlayer.IPlayerOptions = {
    src: url == '' ? 'https://tvproj.live/channel1/prog_index.m3u8?i=1629860563052' : url,
    controls: true,
    autoplay: 'muted',
  };
  const videojsOptions: VREPlayer.IVideoJsOptions = {
    fluid: true,
  };
  // const resources = {
  //   sources: [
  //     {
  //       src: url == '' ? 'http://143.202.160.94/espn/prog_index.m3u?i=1629860563052' : url,
  //       type: 'application/x-mpegURL',
  //     },
  //   ],
  //   poster: 'https://vipclub.lv/logo.png',
  // };

  return (
    <VREPlayer
      playerOptions={playerOptions}
      videojsOptions={videojsOptions}
      // resources={resources}
      // onReady={player => console.log(player)}
      // onPlay={(e, _, second) => console.log('Play!')}
      // onPause={(e, _, second) => console.log('Pause!')}
      // onEnded={(e, _) => console.log('Ended!')}
    />
  );
}
