export interface IPlayerTransactionLimitProfile {
  id: number;
  name: string;
  limits: IPlayerTransactionLimit[];
}

export interface IPlayerTransactionLimit {
  id: number;
  profileId: number;
  transactionType: number;
  limit_type: number;
  quantity: number;
  amount: number;
}

export enum LimitTypeEnum {
  Hourly = 1,
  Daily = 2,
  Weekly = 3,
}
