import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { API_PATH } from '../../../constants';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const GET_BONUS_BY_CODE = `GET_BONUS_BY_CODE${suffix}`;

export const getBonusByCodeAction = createAction<any>(GET_BONUS_BY_CODE);

const GET_BONUS_BY_CODE_SUCCESS = `GET_BONUS_BY_CODE_SUCCESS${suffix}`;
const getBonusByCodeSuccessAction = createAction<any[]>(GET_BONUS_BY_CODE_SUCCESS);

const GET_BONUS_BY_CODE_ERROR = `GET_BONUS_BY_CODE_ERROR${suffix}`;
const getBonusByCodeErrorAction = createAction<any>(GET_BONUS_BY_CODE_ERROR);

function* getBonusByCodeWatcher(): SagaIterator {
  yield takeEvery(GET_BONUS_BY_CODE, getBonusByCodeWorker);
}
mergeSaga(getBonusByCodeWatcher);

function* getBonusByCodeWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getBonusByCodeApi, action.payload);
    yield put(getBonusByCodeSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(getBonusByCodeErrorAction(e));
  }
}

const getBonusByCodeApi = (data: any): Request => {
  return vipApi(API_PATH.CASHIER.GET_BONUS_BY_CODE, 'post', {
    code: data.code,
  });
};

const reduceHandlers = {
  [GET_BONUS_BY_CODE]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_BONUS_BY_CODE_SUCCESS]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    bonus: action.payload.data,
  }),
  [GET_BONUS_BY_CODE_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
