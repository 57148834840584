import { connect } from 'react-redux';
import { getDepositAccountsAction } from '../../../api/cashier/actions/get-deposit-accounts.action';
import { setTransactionAccountAction } from '../../../api/cashier/actions/set-transaction-account.action';
import { setTransactionAmountAction } from '../../../api/cashier/actions/set-transaction-amount.action';
import { setTransactionBonusAction } from '../../../api/cashier/actions/set-transaction-bonus.action';
import {
  accountSelector,
  accountsSelector,
  amountSelector,
  bonusSelector,
  cashierDialogShowSelector,
  methodSelector,
  typeSelector,
} from '../../../api/cashier/cashier.selector';
import { loggedInUserSelector } from '../../../api/user/user.selector';
import { TransactionInfoPage, ExternalProps, ExternalActionProps } from './transaction-info-page.component';
import { setCashierDialogAction } from '../../../api/cashier/actions/set-cashier-dialog.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    bonus: bonusSelector(state),
    type: typeSelector(state),
    method: methodSelector(state),
    account: accountSelector(state),
    amount: amountSelector(state),
    user: loggedInUserSelector(state),
    accounts: accountsSelector(state),
    cashierDialog: cashierDialogShowSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  setTransactionAccountAction,
  setTransactionAmountAction,
  setTransactionBonusAction,
  getDepositAccountsAction,
  setCashierDialogAction,
};

export const TransactionInfoPageContainer = connect(mapStateToProps, mapDispatchToProps)(TransactionInfoPage);
