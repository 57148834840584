import { Action, createAction } from 'redux-actions';
import { IIdentityDialog } from '../../../models/users';
import { userReducer, UserSliceType } from '../user.reducer';
const suffix = `/${userReducer.sliceName}/app`;

const TOGGLE_VERIFY_IDENTITY_DIALOG = `TOGGLE_VERIFY_IDENTITY_DIALOG${suffix}`;

export const toggleVerifyIdentityDialogAction = createAction<IIdentityDialog>(TOGGLE_VERIFY_IDENTITY_DIALOG);

const reduceHandlers = {
  [TOGGLE_VERIFY_IDENTITY_DIALOG]: (slice: UserSliceType, action: Action<IIdentityDialog>): UserSliceType => ({
    ...slice,
    identityDialog: action.payload,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
