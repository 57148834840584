import { connect } from 'react-redux';
import { CashierPage, ExternalProps, ExternalActionProps } from './cashier.component';
import { isLoadingSelector, loggedInUserSelector, userBalanceSelector } from './../../../api/user/user.selector';
import {
  cashierDialogShowSelector,
  fingerprintSelector,
  ipSelector,
  isLoadingSliceSelector,
  transactionLimitDetailsSelector,
  // wdHoldAmountSelector,
} from '../../../api/cashier/cashier.selector';
import { setTransactionIpAction } from '../../../api/cashier/actions/set-transaction-ip.action';
import { setTransactionTypeAction } from '../../../api/cashier/actions/set-transaction-type.action';
import { setTransactionBonusAction } from '../../../api/cashier/actions/set-transaction-bonus.action';
import { setTransactionFingerprintAction } from '../../../api/cashier/actions/set-transaction-fingerprint.action';
import { setNeedCashierFowardAction } from '../../../api/user/actions/set-need-cashier-forward.action';
import { getUserInfoAction } from '../../../api/user/actions/get-user-info.action';
import { setBankReferenceNullAction } from '../../../api/cashier/actions/set-transaction-bank-reference-null.action';
import { getFeeFreeTimesAction } from '../../../api/cashier/actions/get-fee-free-times.action';
import { setTransactionCleanAction } from '../../../api/cashier/actions/clean-transaction.action';
import { clearPaymentMethodsAction } from '../../../api/cashier/actions/set-payment-methods.action';
import { setCashierDialogAction } from '../../../api/cashier/actions/set-cashier-dialog.action';
import { getTransactionLimitAction } from '../../../api/cashier/actions/get-transaction-limits.action';
import { resetTransactionAction } from '../../../api/cashier/actions/new-request-transaction.action';
import { clearCheckSmsCodeAction } from '../../../api/cashier/actions/check-sms-code.action';
import { isLoadingRollOverSliceSelector, rolloverSelector } from '../../../api/rollover/rollover.selector';
import { getPlayerRolloverAction } from '../../../api/rollover/actions/get-player-rollover.action';
// import { getWdHoldAmountAction } from '../../../api/cashier/actions/get-wd-hold-amount.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    user: loggedInUserSelector(state),
    isLoading: isLoadingSliceSelector(state) || isLoadingRollOverSliceSelector(state),
    userDataLoading: isLoadingSelector(state),
    rollover: rolloverSelector(state),
    fingerprint: fingerprintSelector(state),
    ip: ipSelector(state),
    userBalances: userBalanceSelector(state),
    cashierDialog: cashierDialogShowSelector(state),
    transactionLimitDetails: transactionLimitDetailsSelector(state),
    // wdHoldAmount: wdHoldAmountSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getPlayerRolloverAction,
  setTransactionIpAction,
  setTransactionTypeAction,
  setTransactionBonusAction,
  setTransactionFingerprintAction,
  setNeedCashierFowardAction,
  getUserInfoAction,
  setBankReferenceNullAction,
  getFeeFreeTimesAction,
  setTransactionCleanAction,
  clearPaymentMethodsAction,
  setCashierDialogAction,
  // getWdHoldAmountAction,
  getTransactionLimitAction,
  resetTransactionAction,
  clearCheckSmsCodeAction,
};

export const CashierPageContainer = connect(mapStateToProps, mapDispatchToProps)(CashierPage);
