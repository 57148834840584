import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import StarIcon from '@material-ui/icons/Star';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SpotlightBg from '../components/images/login-bg.jpg';
import { Container, Grid, FormLabel, Typography, Card, Dialog, Button } from '@material-ui/core';
import { getHistory } from '../../../../utils/history.util';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { showToast } from '../../../../utils/toast/show-errors';
import { IPlayerTransactionLimitDetails } from '../../../../models/player-transaction-limit-details';
import { LimitTypeEnum } from '../../../../models/player-transaction-limit';
// import { VipCrownIcon } from '../../../../shared/icons/crown-icon';
import { BonusGameWinnerEnum, BonusTeamEnumEnum } from '../../../../enum/bonus/bonus-game-winner-enum';
import Chip from '@material-ui/core/Chip';
import { PickListBonusGame } from '../../../../shared/components/bonus/bonus-list-picks';
import { PlayerStatusEnum } from '../../../../enum/player-v2/PlayerStatusEnum';
import { PlayerTypeV2Enum } from '../../../../enum/player-v2/PlayerTypeV2Enum';
import { BonusAmountTypeEnum } from '../../../../enum/bonus/bonus-type-amount-enum';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
    padding: 5,
    marginBottom: '3rem',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
  },
  title2: {
    // fontWeight: theme.typography.fontWeightBold,
    color: 'white', //theme.palette.secondary.main,
    textAlign: 'left',
    marginLeft: '.5rem',
    marginTop: '.5rem',
    fontSize: '1.3rem',
  },
  card: {
    width: '100%',
    padding: '20px 0px',
    margin: '0px 0px',
    textAlign: 'center',
  },
  depositCard: {
    width: '100%',
    padding: '20px 0px',
    textAlign: 'center',
    backgroundImage: `url(${SpotlightBg})`,
    backgroundSize: 'cover',
    cursor: 'pointer',
  },
  withdrawalCard: {
    width: '100%',
    padding: '20px 0px',
    textAlign: 'center',
    backgroundImage: `url(${SpotlightBg})`,
    backgroundSize: 'cover',
    cursor: 'pointer',
  },
  cardDisabled: {
    filter: 'blur(2px)',
  },
  icon: theme.typography.h6,
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 12,
  },
  bonusSection: {
    width: '100%',
    marginTop: '10px',
    padding: '0px 0px',
    textAlign: 'left',
    // borderRadius: "0px",
    backgroundImage: `url(${SpotlightBg})`,
    backgroundSize: 'cover',
  },
  bonusAmountTitles: {
    fontSize: '.60rem',
    textAlign: 'center',
  },
  bonusAmounts: {
    textAlign: 'center',
    padding: '1rem',
    backgroundColor: '#2d2d2d',
  },
  green: {
    color: 'green',
  },
  red: {
    color: 'red',
  },
  remainingRolloverWhite: {
    fontWeight: theme.typography.fontWeightBold,
    color: 'grey',
  },
  remainingRolloverRed: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#f00',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  guideBtn: {
    borderRadius: '.30rem',
    textAlign: 'center',
    padding: '1rem',
    backgroundColor: '#181818',
  },
  containerGame: {
    borderRadius: '.30rem',
    textAlign: 'center',
    padding: '1rem',
    backgroundColor: '#181818',
    marginTop: '3%',
  },
  itemPick: {
    width: '30%',
    borderRadius: '.30rem',
    textAlign: 'center',
    padding: '1rem',
    backgroundColor: '#181818',
  },
}));

export interface ExternalProps {
  playerNumber: string;
  rollover: any;
  isLoading: boolean;
  userBalances: any;
  user: any;
  cashierDialog: any;
  transactionLimitDetails?: IPlayerTransactionLimitDetails;
  // wdHoldAmount: any;
}

export interface ExternalActionProps {
  setTransactionTypeAction: (type: number) => void;
  handleOpen: (data: any) => void;
  handleContOpen: (data: any) => void;
  setTransactionBonusAction: (data: any) => void;
  setBankReferenceNullAction: (x: any) => void;
  clearPaymentMethodsAction: () => void;
  setCashierDialogAction: (data: any) => void;
  resetTransactionAction: () => void;
  clearCheckSmsCodeAction: () => void;
}

export const FormTransactionType = (props: ExternalActionProps & ExternalProps) => {
  const [showTransactionHistory, setShowTransactionHistory] = useState(false);
  const classes = useStyles();
  const {
    user,
    playerNumber,
    rollover,
    isLoading,
    userBalances,
    transactionLimitDetails,
    // wdHoldAmount,
    setTransactionTypeAction,
    handleOpen,
    handleContOpen,
    setTransactionBonusAction,
    setBankReferenceNullAction,
    clearPaymentMethodsAction,
    setCashierDialogAction,
    resetTransactionAction,
    clearCheckSmsCodeAction,
    cashierDialog,
  } = props;

  let isOnBonus = rollover != null;
  const canDeposit = transactionLimitDetails?.canDeposit || false;

  if (Array.isArray(rollover)) {
    isOnBonus = rollover.length !== 0;
  }

  const showTransactionLimitMessage = () => {
    if (transactionLimitDetails) {
      if (
        transactionLimitDetails.remaining[LimitTypeEnum.Hourly].amount <= 0 ||
        transactionLimitDetails.remaining[LimitTypeEnum.Hourly].quantity <= 0
      ) {
        showToast("Hey, It's time for a break.. Please try depositing again later.", 'error');
      } else if (
        transactionLimitDetails.remaining[LimitTypeEnum.Daily].amount <= 0 ||
        transactionLimitDetails.remaining[LimitTypeEnum.Daily].quantity <= 0
      ) {
        showToast(
          'It seems you have reached our max daily deposit limit. Please note these limits are set for your own protection. Feel free to reach out to our customer support team for more details.',
          'error',
        );
      } else {
        showToast(
          'It seems you have reached our max weekly deposit limit. Please note these limits are set for your own protection. Feel free to reach out to our customer support team for more details.',
          'error',
        );
      }
    }
  };

  const ShowReadOnlyMessage = () => {
    showToast('Action not allowed, please contact customer support.', 'warning');
  };

  // const url = `${getAppSettings().baseUrl}resources/instantpayvid.mp4`;

  const selectTransactionType = (transactionType: number) => {
    if (transactionType == 1) setBankReferenceNullAction(null);

    resetTransactionAction();
    clearCheckSmsCodeAction();
    setTransactionBonusAction(null);
    setTransactionTypeAction(transactionType);
    clearPaymentMethodsAction();

    cashierDialog.isShow
      ? setCashierDialogAction({ isShow: true, type: 'cashier/method', transactionType: transactionType })
      : getHistory().push('/cashier/method');
  };

  const getChipByStatus = (wagerStatus: string) => {
    switch (wagerStatus) {
      case 'P':
        return <Chip label='Pending' color='primary' variant='outlined' />;
      case 'W':
        return <Chip label='Win' color='secondary' variant='outlined' />;
      case 'L':
        return <Chip label='Lose' color='primary' variant='outlined' />;
      default:
        return <Chip label='Lose' color='primary' variant='outlined' />;
    }
  };

  const getStack = (x: any) => {
    return (
      <Grid item xs={12} style={{ marginBottom: '5px' }}>
        Ticket: {!x.wager_id && <Chip label='Pending' color='default' variant='outlined' />}
        {x.wager_id && `#${x.wager_id}  `}
        {x.wager && getChipByStatus(x.wager.status)}
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Container className={classes.container} maxWidth='sm'>
        {!isLoading && userBalances.RealAvailBalance < 10 && (
          <Alert severity='warning' style={{ marginTop: '.5rem' }}>
            <AlertTitle>
              <Typography variant='body1'>Low Balance!</Typography>
            </AlertTitle>
            <Typography variant='body2'>
              Looks like you have a low balance! You must deposit additional funds before you are able to place wagers.
            </Typography>
          </Alert>
        )}
        {!isLoading && (
          <>
            {/* {!isLoading && wdHoldAmount > 0 && userBalances.RealAvailBalance && (
              <Alert severity='warning' style={{ marginTop: '.5rem' }}>
                <AlertTitle>
                  <Typography variant='body1'>Held Balance</Typography>
                </AlertTitle>
                <Typography variant='body2'>
                  Looks like you deposited with Debit/Credit Card. We will be holding any Debit & Credit card balances for 30 days.
                  <br />
                  <br />
                  Balance Held: ${wdHoldAmount}.<br />
                  Available for Withdrawal: ${userBalances.RealAvailBalance - wdHoldAmount}.
                </Typography>
              </Alert>
            )} */}

            <div style={{ padding: '0 3px' }}>
              <Grid container spacing={2} style={{ marginTop: '18px', marginBottom: '8px' }}>
                <Grid item xs={12} sm={6}>
                  <Card
                    onClick={() => {
                      if (user.player_type_id == PlayerTypeV2Enum.CREDIT) return;
                      if (user.status == PlayerStatusEnum.READ_ONLY) {
                        ShowReadOnlyMessage();
                      } else {
                        if (!canDeposit) {
                          showTransactionLimitMessage();
                        } else {
                          selectTransactionType(1);
                        }
                      }
                    }}
                    classes={{ root: classes.depositCard }}
                  >
                    <MonetizationOnIcon
                      style={{
                        color: 'green',
                        fontSize: '2.5rem',
                        filter: user.player_type_id == PlayerTypeV2Enum.CREDIT ? 'grayscale(100%)' : 'grayscale(0%)',
                      }}
                    />

                    <Typography variant='h6'>Deposit</Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card
                    onClick={() => {
                      /**
                       * Restrict credit players?
                       * - Users that DONT have an invite code?
                       * or?
                       * - Users that DONT have VIP in the beginning
                       */
                      if (user.player_type_id == PlayerTypeV2Enum.CREDIT) return;
                      if (user.status == PlayerStatusEnum.READ_ONLY) {
                        ShowReadOnlyMessage();
                      } else {
                        if (user.phone_verified_at == null || user.email_verified_at == null) {
                          showToast(
                            'Sorry, in order to withdraw funds, you must verify your phone AND email in your profile settings. Taking you there now...',
                            'error',
                          );
                          getHistory().push('/profile');
                          cashierDialog.isShow && setCashierDialogAction({ isShow: false, type: '' });
                        } else {
                          if (isOnBonus) {
                            showToast(
                              'Sorry but you cannot withdraw while on a rollover or with a free play balance. Please read the Promotion Rules for more information.',
                              'error',
                            );
                          } else if (!user.can_withdraw && user.inviter_code != 'VIPTEAM' && user.inviter_code != 'VIPAFFILIATES') {
                            if (!user.can_withdraw_message)
                              showToast(
                                'Sorry but you cannot withdraw without placing wagers. Please contact support if you have questions.',
                                'error',
                              );
                            else showToast(user.can_withdraw_message, 'error');
                          } else {
                            selectTransactionType(2);
                          }
                        }
                      }
                    }}
                    classes={{
                      root: clsx(classes.withdrawalCard, {
                        [classes.cardDisabled]: isOnBonus,
                      }),
                    }}
                  >
                    <AccountBalanceIcon
                      style={{
                        color: 'grey',
                        fontSize: '2.5rem',
                        filter: user.player_type_id == PlayerTypeV2Enum.CREDIT ? 'grayscale(100%)' : 'grayscale(0%)',
                      }}
                    />
                    <Typography variant='h6'>Withdrawal</Typography>
                  </Card>
                </Grid>
              </Grid>
            </div>

            {rollover != null && rollover != undefined && Object.keys(rollover).length !== 0 && (
              <Grid>
                <Card
                  classes={{
                    root: clsx(classes.bonusSection),
                  }}
                >
                  <Grid item xs={12} style={{ padding: '5px 10px' }}>
                    <FormLabel component='legend'>
                      <Typography variant='h4' classes={{ root: classes.title2 }}>
                        {playerNumber}
                        {rollover != null && (
                          <>
                            <br />
                            <Typography color='textSecondary'>Bonus Status</Typography>
                          </>
                        )}
                      </Typography>
                    </FormLabel>

                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: '2rem',
                          paddingBottom: '.5rem',
                          textAlign: 'left',
                          verticalAlign: 'text-bottom',
                        }}
                      >
                        {rollover != null && rollover != undefined && Object.keys(rollover).length !== 0 ? (
                          <>
                            {rollover.bonus.category != undefined && (
                              <>
                                {rollover.bonus.category == 'gradedgames' && (
                                  <>
                                    <Typography className={classes.remainingRolloverRed} gutterBottom>
                                      Graded Wager Enabled
                                    </Typography>
                                    <small>
                                      {`Guaranteed! If any of these bets do not win, you will receive up to $${
                                        rollover.bonus.type_amount_id == BonusAmountTypeEnum.FIXED
                                          ? rollover.bonus.match_multiplier * 100
                                          : rollover.bonus.match_multiplier * rollover.bonus.max_deposit
                                      } in freeplay.`}
                                    </small>
                                  </>
                                )}

                                {rollover.bonus.category == 'riskfreebet' && (
                                  <>
                                    <Typography className={classes.remainingRolloverRed} gutterBottom>
                                      Risk Free Wager Enabled
                                    </Typography>
                                    <small>
                                      {`Place your wager if it loses you will be credited back the full risk amount up to $${
                                        rollover.bonus.type_amount_id == BonusAmountTypeEnum.FIXED
                                          ? rollover.bonus.match_multiplier * 100
                                          : rollover.bonus.match_multiplier * rollover.bonus.max_deposit
                                      }. You must wager
                                      the deposit or bonus money prior to cashing out!`}
                                    </small>
                                  </>
                                )}
                                {rollover.bonus.category == 'deposit' && (
                                  <>
                                    <Typography variant='h4' className={classes.remainingRolloverRed}>
                                      ${rollover.remaining_rollover}
                                    </Typography>
                                    <Typography color='textSecondary' gutterBottom>
                                      Remaining Rollover
                                    </Typography>
                                    {rollover.bonus.description.includes('Risk Free') && (
                                      <small>Upon claiming a bonus, you must play amount equal to deposit + bonus money to cash out.</small>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Typography variant='h4' className={classes.remainingRolloverWhite}>
                              $0.00
                            </Typography>
                            <Typography color='textSecondary' gutterBottom>
                              Remaining Rollover
                            </Typography>
                          </>
                        )}
                      </Grid>

                      {!isLoading && rollover == null ? (
                        <Grid
                          item
                          xs={6}
                          style={{
                            paddingTop: '0rem',
                            paddingBottom: '.5rem',
                            textAlign: 'center',
                          }}
                        >
                          <StarIcon
                            color='primary'
                            style={{
                              fontSize: '1.5rem',
                              marginRight: '5px',
                            }}
                          />
                          <Typography style={{ fontSize: '1.0rem', fontWeight: 'bold' }} gutterBottom>
                            Bonuses are available!
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={6}
                          style={{
                            paddingTop: '3.4rem',
                            paddingBottom: '.5rem',
                            textAlign: 'right',
                          }}
                        >
                          <Typography variant='subtitle2' gutterBottom>
                            Claimed Date:
                            <br />
                            {rollover.start_date}
                          </Typography>
                        </Grid>
                      )}

                      {rollover?.bonus?.category == 'gradedgames' && (
                        <>
                          {rollover &&
                            rollover?.rollover &&
                            rollover?.rollover?.rollover_games &&
                            Array.isArray(rollover?.rollover?.rollover_games) &&
                            rollover?.rollover?.rollover_games.map((x: any, index: number) => {
                              return (
                                <>
                                  {index == 0 && (
                                    <>
                                      <Grid container className={classes.containerGame}>
                                        {/* <Grid item xs={2}>
                                          WINNER
                                        </Grid> */}
                                        <Grid item xs={9}>
                                          LINE
                                        </Grid>
                                        <Grid item xs={3}>
                                          MIN BET AMOUNT
                                        </Grid>
                                      </Grid>
                                    </>
                                  )}

                                  <Grid container className={classes.containerGame}>
                                    <br />

                                    {(x.winner == BonusGameWinnerEnum.VISITOR || !x.winner) && (
                                      <>
                                        {/* <Grid item xs={2}>
                                          <VipCrownIcon></VipCrownIcon>
                                        </Grid> */}
                                        <Grid item xs={8}>
                                          #{x.vnum} {x.visitor}
                                        </Grid>
                                        <Grid item xs={4}>
                                          ${x.min_amount}
                                        </Grid>
                                        {/* <Grid item xs={2}>
                                          ${x.max_amount}
                                        </Grid> */}
                                        {getStack(x)}

                                        {!x.wager && <PickListBonusGame BonusOrRollover={x} Team={BonusTeamEnumEnum.VISITOR} />}
                                      </>
                                    )}

                                    {(x.winner == BonusGameWinnerEnum.HOME || !x.winner) && (
                                      <>
                                        {/* <Grid item xs={2}>
                                          <VipCrownIcon></VipCrownIcon>
                                        </Grid> */}
                                        <Grid item xs={8}>
                                          #{x.hnum} {x.home}
                                        </Grid>
                                        <Grid item xs={4}>
                                          ${x.min_amount}
                                        </Grid>
                                        {/* <Grid item xs={2}>
                                          ${x.max_amount}
                                        </Grid> */}
                                        {getStack(x)}
                                        {!x.wager && <PickListBonusGame BonusOrRollover={x} Team={BonusTeamEnumEnum.HOME} />}
                                      </>
                                    )}
                                  </Grid>
                                </>
                              );
                            })}
                        </>
                      )}

                      {!isLoading && rollover != null && rollover?.bonus?.category == 'deposit' && (
                        <Grid item xs={12} style={{ width: '100%' }}>
                          <Button
                            variant='contained'
                            color='primary'
                            style={{
                              marginTop: '.5rem',
                              float: 'right',
                              marginLeft: '.5rem',
                            }}
                            onClick={handleOpen}
                          >
                            View Wagers
                          </Button>
                          <Button
                            variant='outlined'
                            color='default'
                            style={{ marginTop: '.5rem', float: 'right' }}
                            onClick={handleContOpen}
                          >
                            Contribution Table
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {!isLoading && rollover != null && rollover?.category == 'deposit' && (
                    <Grid container>
                      <Grid item xs={4} className={classes.bonusAmounts}>
                        <Typography variant='subtitle1' color='textSecondary' className={classes.bonusAmountTitles}>
                          Starting Rollover
                        </Typography>
                        {rollover != undefined && rollover?.bonus ? (
                          <>
                            {rollover.bonus.category == 'gradedgames' && <Typography variant='h6'>$0.00</Typography>}
                            {rollover.bonus.category == 'riskfreebet' && <Typography variant='h6'>$0.00</Typography>}
                            {rollover.bonus.category == 'deposit' && <Typography variant='h6'>${rollover.starting_rollover}</Typography>}
                          </>
                        ) : (
                          <Typography variant='h6'>$0</Typography>
                        )}
                      </Grid>
                      <Grid item xs={4} className={classes.bonusAmounts}>
                        <Typography variant='subtitle1' color='textSecondary' className={classes.bonusAmountTitles}>
                          Free Play Winnings
                        </Typography>
                        {rollover != undefined ? (
                          <Typography variant='h6'>${rollover.free_play_winnings}</Typography>
                        ) : (
                          <Typography variant='h6'>$0</Typography>
                        )}
                      </Grid>
                      <Grid item xs={4} className={classes.bonusAmounts}>
                        <Typography variant='subtitle1' color='textSecondary' className={classes.bonusAmountTitles}>
                          Amount Wagered
                        </Typography>
                        {rollover != undefined && rollover.rollover_data != undefined ? (
                          <Typography variant='h6'>${rollover.amount_wagered}</Typography>
                        ) : (
                          <Typography variant='h6'>$0</Typography>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Card>
              </Grid>
            )}

            <Dialog
              onClose={() => {
                setShowTransactionHistory(false);
              }}
              aria-labelledby='transactionHistory'
              open={showTransactionHistory}
              fullWidth={true}
              maxWidth='sm'
            >
              {/* <TransactionHistory
                playerNumber={playerNumber}
                onClose={() => {
                  setShowTransactionHistory(false);
                }}
              /> */}
            </Dialog>
          </>
        )}
      </Container>
    </React.Fragment>
  );
};
