import React, { useEffect } from 'react';
import { Box, Button, Chip, Container, FormLabel, Grid, makeStyles, Typography } from '@material-ui/core';
import { Loading } from '../../../shared/loading/loading.component';
import { getHistory } from '../../../utils/history.util';
import Alert from '@material-ui/lab/Alert';
import { AddBankDialog } from './components/add-bank-dialog.component';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles: any = makeStyles({
  container: {
    display: 'block',
    padding: 15,
  },
  buttons: {
    paddingTop: 15,
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export interface ExternalActionProps {
  getPlayerBanksAction: () => void;
  getInstitutionsAction: () => void;
  loginToBankAction: (x: any) => void;
  addPlayerBankAction: (x: any) => void;
  completeChallengeAction: (x: any) => void;
  removePlayerBankAction: (x: any) => void;
  setDefaultPlayerBankAction: (x: any) => void;
  setCashierDialogAction: (data: any) => void;
}

export interface ExternalProps {
  loading: any;
  playerBanks: any;
  institutions: any;
  selectableBanks: any;
  mfaChallenge: any;
  cashierDialog: any;
  type: number | null;
}

export const PlayerBankSelectPage = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const {
    loading,
    playerBanks,
    getPlayerBanksAction,
    institutions,
    getInstitutionsAction,
    loginToBankAction,
    selectableBanks,
    mfaChallenge,
    completeChallengeAction,
    addPlayerBankAction,
    removePlayerBankAction,
    setDefaultPlayerBankAction,
    setCashierDialogAction,
    cashierDialog,
    type,
  } = props;
  const [addBankOpen, setAddBankOpen] = React.useState(false);

  const handleClickOpen = () => {
    setAddBankOpen(true);
  };

  const handleClose = () => {
    setAddBankOpen(false);
  };

  useEffect(() => {
    getPlayerBanksAction();
  }, []);

  useEffect(() => {
    handleClose();
  }, [playerBanks]);

  return (
    <React.Fragment>
      <AddBankDialog
        open={addBankOpen}
        handleClose={handleClose}
        institutions={institutions}
        getInstitutionsAction={getInstitutionsAction}
        loginToBankAction={loginToBankAction}
        selectableBanks={selectableBanks}
        mfaChallenge={mfaChallenge}
        loading={loading}
        completeChallengeAction={completeChallengeAction}
        addPlayerBankAction={addPlayerBankAction}
      />
      <Loading loading={loading} />
      <Container className={classes.container} maxWidth='sm'>
        <FormLabel component='legend' className={classes.button}>
          <Typography variant='h6' classes={{ root: classes.title }}>
            Connected Banks
          </Typography>
        </FormLabel>

        <hr />

        {playerBanks &&
          playerBanks.length > 0 &&
          playerBanks.map((bank: any, index: any) => {
            return (
              <Box
                key={index}
                bgcolor={'secondary.dark'}
                style={{ padding: '.5rem', marginTop: '.5rem', marginBottom: '.5rem', borderRadius: '.25rem' }}
              >
                {bank.default == true && (
                  <Alert severity='warning' style={{ marginBottom: '.5rem' }}>
                    <Typography variant='body2'>Funds will be withdrawn from the DEFAULT account.</Typography>
                  </Alert>
                )}

                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant='h6'>
                      {bank.status} {bank.type}
                    </Typography>
                    <Typography variant='body2'>Acc #{bank.account}</Typography>
                    <Typography variant='caption'>Connected: {bank.date}</Typography>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: 'center' }}>
                    {bank.default == true && <Chip label='Default' color='primary' style={{ marginTop: '.5rem' }} />}

                    {bank.default != true && (
                      <Button
                        endIcon={<LaunchIcon />}
                        color='primary'
                        variant='outlined'
                        onClick={() => {
                          setDefaultPlayerBankAction({ bankId: bank.id });
                        }}
                      >
                        Select
                      </Button>
                    )}

                    {bank.default != true && (
                      <Button
                        startIcon={<DeleteIcon />}
                        color='default'
                        variant='text'
                        size='small'
                        onClick={() => {
                          if (confirm('Are you sure you want to delete this bank?')) {
                            removePlayerBankAction({ bankId: bank.id });
                          }
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            );
          })}

        {!loading && playerBanks && playerBanks.length == 0 && (
          <Alert severity='warning' style={{ marginBottom: '.5rem', textAlign: 'center' }} icon={false}>
            <Typography variant='body2' gutterBottom>
              Please add a bank before you can use this method.
            </Typography>

            <Button
              variant='contained'
              color='secondary'
              size='small'
              style={{ margin: '10px auto', display: 'block' }}
              onClick={handleClickOpen}
            >
              Add Bank
            </Button>
          </Alert>
        )}

        <span className={classes.button}>
          <Button
            variant='outlined'
            onClick={() => {
              getHistory().goBack();
            }}
          >
            <Typography>Back</Typography>
          </Button>

          {playerBanks && playerBanks.length > 0 && (
            <Button variant='outlined' color='primary' style={{ margin: '0px auto', display: 'block' }} onClick={handleClickOpen}>
              Add Bank
            </Button>
          )}

          {playerBanks && playerBanks.length > 0 && (
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                cashierDialog.isShow
                  ? setCashierDialogAction({ isShow: true, type: 'cashier/bonus', transactionType: type })
                  : getHistory().push('/cashier/bonus');
              }}
            >
              Next
            </Button>
          )}
        </span>
      </Container>
    </React.Fragment>
  );
};
