import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Container, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { getHistory } from '../../../utils/history.util';

const useStyles = makeStyles({
  container: {
    display: 'block',
    padding: 15,
  },
  buttons: {
    paddingTop: 15,
    display: 'flex',
    justifyContent: 'center',
  },
});

export interface ExternalProps {
  transactionRequest: any;
  cashierDialog: any;
}

export interface ExternalActionProps {
  setSmsCodeNullAction: () => void;
  setTransactionCleanAction: () => void;
  setCashierDialogAction: (data: any) => void;
}

export const CompletePage = (props: ExternalProps & ExternalActionProps) => {
  const { transactionRequest, setSmsCodeNullAction, setTransactionCleanAction, setCashierDialogAction, cashierDialog } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container className={classes.container} maxWidth='sm'>
        {transactionRequest.type == 'Withdrawal' ? (
          <Alert severity='success'>
            <AlertTitle>Payment Sent</AlertTitle>
            Your payment has been sent. Thank you!
          </Alert>
        ) : (
          <Alert severity='success'>
            <AlertTitle>Payment Received</AlertTitle>
            Your payment has been received. Thank you!
          </Alert>
        )}
        <br />
        <div className={classes.buttons}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setSmsCodeNullAction();
              setTransactionCleanAction();
              cashierDialog.isShow ? setCashierDialogAction({ isShow: true, type: 'cashier' }) : getHistory().push('/cashier');
            }}
          >
            <Typography>Back to Cashier</Typography>
          </Button>
        </div>
      </Container>
    </React.Fragment>
  );
};
