import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const GET_DEPOSIT_ACCOUNTS = `GET_DEPOSIT_ACCOUNTS${suffix}`;

export const getDepositAccountsAction = createAction<number>(GET_DEPOSIT_ACCOUNTS);

const GET_DEPOSIT_ACCOUNTS_SUCCESS = `GET_DEPOSIT_ACCOUNTS_SUCCESS${suffix}`;
const getDepositAccountsSuccessAction = createAction<string[]>(GET_DEPOSIT_ACCOUNTS_SUCCESS);

const GET_DEPOSIT_ACCOUNTS_ERROR = `GET_DEPOSIT_ACCOUNTS_ERROR${suffix}`;
const getDepositAccountsErrorAction = createAction<string>(GET_DEPOSIT_ACCOUNTS_ERROR);

function* getDepositAccountsWatcher(): SagaIterator {
  yield takeLatest(GET_DEPOSIT_ACCOUNTS, getDepositAccountsWorker);
}
mergeSaga(getDepositAccountsWatcher);

function* getDepositAccountsWorker(action: Action<number>): SagaIterator {
  try {
    const result: any = yield call(getDepositAccountsApi, action.payload);
    yield put(getDepositAccountsSuccessAction(result.accounts));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getDepositAccountsErrorAction(e));
  }
}

const getDepositAccountsApi = (methodId: number): Request => {
  return vipApi(`/pcashier/deposit-accounts/${methodId}`, 'get', {});
};

const reduceHandlers = {
  [GET_DEPOSIT_ACCOUNTS]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
    accounts: [],
  }),
  [GET_DEPOSIT_ACCOUNTS_SUCCESS]: (slice: CashierTransactionSliceType, action: Action<string[]>): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    accounts: action.payload,
  }),
  [GET_DEPOSIT_ACCOUNTS_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
