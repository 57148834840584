import platform from 'platform';
import { io, Socket } from 'socket.io-client';
import { SocketBasicRequest, SocketRequest, SocketStagantPlayer } from '../../models/Socket/socket-request';
import { GetFingerPrint, GetIp, GetLuckyOrangSession } from '../functions/common';
import { IpProviderResponse } from '../../models/ip';
import axios from 'axios';
import { getCurrentPage } from '../app-root/app-root.component';
import { SocketUserTypeEnum } from '../../models/Socket/socket-enum';
import { IPlayerSession } from '../../models/player';
import { SocketRooms } from '../../enum/socket/socket-enum';

export class PlayerSocketService {
  private static instance: PlayerSocketService;
  private socketIOUrl: string | null = null;
  private socket: Socket | null = null;

  public static getInstance(request: SocketBasicRequest): PlayerSocketService {
    if (!PlayerSocketService.instance) {
      PlayerSocketService.instance = new PlayerSocketService(request);
    }

    return PlayerSocketService.instance;
  }

  constructor(request: SocketBasicRequest) {
    axios.get('app.settings.json').then(response => {
      this.socketIOUrl = response?.data?.notificationsUrl;

      if (this.socketIOUrl) {
        this.CreateSocket(request);
      }
    });
  }

  CreateSocket(request: SocketBasicRequest): Socket | null {
    if (this.socketIOUrl) {
      this.socket = io(this.socketIOUrl, {
        query: {
          userID: request.userID,
          userType: SocketUserTypeEnum.PLAYER,
          createdAt: new Date(),
        },
        auth: {
          token: request.accessToken,
        },
        transports: ['websocket', 'polling'],
      });

      this.socket.on('connect', () => {
        if (this.socket) {
          console.log(`Connected with socket ID: ${this.socket.id}. UserID: ${request.userID} v1`);
          setTimeout(async () => {
            await this.GetInformationToUpdate(request, false);
            await this.GetMessages();
          }, 2000);
        }
      });

      this.socket.on('notification-received', (req: any) => {
        if (
          req.action &&
          (req.action == 'balance-change' ||
            req.action == 'signup-status' ||
            req.action == 'close-cognito-identity' ||
            req.action == 'message-to-client')
        ) {
          window.parent.postMessage(req, '*');
        }
      });

      this.socket.on('message-to-client', (req: any) => {
        if (req && req.data && req.data.data) {
          req.data.data.showOpenDialogButton = true;
        }
        window.parent.postMessage(req, '*');
      });

      this.socket.on('get-messages-received', (req: any) => {
        window.parent.postMessage(req, '*');
      });

      this.socket.on('signup-status', (req: any) => {
        window.parent.postMessage(req, '*');
      });

      this.socket.on('set-Messages', (req: any) => {
        window.parent.postMessage(req, '*');
      });

      this.socket.on('set-Messages-by-date', (req: any) => {
        window.parent.postMessage(req, '*');
      });

      this.socket.on('get-information', () => {
        this.GetInformationToUpdate(request, true);
      });

      return this.socket;
    }
    return null;
  }

  GetInformationToUpdate = async (request: SocketBasicRequest, update: boolean) => {
    const query: SocketRequest = {
      accessToken: request.accessToken,
      userID: request.userID,
      fingerprint: '',
      ip: null,
      userAgent: 'N/A',
      messages: [],
      createdAt: new Date(),
      updatedAt: new Date(),
      userType: SocketUserTypeEnum.PLAYER,
      device: null,
      update: update,
      currentPage: getCurrentPage(),
      luckyOrangeSession: null,
    };

    try {
      query.userAgent = window.navigator.userAgent;
    } catch (error) {
      console.error('Error GetInformationToUpdate - User Agent', { error });
    }

    try {
      query.device = {
        browser: platform.name ?? 'N/A',
        os: platform?.os?.family ?? 'N/A',
        osVersion: platform?.os?.version ?? 'N/A',
        product: platform.product ?? 'N/A',
      };
    } catch (error) {
      console.error('Error GetInformationToUpdate - Device', { error });
    }

    try {
      await GetLuckyOrangSession().then(x => {
        query.luckyOrangeSession = x;
      });
    } catch (error) {
      console.error('Error GetInformationToUpdate - Lucky Orange', { error });
    }

    try {
      if (localStorage.getItem('geo')) {
        query.ip = <IpProviderResponse>JSON.parse(localStorage.getItem('geo') ?? '{}');
      } else {
        await GetIp().then(x => {
          if (x) {
            query.ip = x;
          }
        });
      }
    } catch (error) {
      console.error('Error GetInformationToUpdate - Ip', { error });
    }

    try {
      if (localStorage.getItem('fpjs')) {
        query.fingerprint = localStorage.getItem('fpjs') ?? 'N/A';
      } else {
        await GetFingerPrint().then(x => {
          query.fingerprint = x;
        });
      }
    } catch (error) {
      console.error('Error GetInformationToUpdate - FingerPrint', { error });
    }

    setTimeout(() => {
      this.UpdateInformation(query);
    }, 1000);

    setTimeout(() => {
      try {
        //debugger; // eslint-disable-line no-debugger
        const userSession = localStorage.getItem('user');
        if (userSession) {
          const objUserSession: IPlayerSession = JSON.parse(userSession);
          if (
            request.userID == SocketRooms.VIPPUBLIC &&
            objUserSession.username.trim().toLocaleLowerCase() != request.userID.toLocaleLowerCase().trim()
          ) {
            this.Login({
              accessToken: objUserSession.token,
              userID: objUserSession.username.toLocaleUpperCase(),
            });
          }
        }
      } catch (error) {
        console.error('Login', { error });
      }
    }, 2000);
  };

  Login = async (request: SocketBasicRequest) => {
    if (this.socket)
      this.socket.emit('login-player', {
        userID: request.userID,
        accessToken: request.accessToken,
      });
  };

  StagnantPlayer = async (request: SocketStagantPlayer) => {
    if (this.socket) this.socket.emit('stagnant-player', request);
  };

  UpdateInformation = async (request: SocketRequest | any) => {
    if (this.socket) this.socket.emit('update-information', request);
  };

  GetMessages = async () => {
    if (this.socket) this.socket.emit('get-messages');
  };
}

export const PlayerSocketServiceClient = (request: SocketBasicRequest) => PlayerSocketService.getInstance(request);
