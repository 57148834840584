import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { BTCDepositCard } from './cards/btcQrCode';
import { CADepositCard } from './cards/cashappCard';
import WithdrawalPendingCard from './cards/withdrawPendingCard';
import { Button } from '@material-ui/core';
import { Container, LinearProgress, Typography } from '@material-ui/core';
import { ManualPaymentCard } from './cards/manualPaymentCard';
import { getHistory } from '../../../utils/history.util';
import $ from 'jquery';
import { getAppSettings } from '../../../utils/app-settings.util';
import { setBankReferenceNullAction } from '../../../api/cashier/actions/set-transaction-bank-reference-null.action';
import { PaymentMethodEnum } from '../../../enum/transactions/PaymentMethodEnum';

const useStyles = makeStyles({
  container: {
    display: 'block',
    padding: 15,
    marginBottom: '3rem',
  },
  buttons: {
    paddingTop: 15,
    display: 'flex',
    justifyContent: 'center',
  },
});

export interface ExternalProps {
  transactionRequest: any;
  bankReference: any;
  method: any;
  transactionStatus: any;
  cashierDialog: any;
}

export interface ExternalActionProps {
  setTransactionBonusAction: (x: any) => void;
  getTransactionStatusAction: (transactionId: any) => void;
  getBankReferenceAction: (x: any) => void;
  setTransactionNameAction: (x: any) => void;
  setTransactionAccountAction: (x: any) => void;
  setTransactionAmountAction: (x: any) => void;
  setTransactionMethodAction: (x: any) => void;
  setTransactionTypeAction: (x: any) => void;
  setSmsCodeNullAction: () => void;
  setBankReferenceNullAction: (x: any) => void;
  setCashierDialogAction: (data: any) => void;
}

export const RequestReceived = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const {
    transactionRequest,
    bankReference,
    method,
    setTransactionBonusAction,
    getBankReferenceAction,
    setTransactionNameAction,
    setTransactionAccountAction,
    setTransactionAmountAction,
    setTransactionMethodAction,
    setTransactionTypeAction,
    setSmsCodeNullAction,
    setCashierDialogAction,
    cashierDialog,
  } = props;

  useEffect(() => {
    if (bankReference == null && transactionRequest?.type != 'Withdrawal') {
      getBankReferenceAction({
        method: transactionRequest?.method,
        name: transactionRequest?.bank,
      });
    }
    if (transactionRequest == null) {
      cashierDialog.isShow ? setCashierDialogAction({ isShow: true, type: 'cashier' }) : getHistory().push('/cashier');
    }
    if (transactionRequest?.number != null) {
      cashierDialog.isShow ? setCashierDialogAction({ isShow: true, type: 'cashier/complete' }) : getHistory().push('/cashier/complete');
    }
  }, []);

  useEffect(() => {
    if (transactionRequest) {
      if (transactionRequest.success != null && transactionRequest.success == true) {
        cashierDialog.isShow ? setCashierDialogAction({ isShow: true, type: 'cashier/complete' }) : getHistory().push('/cashier/complete');
      } else {
        getTransactionStatus(0);
      }
    }
  }, [transactionRequest]);

  useEffect(() => {
    if (transactionRequest?.type != 'Withdrawal') {
      getBankReferenceAction({
        method: transactionRequest?.method,
        name: transactionRequest?.bank,
      });
    }
  }, [bankReference]);

  const getTransactionStatus = (pollCount: any) => {
    $.post(
      getAppSettings().apiUrl + '/pcashier/GetTransactionStatus',
      {
        id: transactionRequest.id,
      },
      function (data) {
        if (data == 5 || data == 6) {
          cashierDialog.isShow
            ? setCashierDialogAction({ isShow: true, type: 'cashier/complete' })
            : getHistory().push('/cashier/complete');
        } else {
          if (pollCount < 60) {
            setTimeout(() => getTransactionStatus(++pollCount), 10000);
          }
        }
      },
    );
  };

  return (
    <React.Fragment>
      <Container className={classes.container} maxWidth='sm'>
        <Alert severity='warning'>
          <AlertTitle>Pending Payment</AlertTitle>
          {transactionRequest?.type == 'Deposit' ? 'Your request is pending payment.' : 'Our team is working on your withdrawal.'}
          {transactionRequest?.type == 'Deposit' && <strong>Please send funds to the account below!</strong>}
        </Alert>

        <LinearProgress color='secondary' />

        {transactionRequest?.type == 'Withdrawal' ? (
          <WithdrawalPendingCard transactionRequest={transactionRequest} />
        ) : method?.id == PaymentMethodEnum.BITCOIN || method?.id == PaymentMethodEnum.INSTANT_BITCOIN ? (
          <BTCDepositCard transactionRequest={transactionRequest} />
        ) : method?.id == PaymentMethodEnum.CASH_APP ? (
          <CADepositCard transactionRequest={transactionRequest} bankReference={bankReference} />
        ) : (
          <ManualPaymentCard
            transactionRequest={transactionRequest}
            bankReference={bankReference}
            getBankReferenceAction={getBankReferenceAction}
            setCashierDialogAction={setCashierDialogAction}
          />
        )}
        <div className={classes.buttons}>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              setTransactionBonusAction(null);
              setTransactionNameAction(null);
              setTransactionAccountAction(null);
              setTransactionAmountAction(null);
              setTransactionMethodAction(null);
              setTransactionTypeAction(null);
              setSmsCodeNullAction();
              setBankReferenceNullAction(null);
              cashierDialog.isShow ? setCashierDialogAction({ isShow: true, type: 'cashier' }) : getHistory().push('/cashier');
            }}
          >
            <Typography>New Transaction</Typography>
          </Button>
        </div>
      </Container>
    </React.Fragment>
  );
};
