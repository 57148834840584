import { createAction } from 'redux-actions';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
declare global {
  interface Window {
    LO: any;
  }
}

const logEventInLuckyOrange = (data: any) => {
  if (window.LO != null) {
    if (window.LO.events) {
      window.LO.events.track('Selected Bonus', data);
      console.log('[LO Event]: Selected Bonus');
    }
  }
};

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const SET_TRANSACTION_BONUS = `SET_TRANSACTION_BONUS${suffix}`;

export const setTransactionBonusAction = createAction<number>(SET_TRANSACTION_BONUS);

const reduceHandlers = {
  [SET_TRANSACTION_BONUS]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => {
    logEventInLuckyOrange(action.payload);
    return { ...slice, isLoading: false, bonus: action.payload };
  },
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
