import React from 'react';
import { PLAY_LINE } from '../../../enum';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import { PickBonusGame } from './bonus-valid-picks';
import { BonusTeamEnumEnum } from '../../../enum/bonus/bonus-game-winner-enum';
const useStyles = makeStyles({
  itemPick: {
    width: '28%',
    borderRadius: '.30rem',
    textAlign: 'center',
    backgroundColor: '#4c4c4c3a',
    marginRight: '4px',
    marginTop: '6px',
  },
});
export const PickListBonusGame = (props: any) => {
  const { BonusOrRollover, Team } = props;
  const classes = useStyles();

  return (
    <>
      {Team == BonusTeamEnumEnum.VISITOR && (
        <>
          <Badge
            className={classes.itemPick}
            badgeContent={<PickBonusGame BonusGame={BonusOrRollover} PlayLine={PLAY_LINE.VISITORSPREAD} />}
          >
            <div>SPREAD</div>
          </Badge>
          <Badge
            className={classes.itemPick}
            badgeContent={<PickBonusGame BonusGame={BonusOrRollover} PlayLine={PLAY_LINE.VISITORMONEYLINE} />}
          >
            <div>MONEY LINE</div>
          </Badge>
          <Badge
            className={classes.itemPick}
            badgeContent={<PickBonusGame BonusGame={BonusOrRollover} PlayLine={PLAY_LINE.VISITORTOTAL} />}
          >
            <div>TOTAL</div>
          </Badge>
        </>
      )}
      {Team == BonusTeamEnumEnum.HOME && (
        <>
          <Badge className={classes.itemPick} badgeContent={<PickBonusGame BonusGame={BonusOrRollover} PlayLine={PLAY_LINE.HOMESPREAD} />}>
            <div>SPREAD</div>
          </Badge>
          <Badge
            className={classes.itemPick}
            badgeContent={<PickBonusGame BonusGame={BonusOrRollover} PlayLine={PLAY_LINE.HOMEMONEYLINE} />}
          >
            <div>MONEY LINE</div>
          </Badge>
          <Badge className={classes.itemPick} badgeContent={<PickBonusGame BonusGame={BonusOrRollover} PlayLine={PLAY_LINE.HOMETOTAL} />}>
            <div>TOTAL</div>
          </Badge>
        </>
      )}
    </>
  );
};
