import React, { useState, useEffect } from 'react';
import { FormTransactionType } from './components/FormTransactionType';
import { ContDialog } from './components/ContDiaglog';
import { ContTable } from './components/ContTable';
import { WageringDialoge } from './components/WageringDialoge';
import { WageringHistoryTable } from './components/WageringHistoryTable';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import { Loading } from '../../../shared/loading/loading.component';
import { IPlayerTransactionLimitDetails } from '../../../models/player-transaction-limit-details';

export interface ExternalProps {
  user: any;
  isLoading: boolean;
  rollover: any;
  fingerprint: any;
  ip: any;
  userBalances: any;
  userDataLoading: any;
  cashierDialog: any;
  transactionLimitDetails?: IPlayerTransactionLimitDetails;
  // wdHoldAmount: any;
}

export interface ExternalActionProps {
  getPlayerRolloverAction: (playerNumber: any) => void;
  setTransactionIpAction: (ip: any) => void;
  setTransactionTypeAction: (type: any) => void;
  setTransactionBonusAction: (bonus: any) => void;
  setTransactionFingerprintAction: (fingerprint: any) => void;
  setNeedCashierFowardAction: (status: boolean) => void;
  getUserInfoAction: () => void;
  setBankReferenceNullAction: (x: any) => void;
  getFeeFreeTimesAction: () => void;
  setTransactionCleanAction: () => void;
  clearPaymentMethodsAction: () => void;
  setCashierDialogAction: (data: any) => void;
  getTransactionLimitAction: () => void;
  resetTransactionAction: () => void;
  clearCheckSmsCodeAction: () => void;
  // getWdHoldAmountAction: () => void;
}

export const CashierPage = (props: ExternalProps & ExternalActionProps) => {
  const {
    user,
    isLoading,
    rollover,
    fingerprint,
    ip,
    userBalances,
    userDataLoading,
    transactionLimitDetails,
    // wdHoldAmount,
    getPlayerRolloverAction,
    setTransactionIpAction,
    setTransactionTypeAction,
    setTransactionBonusAction,
    setTransactionFingerprintAction,
    setNeedCashierFowardAction,
    getUserInfoAction,
    setBankReferenceNullAction,
    getFeeFreeTimesAction,
    setTransactionCleanAction,
    clearPaymentMethodsAction,
    setCashierDialogAction,
    getTransactionLimitAction,
    resetTransactionAction,
    clearCheckSmsCodeAction,
    cashierDialog,
    // getWdHoldAmountAction,
  } = props;

  const [playerNumber] = useState(user.username);
  const [open, setOpen] = useState(false);
  const [contOpen, setContOpen] = useState(false);

  useEffect(() => {
    getPlayerRolloverAction(playerNumber);

    if (ip == null) {
      fetch('https://api.ipify.org?format=json')
        .then(response => {
          return response.json();
        })
        .then(res => {
          setTransactionIpAction(res.ip);
        })
        .catch(err => console.error('Problem fetching my IP', err));
    }

    if (fingerprint == null) {
      if (ip == null) {
        ip == '';
      }

      const userMetaData = {
        playerNumber: playerNumber,
        ip: ip,
      };

      FingerprintJS.load({ token: 'nvoiB2OqFxGoBIg53MC6' })
        .then(fp => fp.get({ tag: userMetaData }))
        .then(result => {
          setTransactionFingerprintAction(result.visitorId);
        })
        .catch(ex => {
          console.log(ex);
          const userId = btoa(playerNumber + ':cashier:' + new Date().getTime());
          setTransactionFingerprintAction(userId);
        });
    }

    setNeedCashierFowardAction(false);
    getUserInfoAction();
    getFeeFreeTimesAction();
    getTransactionLimitAction();
  }, []);

  useEffect(() => {
    setTransactionCleanAction();
    // getWdHoldAmountAction();
  }, []);

  if (playerNumber != null && playerNumber != '') {
    return (
      <>
        <Loading loading={isLoading || userDataLoading} />
        {!isLoading && (
          <>
            <FormTransactionType
              setTransactionTypeAction={setTransactionTypeAction}
              playerNumber={playerNumber}
              rollover={rollover}
              isLoading={isLoading}
              handleOpen={() => setOpen(true)}
              handleContOpen={() => setContOpen(true)}
              setTransactionBonusAction={setTransactionBonusAction}
              userBalances={userBalances}
              setBankReferenceNullAction={setBankReferenceNullAction}
              user={user}
              clearPaymentMethodsAction={clearPaymentMethodsAction}
              setCashierDialogAction={setCashierDialogAction}
              cashierDialog={cashierDialog}
              transactionLimitDetails={transactionLimitDetails}
              resetTransactionAction={resetTransactionAction}
              clearCheckSmsCodeAction={clearCheckSmsCodeAction}
              // wdHoldAmount={wdHoldAmount}
            />
            <WageringDialoge
              open={open}
              onClose={() => setOpen(false)}
              data={<WageringHistoryTable rows={rollover ? rollover.wager_data : []} />}
            />
            <ContDialog
              open={contOpen}
              onClose={() => setContOpen(false)}
              data={<ContTable rows={rollover && rollover != null ? rollover.contribution_table : []} />}
            />
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        <h3 style={{ color: 'white' }}>
          [Error]: Invalid Player Number.
          <br></br>
          <small>Please contact support.</small>
        </h3>
      </>
    );
  }
};
