import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { getPlayerBanksAction } from './get-player-banks.action';
import { API_PATH } from '../../../constants';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const REMOVE_BANK_ACTION = `REMOVE_BANK_ACTION${suffix}`;

export const removePlayerBankAction = createAction(REMOVE_BANK_ACTION);

const REMOVE_BANK_ACTION_SUCCESS = `REMOVE_BANK_ACTION_SUCCESS${suffix}`;
const removePlayerBankSuccessAction = createAction<any[]>(REMOVE_BANK_ACTION_SUCCESS);

const REMOVE_BANK_ACTION_ERROR = `REMOVE_BANK_ACTION_ERROR${suffix}`;
const removePlayerBankErrorAction = createAction<string>(REMOVE_BANK_ACTION_ERROR);

function* removePlayerBankWatcher(): SagaIterator {
  yield takeEvery(REMOVE_BANK_ACTION, removePlayerBankWorker);
}
mergeSaga(removePlayerBankWatcher);

function* removePlayerBankWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(removePlayerBankApi, action.payload);
    yield put(removePlayerBankSuccessAction(result));
    yield call(showToast, result.message, 'success');
    yield put(getPlayerBanksAction());
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(removePlayerBankErrorAction(e));
  }
}

const removePlayerBankApi = (data: any): Request => {
  return vipApi(API_PATH.CASHIER.REMOVE_BANK, 'post', { bankId: data.bankId });
};

const reduceHandlers = {
  [REMOVE_BANK_ACTION]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [REMOVE_BANK_ACTION_SUCCESS]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
  }),
  [REMOVE_BANK_ACTION_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
