import React, { useEffect, useState } from 'react';
import { Button, CardContent, Container, Grid, CircularProgress, FormLabel, Card, Avatar, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { makeStyles } from '@material-ui/core/styles';
import { getHistory } from '../../../utils/history.util';
import { Loading } from '../../../shared/loading/loading.component';
import { FeeFreeTime, FeeFreeTimeByDay } from '../../../models/FeeFreeTime';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PlayerVerifyDocumentV2StatusEnum } from '../../../enum/player-v2/PlayerVerifyDocumentV2StatusEnum';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
    padding: 10,
    marginBottom: '2.5rem',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    borderBottom: '1px solid #646464',
    marginBottom: '10px',
    paddingBottom: '10px',
  },
  cardContent: {
    display: 'flex',
    textAlign: 'center',
    padding: '10px 16px !important',
  },
  methodAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    display: 'block',
    // marginLeft: 'auto',
    // marginRight: 'auto',
  },
  button: {
    paddingTop: theme.spacing(),
  },
  alertBody: {
    width: '100%',
  },
  alertContainer: {
    marginBottom: '1rem',
  },
  methodBox: {
    cursor: 'pointer',
    transition: 'all .2s ease-in',
    '&:hover': {
      boxShadow: '0 0 10px 2px rgba(0, 0, 0, .40)',
      transform: 'scale(1.01)',
    },
  },
  methodInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexGrow: 1,
  },
}));

export interface ExternalProps {
  user: any;
  paymentMethods: any;
  isLoading: boolean;
  type: any;
  feeFreeTimes: FeeFreeTime[];
  cashierDialog: any;
}

export interface ExternalActionProps {
  setTransactionMethodAction: (method: any) => void;
  getOnlineHoursAction: (type: any) => void;
  getPaymentMethodsAction: (x: { playerNumber: any; type: any }) => void;
  setCashierDialogAction: (data: any) => void;
  setTransactionTypeAction: (data: number) => void;
}

export const TransactionMethodPage = (props: ExternalProps & ExternalActionProps) => {
  const {
    user,
    paymentMethods,
    isLoading,
    type,
    feeFreeTimes,
    setTransactionMethodAction,
    getPaymentMethodsAction,
    getOnlineHoursAction,
    setCashierDialogAction,
    setTransactionTypeAction,
    cashierDialog,
  } = props;

  const [open, setOpen] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (type == null || type == undefined) {
      cashierDialog.isShow ? setCashierDialogAction({ isShow: true, type: 'cashier' }) : getHistory().push('/cashier');
    } else {
      getPaymentMethodsAction({ playerNumber: user.username, type: type });
      getOnlineHoursAction(type);
    }
  }, []);

  // const goToCognito = () => {
  //   const _user = localStorage.getItem('user'),
  //     _userObj = _user && JSON.parse(_user),
  //     inputData = {
  //       username: _userObj?.username,
  //       email: _userObj?.email,
  //       token: localStorage.getItem('token'),
  //     };
  //   window.open(`
  //     https://cisso.services/flow.php?input=${btoa(JSON.stringify(inputData))}
  //   `);
  // };

  const handlerRedirect = () => {
    setCashierDialogAction({ isShow: false, type: '' });
    localStorage.setItem('openDialogProfile', '1');
    getHistory().push('/profile');
  };

  return (
    <React.Fragment>
      <Loading loading={isLoading} />
      <Container className={classes.container} maxWidth='sm'>
        <FormLabel component='legend'>
          <Typography variant='h6' classes={{ root: classes.title }} style={{ marginTop: '.5rem' }}>
            Select a {type == 1 ? 'Deposit' : 'Withdrawal'} Method
          </Typography>
        </FormLabel>
        {!isLoading && paymentMethods && paymentMethods.length < 2 && type == 1 && (
          <Alert
            severity='info'
            classes={{
              message: classes.alertBody,
              root: classes.alertContainer,
            }}
          >
            <AlertTitle>
              <Typography variant='body1'>Want More Deposit Methods?</Typography>
            </AlertTitle>
            <Typography variant='body2'>
              Verify your identity via Live Chat today to receive access to additional deposit methods like Debit Card, Zelle, Paypal and
              much more!
            </Typography>
          </Alert>
        )}

        <div className={classes.button} style={{ marginBottom: '12px' }}>
          <Button
            variant='outlined'
            onClick={() => {
              cashierDialog.isShow
                ? setCashierDialogAction({ isShow: true, type: 'cashier', transactionType: null })
                : getHistory().goBack();

              setTransactionTypeAction(-1);
            }}
          >
            <Typography>Back</Typography>
          </Button>
        </div>

        {isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress color='secondary' style={{ marginTop: '1rem' }} />
            <Typography variant='subtitle2' style={{ color: 'white', fontWeight: 'bold' }}>
              Loading Methods...
            </Typography>
          </div>
        ) : (
          <Grid container spacing={2} style={{ padding: '10px 0' }}>
            {!isLoading &&
              paymentMethods
                .filter(
                  (x: any) =>
                    (type == 2 && x.id != 2) ||
                    (type == 2 && x.id == 2 && x.status == 1 && !((paymentMethods ?? []).find((x: any) => x.id == 11)?.status ?? false)) ||
                    (type == 1 && x.id != 3) ||
                    (type == 1 && x.id == 3 && x.status == 1),
                )
                .map((method: any) => (
                  <Grid
                    item
                    xs={12}
                    style={{ padding: '1px 8px' }}
                    className={`tour_cashier-${method.name.replace(/\s/g, '').toLowerCase()} `}
                    key={method.id}
                  >
                    <Card
                      className={`${classes.methodBox} ${
                        method.playerHasActive != null && method.playerHasActive == false ? 'disableCard' : ''
                      }`}
                      onClick={() => {
                        if (method.playerHasActive != null && method.playerHasActive == false) {
                          setOpen(true);
                          return;
                        }
                        setTransactionMethodAction(method);
                        if (type == 1) {
                          cashierDialog.isShow
                            ? setCashierDialogAction({ isShow: true, type: 'cashier/bonus', transactionType: type })
                            : getHistory().push('/cashier/bonus');
                        } else {
                          cashierDialog.isShow
                            ? setCashierDialogAction({ isShow: true, type: 'cashier/info', transactionType: type })
                            : getHistory().push('/cashier/info');
                        }
                      }}
                    >
                      <CardContent classes={{ root: classes.cardContent }}>
                        <div style={{ display: 'flex', alignItems: 'center', minWidth: '185px' }}>
                          <Avatar
                            className={`${classes.methodAvatar} ${
                              method.playerHasActive != null && method.playerHasActive == false ? 'blackAndWhiteImage' : ''
                            }`}
                            src={'images/payment_methods/' + method.image}
                          />
                          <Typography variant='body2' style={{ marginLeft: '7px' }}>
                            <strong>{method.name}</strong>
                          </Typography>
                        </div>

                        <div className={classes.methodInfo} style={{ textAlign: 'left' }}>
                          <Typography variant='body2'>
                            Min: ${Intl.NumberFormat().format(type == 1 ? method.deposit_min : method.withdrawal_min)}
                          </Typography>
                          <Typography variant='body2'>
                            Max: ${Intl.NumberFormat().format(type == 1 ? method.deposit_max : method.withdrawal_max)}
                          </Typography>
                          <Typography variant='body2'>Fee: {method.fee ?? 0}%</Typography>
                        </div>

                        {/* <div style={{ textAlign: 'left' }}>
                            <Typography variant='body2'>Fee: {method.fee ?? 0}%</Typography>
                            <Typography variant='body2'>
                              Min: ${Intl.NumberFormat().format(type == 1 ? method.deposit_min : method.withdrawal_min)}
                            </Typography>
                            <Typography variant='body2'>
                              Max: ${Intl.NumberFormat().format(type == 1 ? method.deposit_max : method.withdrawal_max)}
                            </Typography>
                          </div> */}
                        {/* {hours != null && (method.name == 'Zelle' || method.name == 'Apple Pay') && type == 1 && (
                          <Alert
                            severity='warning'
                            classes={{
                              message: classes.alertBody,
                              root: classes.alertContainer,
                            }}
                            style={{ marginTop: '1rem' }}
                          >
                            <AlertTitle>
                              <Typography variant='body1'>Manual Method</Typography>
                            </AlertTitle>
                            <Typography variant='body2'>
                              {method.name} processing hours are from: {hours.open} - {hours.close} {hours.timezone}. <br />
                              Any requests outside these hours will be processed the following day.
                            </Typography>
                          </Alert>
                        )} */}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            {type == 2 && (
              <Grid item xs={12}>
                <Alert severity='info' style={{ marginTop: '.5rem' }}>
                  <AlertTitle>
                    <Typography variant='body1'>(Instant) Pay Withdrawals Only available to US based clients</Typography>
                  </AlertTitle>
                  <Typography variant='body2'>(Instant) Pay withdrawals available for verified users!</Typography>
                  <iframe
                    width='300'
                    height='315'
                    src='https://www.youtube.com/embed/tunXmNtSr38'
                    title='YouTube video player'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    // allowfullscreen
                    style={{ width: '100%', marginTop: '1rem', maxWidth: '280px', display: 'block', margin: '0px auto' }}
                  ></iframe>
                </Alert>
              </Grid>
            )}
          </Grid>
        )}

        {type == 2 &&
          feeFreeTimes &&
          feeFreeTimes.length > 0 &&
          feeFreeTimes.map((fft: FeeFreeTime) => {
            return (
              <>
                <Alert
                  severity='success'
                  style={{ marginTop: '.5rem', textAlign: 'center' }}
                  className='AlertWithdrawals'
                  key={`PM${fft.id}`}
                >
                  <AlertTitle>
                    <Typography variant='body1'>Free {fft.name} Withdrawal Periods</Typography>
                  </AlertTitle>
                  {fft &&
                    fft.days.map((day: FeeFreeTimeByDay) => {
                      return (
                        <Typography variant='body2' style={{ fontWeight: 'bold' }} gutterBottom key={`Day${day.week_day_id}PM${fft.id}`}>
                          {day.week_day.name} - {day.start_time} to {day.end_time} PST
                        </Typography>
                      );
                    })}
                  <br />
                  <Typography variant='caption'></Typography>
                </Alert>
              </>
            );
          })}
        {/* {type == 2 && feeFreeTimes && feeFreeTimes.length > 0 && (
          <>
            <Alert severity='success' style={{ marginTop: '.5rem', textAlign: 'center' }} className='AlertWithdrawals'>
              <Typography variant='body2'>Withdrawals outside of these hours will be processed at default fees.</Typography>
              <br />
              <Typography variant='caption'>These periods are subject to change at Managements discretion.</Typography>
            </Alert>
          </>
        )} */}
      </Container>

      <Dialog fullWidth maxWidth='sm' open={open} onClose={() => setOpen(false)} aria-labelledby='max-width-dialog-title'>
        <DialogTitle>Payment method not available</DialogTitle>
        <DialogContent>
          {user.verification_request_status && (
            <p>
              {user.verification_request_status &&
                (user.verification_request_status == PlayerVerifyDocumentV2StatusEnum.PENDING ||
                  user.verification_request_status == PlayerVerifyDocumentV2StatusEnum.RETURNED) && (
                  <p>Your identity verification request is in process, it will be ready soon.</p>
                )}

              {user.verification_request_status && user.verification_request_status == PlayerVerifyDocumentV2StatusEnum.REJECTED && (
                <p>
                  <p>Your request has been rejected. </p>
                  <small>Reason: {user.verification_request_note}</small>
                  <p>Press continue to resend the identity verification request.</p>
                </p>
              )}

              {user.verification_request_status && user.verification_request_status == PlayerVerifyDocumentV2StatusEnum.NOTEXISTS && (
                <p>
                  To gain access to this payment method, please verify your identity. Please select continue to submit a verification
                  request.
                </p>
              )}
            </p>
          )}
        </DialogContent>
        <DialogActions>
          {user.verification_request_status &&
            (user.verification_request_status == PlayerVerifyDocumentV2StatusEnum.NOTEXISTS ||
              user.verification_request_status == PlayerVerifyDocumentV2StatusEnum.REJECTED) && (
              <Button variant='contained' color='primary' onClick={handlerRedirect}>
                Continue
              </Button>
            )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
