import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { API_PATH } from '../../../constants';
import { IPlayerTransactionLimitDetails } from '../../../models/player-transaction-limit-details';
import { IVipResponse } from '../../../models/vip-response';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const GET_TRANSACTION_LIMIT = `GET_TRANSACTION_LIMIT${suffix}`;

export const getTransactionLimitAction = createAction(GET_TRANSACTION_LIMIT);

const GET_TRANSACTION_LIMIT_SUCCESS = `GET_TRANSACTION_LIMIT_SUCCESS${suffix}`;
const getTransactionLimitSuccessAction = createAction<IPlayerTransactionLimitDetails>(GET_TRANSACTION_LIMIT_SUCCESS);

const GET_TRANSACTION_LIMIT_ERROR = `GET_TRANSACTION_LIMIT_ERROR${suffix}`;
const getTransactionLimitErrorAction = createAction<string>(GET_TRANSACTION_LIMIT_ERROR);

function* getTransactionLimitWatcher(): SagaIterator {
  yield takeEvery(GET_TRANSACTION_LIMIT, getTransactionLimitWorker);
}
mergeSaga(getTransactionLimitWatcher);

function* getTransactionLimitWorker(): SagaIterator {
  try {
    const result: IVipResponse<IPlayerTransactionLimitDetails> = yield call(getTransactionLimitApi);
    yield put(getTransactionLimitSuccessAction(result.data));
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(getTransactionLimitErrorAction(e));
  }
}

const getTransactionLimitApi = (): Request => {
  return vipApi(API_PATH.CASHIER.GET_TRANSACTION_LIMIT, 'get', {});
};

const reduceHandlers = {
  [GET_TRANSACTION_LIMIT]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
    transactionLimitDetails: undefined,
  }),
  [GET_TRANSACTION_LIMIT_SUCCESS]: (
    slice: CashierTransactionSliceType,
    action: Action<IPlayerTransactionLimitDetails>,
  ): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    transactionLimitDetails: action.payload,
  }),
  [GET_TRANSACTION_LIMIT_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    transactionLimitDetails: undefined,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
