import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Card, CardContent, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  content: {
    flex: '2 0 auto',
  },
  cover: {
    width: 200,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  spacer: {
    width: 20,
  },
  infoText: {
    textAlign: 'left',
    '& .MuiAlert-icon': {
      marginRight: '0',
      '& .MuiSvgIcon-root': {
        fontSize: '21px',
        marginTop: '10px',
      },
    },
  },
});

export interface ExternalProps {
  transactionRequest: any;
  bankReference: any;
}

export const CADepositCard = (props: ExternalProps) => {
  const { transactionRequest, bankReference } = props;

  const [copySuccess, setCopySuccess] = useState(false);
  const classes = useStyles();

  return (
    <Card>
      <div className={classes.details}>
        {bankReference != null && (
          <CardContent>
            {bankReference != '' && (
              <a href={`https://cash.app/${bankReference}/${transactionRequest.totalToSend}`} target='_blank' rel='noopener noreferrer'>
                <img src={`https://cash.app/qr/${bankReference}?size=288&margin=15`} />
              </a>
            )}
            <Typography component='h6' variant='h6'>
              Please send ${transactionRequest.totalToSend} to {bankReference}
            </Typography>

            <br />
            <div className={classes.buttons}>
              <Button
                variant='contained'
                href={`https://cash.app/${bankReference}/${transactionRequest.totalToSend}`}
                rel='noopener noreferrer'
                target='_blank'
              >
                <Typography>Open Cash App</Typography>
              </Button>
              <div className={classes.spacer} />
              <Button
                variant='contained'
                onClick={() => {
                  navigator.clipboard.writeText(bankReference);
                  setCopySuccess(true);
                  setTimeout(() => setCopySuccess(false), 2000);
                }}
              >
                {copySuccess ? <Typography variant='subtitle2'>Copied!</Typography> : <Typography>Copy Cash App Tag</Typography>}
              </Button>
            </div>
            <br />

            <Alert severity='info' className={classes.infoText}>
              <Accordion style={{ backgroundColor: 'transparent', color: '#a6d5fa' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                  <AlertTitle style={{ margin: '0' }}>Need Help?</AlertTitle>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block' }}>
                  To complete your Cash App deposit, please send to the Cash App tag above. <br />
                  <br />
                  You can scan the QR code or click on &apos;Open Cash App&apos; in your phone. <br />
                  <br />
                  Alternatively, you can copy the Cash App tag and paste it into Cash App to send the funds.
                </AccordionDetails>
              </Accordion>
            </Alert>
          </CardContent>
        )}
      </div>
    </Card>
  );
};
