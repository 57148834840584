import React, { useEffect, useState, useRef } from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  FormLabel,
  Chip,
  Typography,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Collapse,
  TextField,
  Hidden,
} from '@material-ui/core';
import { getHistory } from '../../../utils/history.util';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Loading } from '../../../shared/loading/loading.component';
import { ContTable } from '../cashier-home-page/components/ContTable';
import { ContDialog } from '../cashier-home-page/components/ContDiaglog';
// import { VipCrownIcon } from '../../../shared/icons/crown-icon';
import { BonusGameWinnerEnum, BonusTeamEnumEnum } from '../../../enum/bonus/bonus-game-winner-enum';
import { PickListBonusGame } from '../../../shared/components/bonus/bonus-list-picks';
import { BonusAmountTypeEnum } from '../../../enum/bonus/bonus-type-amount-enum';
const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
    padding: 15,
    marginBottom: '3rem',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
  },
  alertBody: {
    marginLeft: -22,
    width: '100%',
    textAlign: 'center',
  },
  card: {
    marginBottom: 10,
  },
  alreadyOnBonusCard: {
    textAlign: 'center',
    padding: 15,
    marginBottom: 10,
  },
  loader: {
    padding: 15,
  },
  bonusDetails: {
    display: 'grid',
    textAlign: 'center',
    marginTop: 10,
  },
  button: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  message: {
    width: '100%',
  },
  bonusDescription: {
    '& p': {
      margin: 0,
      marginBottom: '5px',
    },
  },
  containerPicks: { margin: '10px 0 10px 0', border: '1px solid rgb(206, 168, 69)', padding: '7px 5px' },
}));

export interface ExternalProps {
  user: any;
  type: any;
  isLoading: any;
  rollover: any;
  availableBonuses: any;
  method: any;
  bonus: any;
  cashierDialog: any;
}

export interface ExternalActionProps {
  setTransactionBonusAction: (bonus: any) => void;
  getBonusesAction: (x: any) => void;
  getBonusByCodeAction: (code: any) => void;
  setCashierDialogAction: (data: any) => void;
}

export const BonusSelectPage = (props: ExternalProps & ExternalActionProps) => {
  const {
    user,
    isLoading,
    type,
    rollover,
    availableBonuses,
    method,
    bonus,
    setTransactionBonusAction,
    getBonusesAction,
    getBonusByCodeAction,
    setCashierDialogAction,
    cashierDialog,
  } = props;

  const [showDetails, setShowDetails] = useState(0);
  const mountedRef = useRef(true);
  const classes = useStyles();
  const [promoCode, setPromoCode] = useState('');
  const [contOpen, setContOpen] = useState(false);
  const [contData, setContData] = useState([]);
  const [showCodeBox, setShowCodeBox] = useState(false);

  useEffect(() => {
    // Object.keys(availableBonuses).length < 1
    if (method == undefined || method == null) {
      cashierDialog.isShow ? setCashierDialogAction({ isShow: true, type: 'cashier' }) : getHistory().push('/cashier');
    } else {
      getBonusesAction({
        player_number: user.username,
        methodId: method.id,
      });
    }

    return () => {
      mountedRef.current = false;
    };
  }, []);

  let isOnBonus = null;
  if (rollover != undefined) {
    isOnBonus = true;
  } else {
    isOnBonus = false;
  }

  if (type == 1 && bonus != null) {
    cashierDialog.isShow ? setCashierDialogAction({ isShow: true, type: 'cashier/info' }) : getHistory().push('/cashier/info');
  }

  // const getValidPlayLines = (x: any) => {
  //   return (
  //     <Grid item xs={12}>
  //       Valid Picks in:{' '}
  //       {!x.use_spread ? <Chip size='small' style={{ marginRight: '5px' }} label='Spread' color='primary' variant='outlined' /> : ' '}
  //       {x.use_moneyline ? <Chip size='small' style={{ marginRight: '5px' }} label='MoneyLine' color='primary' variant='outlined' /> : ' '}
  //       {!x.use_total ? <Chip size='small' style={{ marginRight: '5px' }} label='Total' color='primary' variant='outlined' /> : ' '}
  //     </Grid>
  //   );
  // };

  return (
    <>
      <Container className={classes.container} maxWidth='sm'>
        <Loading loading={isLoading} />

        <FormLabel component='legend'>
          <Typography variant='h6' classes={{ root: classes.title }}>
            Select a {type == 1 ? 'Deposit' : 'Withdrawal'} Bonus
          </Typography>
        </FormLabel>
        <hr />
        <Alert severity='info'>
          <Typography variant='body2'>If you would like to deposit without a bonus, you can skip by pressing the button below.</Typography>
        </Alert>
        <br />

        {!isLoading ? (
          <Grid item xs={12}>
            <ContDialog
              open={contOpen}
              onClose={() => {
                setContData([]);
                setContOpen(false);
              }}
              data={<ContTable rows={contData && contData != null ? contData : []} />}
            />

            {isOnBonus && (
              <Alert severity='warning' classes={{ root: classes.alreadyOnBonusCard }}>
                <Typography variant='body2'>
                  It looks like you are already on a bonus. You cannot redeem a bonus while on a current rollover.
                </Typography>
              </Alert>
            )}

            {/* <Alert
              severity='success'
              variant='outlined'
              classes={{ message: classes.message }}
              style={{ textAlign: 'center', marginBottom: '1rem', minWidth: '100%' }}
              icon={false}
            >
              <AlertTitle> Use special promo code below!</AlertTitle>
              <div style={{ minWidth: '100%' }}>
                <Grid container style={{ marginTop: '.5rem', marginBottom: '1rem', textAlign: 'center', width: '100%' }}>
                  <Grid item xs={12} style={{ display: 'block', margin: '0px auto', padding: 10 }}>
                    <TextField
                      variant='outlined'
                      label='Promo Code'
                      size='small'
                      value={promoCode}
                      onChange={event => {
                        setPromoCode(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ padding: 10 }}>
                    <Button
                      variant='contained'
                      style={{ marginLeft: '.5rem', marginRight: '.5rem' }}
                      size='large'
                      color='secondary'
                      onClick={() => {
                        getBonusByCodeAction({ code: promoCode });
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Alert> */}

            <div className={classes.button} style={{ marginBottom: '12px' }}>
              <Button
                variant='contained'
                color='default'
                onClick={() => {
                  setTransactionBonusAction(null);
                  cashierDialog.isShow
                    ? setCashierDialogAction({ isShow: true, type: 'cashier/method', transactionType: type })
                    : getHistory().goBack();
                }}
              >
                <Typography>Back</Typography>
              </Button>

              <Button
                variant='outlined'
                color='default'
                onClick={() => {
                  setTransactionBonusAction(null);
                  cashierDialog.isShow
                    ? setCashierDialogAction({ isShow: true, type: 'cashier/info', transactionType: type })
                    : getHistory().push('/cashier/info');
                }}
              >
                <Typography>Skip</Typography>
              </Button>
            </div>

            {availableBonuses.length > 0 ? (
              availableBonuses.map((bonus: any) => (
                <Card key={bonus.id} className={classes.card}>
                  <CardContent
                    onClick={() => {
                      if (bonus.id == showDetails) {
                        setShowDetails(0);
                      } else {
                        setShowDetails(bonus.id);
                      }
                      // setTransactionBonusAction(bonus);
                    }}
                  >
                    <div style={{ margin: '0px auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography
                        variant='subtitle1'
                        color='textSecondary'
                        dangerouslySetInnerHTML={{ __html: bonus.description }}
                        gutterBottom
                        style={{ margin: '0' }}
                        className={classes.bonusDescription}
                      ></Typography>

                      {bonus.details && bonus.details != null && bonus.details != 'null' && (
                        <Typography
                          variant='subtitle1'
                          // color='textSecondary'
                          dangerouslySetInnerHTML={{ __html: bonus.details }}
                          style={{ color: 'orange' }}
                        ></Typography>
                      )}

                      <Hidden smDown>
                        <div className={classes.center}>{bonus.id == showDetails ? <ArrowDropUpIcon /> : <ArrowDropDownCircleIcon />}</div>
                      </Hidden>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Chip label={bonus.type} color={bonus.type == 'Free Play' ? 'primary' : 'secondary'} />
                      <Hidden mdUp>
                        <div className={classes.center}>{bonus.id == showDetails ? <ArrowDropUpIcon /> : <ArrowDropDownCircleIcon />}</div>
                      </Hidden>
                    </div>
                  </CardContent>
                  <Collapse in={bonus.id === showDetails}>
                    <Alert severity='warning' classes={{ message: classes.alertBody }}>
                      {(bonus.category == 'gradedgames' || bonus.category == 'riskfreebet') && (
                        <>
                          <AlertTitle>
                            <Typography variant='body1'>Bonus Terms</Typography>
                          </AlertTitle>
                          <Typography variant='body2' gutterBottom dangerouslySetInnerHTML={{ __html: bonus.terms }}></Typography>

                          {bonus &&
                            bonus?.bonus_games &&
                            Array.isArray(bonus?.bonus_games) &&
                            bonus.bonus_games.map((x: any, index: number) => {
                              return (
                                <>
                                  {index == 0 && (
                                    <>
                                      <Grid container>
                                        {/* <Grid item xs={2}>
                                          WINNER
                                        </Grid> */}
                                        <Grid item xs={9}>
                                          LINE
                                        </Grid>
                                        <Grid item xs={3}>
                                          MIN BET AMOUNT
                                        </Grid>
                                      </Grid>
                                    </>
                                  )}
                                  <Grid container className={classes.containerPicks}>
                                    {(x.winner == BonusGameWinnerEnum.VISITOR || !x.winner) && (
                                      <>
                                        {/* <Grid item xs={2}>
                                          <VipCrownIcon></VipCrownIcon>
                                        </Grid> */}
                                        <Grid item xs={8}>
                                          #{x.vnum} {x.visitor}
                                        </Grid>
                                        <Grid item xs={4}>
                                          ${x.min_amount}
                                        </Grid>
                                        {/* <Grid item xs={2}>
                                          ${x.max_amount}
                                        </Grid> */}
                                        <PickListBonusGame BonusOrRollover={x} Team={BonusTeamEnumEnum.VISITOR} />
                                      </>
                                    )}
                                    <br />
                                    {(x.winner == BonusGameWinnerEnum.HOME || !x.winner) && (
                                      <>
                                        {/* <Grid item xs={2}>
                                          <VipCrownIcon></VipCrownIcon>
                                        </Grid> */}
                                        <Grid item xs={8}>
                                          #{x.hnum} {x.home}
                                        </Grid>
                                        <Grid item xs={4}>
                                          ${x.min_amount}
                                        </Grid>
                                        {/* <Grid item xs={2}>
                                          ${x.max_amount}
                                        </Grid> */}
                                        <PickListBonusGame BonusOrRollover={x} Team={BonusTeamEnumEnum.HOME} />
                                      </>
                                    )}
                                  </Grid>
                                </>
                              );
                            })}

                          <div className={classes.bonusDetails}>
                            <Typography variant='caption'>Bonus Details:</Typography>
                            <Typography variant='caption'>
                              Deposit: Min ${bonus.min_deposit} - Max ${bonus.max_deposit}
                            </Typography>
                            {bonus.category == 'riskfreebet' && (
                              <Typography variant='caption'>
                                {bonus.type_amount_id == BonusAmountTypeEnum.FIXED && (
                                  <Typography variant='body2'>{`Freeplay-back: $${bonus.match_multiplier * 100}`}</Typography>
                                )}
                                {bonus.type_amount_id == BonusAmountTypeEnum.PERCENT && (
                                  <Typography variant='body2'>
                                    {`Freeplay-back: $${bonus.max_deposit} Risk * ${bonus.match_multiplier * 100}% = $${
                                      bonus.max_deposit * bonus.match_multiplier
                                    }`}
                                  </Typography>
                                )}
                              </Typography>
                            )}
                          </div>
                          <Typography variant='caption'>*See Bonus Rules for complete terms and Conditions</Typography>
                        </>
                      )}
                      {bonus.category == 'deposit' && (
                        <>
                          <AlertTitle>
                            <Typography variant='body1'>Wagering Requirement Before Withdrawals</Typography>
                          </AlertTitle>

                          <Typography variant='body2'>For a bonus with {bonus.rollover_multiplier * 1}x Rollover:</Typography>

                          <Typography variant='body2'>
                            $100 Deposit + ${bonus.match_multiplier * 100} Bonus = ${bonus.match_multiplier * 100 + 100}
                          </Typography>

                          <Typography variant='body2'>
                            ${bonus.match_multiplier * 100 + 100} x {Math.floor(bonus.rollover_multiplier)}x Rollover Multiplier = $
                            {bonus.rollover_multiplier * (bonus.match_multiplier * 100 + 100)}
                          </Typography>

                          <Typography variant='body2'>
                            This means you must wager a total of ${bonus.rollover_multiplier * (bonus.match_multiplier * 100 + 100)} to be
                            eligible for a Withdrawal.
                          </Typography>
                          <Typography variant='caption'>*See Bonus Rules for complete terms and Conditions</Typography>
                          <div className={classes.bonusDetails}>
                            <Typography variant='caption'>Bonus Details:</Typography>
                            <Typography variant='caption'>
                              Deposit: Min ${bonus.min_deposit} - Max ${bonus.max_deposit}
                            </Typography>
                            {bonus.match_multiplier != 0 && bonus.category == 'deposit' && (
                              <Typography variant='caption'>
                                {bonus.type_amount_id == BonusAmountTypeEnum.FIXED
                                  ? `$${bonus.match_multiplier * 100}`
                                  : `${bonus.match_multiplier * 100}% Match`}
                                {` | ${Math.floor(bonus.rollover_multiplier)}x Rollover`}
                              </Typography>
                            )}
                          </div>
                        </>
                      )}
                      <Grid container style={{ paddingTop: '1rem', paddingBottom: '1rem' }} spacing={2}>
                        <Grid item xs={6}>
                          {bonus.category == 'deposit' && (
                            <Button
                              variant='outlined'
                              style={{ marginLeft: '.5rem', marginRight: '.5rem' }}
                              color='default'
                              onClick={() => {
                                setContData(bonus.contribution_table);
                                setContOpen(true);
                              }}
                            >
                              Contribution
                            </Button>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant='contained'
                            color='secondary'
                            onClick={() => {
                              setTransactionBonusAction(bonus);
                              if (bonus.id == 97) {
                                // hardcodedBonusValidation
                                if (confirm('You are about to redeem a CASINO ONLY bonus. Are you sure?')) {
                                  cashierDialog.isShow
                                    ? setCashierDialogAction({ isShow: true, type: 'cashier/info', transactionType: type })
                                    : getHistory().push('/cashier/info');
                                }
                              } else {
                                cashierDialog.isShow
                                  ? setCashierDialogAction({ isShow: true, type: 'cashier/info', transactionType: type })
                                  : getHistory().push('/cashier/info');
                              }
                            }}
                          >
                            <Typography>Claim Bonus</Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Alert>
                  </Collapse>
                </Card>
              ))
            ) : (
              <Typography variant='h6' style={{ marginBottom: '10px', textAlign: 'center' }}>
                There are no available bonuses.
              </Typography>
            )}

            <Card className={classes.card}>
              <CardContent onClick={() => setShowCodeBox(!showCodeBox)}>
                {/* <Chip label='Enter Another Code' color='primary' /> */}
                <div style={{ margin: '0px auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant='subtitle1' color='textSecondary' gutterBottom style={{ margin: '0' }}>
                    Use special promo code below!
                  </Typography>

                  <Typography variant='subtitle1' style={{ color: 'orange' }}>
                    Enter Another Code
                  </Typography>

                  <div className={classes.center}>{showCodeBox ? <ArrowDropUpIcon /> : <ArrowDropDownCircleIcon />}</div>
                </div>
              </CardContent>
              <Collapse in={showCodeBox}>
                <div style={{ minWidth: '100%' }}>
                  <Grid container style={{ marginTop: '.5rem', marginBottom: '1rem', textAlign: 'center', width: '100%' }}>
                    <Grid item xs={12} style={{ display: 'block', margin: '0px auto', padding: 10 }}>
                      <TextField
                        variant='outlined'
                        label='Promo Code'
                        size='small'
                        value={promoCode}
                        onChange={event => {
                          setPromoCode(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 10 }}>
                      <Button
                        variant='contained'
                        style={{ marginLeft: '.5rem', marginRight: '.5rem' }}
                        size='large'
                        color='secondary'
                        onClick={() => {
                          getBonusByCodeAction({ code: promoCode });
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Collapse>
            </Card>
          </Grid>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress color='secondary' />
            <Typography variant='subtitle2'>Loading Bonuses...</Typography>
          </div>
        )}
      </Container>
    </>
  );
};
