import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { API_PATH } from '../../../constants';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const LOGIN_TO_BANK_ACTION = `LOGIN_TO_BANK_ACTION${suffix}`;

export const loginToBankAction = createAction(LOGIN_TO_BANK_ACTION);

const LOGIN_TO_BANK_ACTION_SUCCESS = `LOGIN_TO_BANK_ACTION_SUCCESS${suffix}`;
const loginToBankSuccessAction = createAction<any[]>(LOGIN_TO_BANK_ACTION_SUCCESS);

const LOGIN_TO_BANK_ACTION_ERROR = `LOGIN_TO_BANK_ACTION_ERROR${suffix}`;
const loginToBankErrorAction = createAction<string>(LOGIN_TO_BANK_ACTION_ERROR);

function* loginToBankWatcher(): SagaIterator {
  yield takeEvery(LOGIN_TO_BANK_ACTION, loginToBankWorker);
}
mergeSaga(loginToBankWatcher);

function* loginToBankWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(loginToBankApi, action.payload);
    yield put(loginToBankSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(loginToBankErrorAction(e));
  }
}

const loginToBankApi = (data: any): Request => {
  return vipApi(
    API_PATH.CASHIER.LOGIN_TO_INSTITUTION,
    'post',
    {
      institution_id: data.institution_id,
      fields: data.fields,
    },
    'json',
  );
};

const reduceHandlers = {
  [LOGIN_TO_BANK_ACTION]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
    institutions: [],
  }),
  [LOGIN_TO_BANK_ACTION_SUCCESS]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => {
    if (action.payload.data.mfa) {
      return { ...slice, isLoading: false, mfa: action.payload.data.mfa };
    } else {
      return { ...slice, isLoading: false, selectableBanks: action.payload.data.accounts };
    }
  },
  [LOGIN_TO_BANK_ACTION_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    institutions: [],
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
