import React, { useEffect } from 'react';
import {
  Dialog,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  NativeSelect,
} from '@material-ui/core';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
  button: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export interface ExternalActionProps {
  handleClose: () => void;
  getInstitutionsAction: () => void;
  loginToBankAction: (x: any) => void;
  completeChallengeAction: (x: any) => void;
  addPlayerBankAction: (x: any) => void;
}

export interface ExternalProps {
  open: boolean;
  institutions: any;
  selectableBanks: any;
  mfaChallenge: any;
  loading: any;
}

export const AddBankDialog = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const {
    open,
    handleClose,
    getInstitutionsAction,
    institutions,
    loginToBankAction,
    selectableBanks,
    mfaChallenge,
    loading,
    completeChallengeAction,
    addPlayerBankAction,
  } = props;
  const [institution, setInstitution] = React.useState<null | {
    id: number;
    name: string;
    fields: string;
    institution_id: string;
  }>(null);
  const [step, setStep] = React.useState(0);
  const [fields, setFields] = React.useState({});
  const [challenges, setChallenges] = React.useState<any[] | []>([]);
  //   const [selectedBank, setSelectedBank] = React.useState<null | {
  //     account: string;
  //     name: string;
  //     routing: string;
  //   }>(null);
  const [selectedBankType, setSelectedBankType] = React.useState('CHECKING');

  useEffect(() => {
    if (open) {
      getInstitutionsAction();
      setStep(0);
      setInstitution(null);
      setFields({});
      setChallenges([]);
    }
  }, [open]);

  useEffect(() => {
    if (mfaChallenge && mfaChallenge.length > 0) {
      setStep(2);
      setChallenges(mfaChallenge);
    }
  }, [mfaChallenge]);

  useEffect(() => {
    if (selectableBanks && selectableBanks.length > 0) {
      setStep(3);
    }
  }, [selectableBanks]);

  const formatForm = (i: any) => {
    const json = JSON.parse(i.fields);
    return json.map((field: any, index: any) => {
      return (
        <TextField
          type={field.type}
          name={field.name}
          key={index}
          label={field.description}
          fullWidth
          variant='filled'
          style={{ marginTop: '.5rem', marginBottom: '.5rem' }}
          onChange={e => {
            setFields({ ...fields, [field.name]: e.target.value });
          }}
          required
        />
      );
    });
  };

  const updateFieldChanged = (index: any, e: any) => {
    const newArr = [...challenges];
    newArr[index].response = e.target.value;
    setChallenges(newArr);
  };

  const CleanAndSubmit = (chall: any) => {
    const newChallenges: any = [];
    chall.map((c: any) => {
      if (c.selections) {
        delete c.selections;
      }
      if (c.image) {
        delete c.image;
      }
      newChallenges.push(c);
    });

    completeChallengeAction({
      institution_id: institution?.institution_id,
      fields: newChallenges,
    });
  };

  const formatChallenges = (i: any) => {
    if (i.length == 0) {
      return '';
    }
    return i.map((challenge: any, index: any) => {
      if (challenge.type == 'SELECTION') {
        return (
          <>
            <FormControl fullWidth style={{ marginTop: '1rem', marginBottom: '1rem' }} key={index}>
              <InputLabel variant='standard' htmlFor='uncontrolled-native' shrink>
                {challenge.description}
              </InputLabel>
              <NativeSelect
                defaultValue={''}
                inputProps={{
                  name: challenge.name,
                  id: 'uncontrolled-native',
                }}
                onChange={e => {
                  updateFieldChanged(index, e);
                }}
                variant='filled'
              >
                <option value={''}>Select an Option</option>
                {challenge.selections &&
                  challenge.selections.map((s: any, index: any) => {
                    return (
                      <option key={index} value={s.name}>
                        {s.description}
                      </option>
                    );
                  })}
              </NativeSelect>
            </FormControl>
          </>
        );
      }
      if (challenge.type == 'IMAGE') {
        return (
          <div key={index}>
            <Typography style={{ marginTop: '1rem' }}>{challenge.description}</Typography>
            <img src={challenge.image} style={{ width: '100%' }} />
            <TextField
              name={challenge.name}
              key={index}
              label={'Enter here..'}
              fullWidth
              variant='filled'
              style={{ marginTop: '.5rem', marginBottom: '.5rem' }}
              onChange={e => {
                updateFieldChanged(index, e);
              }}
              required
            />
          </div>
        );
      }
      return (
        <>
          <Typography style={{ marginTop: '1rem' }}>{challenge.description}</Typography>
          <TextField
            type={challenge.type}
            name={challenge.name}
            key={index}
            label={'Enter Here'}
            fullWidth
            variant='filled'
            style={{ marginTop: '.5rem', marginBottom: '.5rem' }}
            onChange={e => {
              updateFieldChanged(index, e);
            }}
            required
          />
        </>
      );
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      {institution == null && <DialogTitle>Add Bank</DialogTitle>}
      <DialogContent>
        {/* Step 0 - Select a Bank */}
        {step == 0 && (
          <>
            {institutions && institutions.length == 0 && 'Loading available banks...'}
            {institutions && institutions.length > 0 && <TextField id='name' label='Search' type='text' variant='filled' fullWidth />}
            {institutions &&
              institutions.length > 0 &&
              institutions.map((i: any) => {
                return (
                  <Box
                    key={i.id}
                    bgcolor={'secondary.main'}
                    style={{
                      padding: '.5rem',
                      marginTop: '.5rem',
                      marginBottom: '.5rem',
                      textAlign: 'center',
                      borderRadius: '.25rem',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setInstitution(i);
                      setStep(step + 1);
                    }}
                  >
                    <Typography variant='h6'>{i.name}</Typography>
                  </Box>
                );
              })}
          </>
        )}

        {/* Step 1 - Login To Bank */}
        <form
          method='POST'
          onSubmit={e => {
            e.preventDefault();
            loginToBankAction({
              institution_id: institution?.institution_id,
              fields: fields,
            });
          }}
        >
          {step == 1 && institution && (
            <>
              <Typography variant='h6'>{institution && institution.name != null && institution.name}</Typography>
              {formatForm(institution)}
            </>
          )}
          {step == 1 && institution && (
            <div className={classes.button}>
              <Button
                onClick={() => {
                  setInstitution(null);
                  setStep(step - 1);
                }}
                variant='outlined'
              >
                Back
              </Button>
              <Button type='submit' color='secondary' variant='contained' disabled={loading}>
                Login
              </Button>
            </div>
          )}
        </form>

        {/* Step 2 - Challenges */}
        {step == 2 && mfaChallenge && (
          <>
            <Typography variant='h6'>{institution && institution.name != null && institution.name}</Typography>
            <hr />
            <Typography variant='subtitle1'>Additional Steps are required to confirm your account.</Typography>
            <form
              method='POST'
              onSubmit={e => {
                e.preventDefault();
                CleanAndSubmit(challenges);
              }}
            >
              {formatChallenges(challenges)}
              <div className={classes.button}>
                <Button
                  onClick={() => {
                    setInstitution(null);
                    getInstitutionsAction();
                    setStep(0);
                  }}
                  variant='outlined'
                >
                  Back
                </Button>
                <Button type='submit' color='secondary' variant='contained' disabled={loading}>
                  Next
                </Button>
              </div>
            </form>
          </>
        )}

        {/* Step 3 - Select Bank */}
        {step == 3 && (
          <>
            <DialogTitle>Select Bank Account</DialogTitle>
            <hr />

            <FormControl fullWidth style={{ marginTop: '1rem', marginBottom: '1rem' }}>
              <InputLabel variant='standard' htmlFor='bank-select'>
                Account Type
              </InputLabel>
              <NativeSelect
                inputProps={{
                  name: 'bank-select',
                  id: 'bank-select',
                }}
                onChange={e => {
                  setSelectedBankType(e.target.value as string);
                }}
              >
                <option value={'CHECKING'}>Checking Account</option>
                <option value={'SAVINGS'}>Savings Account</option>
                <option value={'BUSINESS'}>Business Account</option>
              </NativeSelect>
            </FormControl>

            {selectableBanks &&
              selectableBanks.length > 0 &&
              selectableBanks.map((bank: any, index: any) => {
                return (
                  <Box
                    key={index}
                    bgcolor={'secondary.main'}
                    style={{ borderRadius: '.25rem', marginTop: '.5rem', marginBottom: '.5rem', textAlign: 'center' }}
                  >
                    <Typography variant='h6'>
                      {bank.name} #{bank.account}
                    </Typography>
                    <Typography variant='caption'>Routing: {bank.routing}</Typography>
                    <br />
                    <Button
                      variant='contained'
                      color='primary'
                      style={{ marginTop: '.5rem', marginBottom: '1rem' }}
                      onClick={() => {
                        addPlayerBankAction({
                          type: selectedBankType,
                          routing: bank.routing,
                          account: bank.account,
                        });
                      }}
                      disabled={loading}
                    >
                      Select Account
                    </Button>
                  </Box>
                );
              })}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setInstitution(null);
            handleClose();
          }}
        >
          Exit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
