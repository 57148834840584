import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { getPlayerBanksAction } from './get-player-banks.action';
import { API_PATH } from '../../../constants';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const ADD_BANK_ACTION = `ADD_BANK_ACTION${suffix}`;

export const addPlayerBankAction = createAction(ADD_BANK_ACTION);

const ADD_BANK_ACTION_SUCCESS = `ADD_BANK_ACTION_SUCCESS${suffix}`;
const addPlayerBankSuccessAction = createAction<any[]>(ADD_BANK_ACTION_SUCCESS);

const ADD_BANK_ACTION_ERROR = `ADD_BANK_ACTION_ERROR${suffix}`;
const addPlayerBankErrorAction = createAction<string>(ADD_BANK_ACTION_ERROR);

function* addPlayerBankWatcher(): SagaIterator {
  yield takeEvery(ADD_BANK_ACTION, addPlayerBankWorker);
}
mergeSaga(addPlayerBankWatcher);

function* addPlayerBankWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(addPlayerBankApi, action.payload);
    yield put(addPlayerBankSuccessAction(result));
    yield call(showToast, result.message, 'success');
    yield put(getPlayerBanksAction());
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(addPlayerBankErrorAction(e));
  }
}

const addPlayerBankApi = (data: any): Request => {
  return vipApi(API_PATH.CASHIER.ADD_BANK, 'post', {
    routing: data.routing,
    account: data.account,
    type: data.type,
  });
};

const reduceHandlers = {
  [ADD_BANK_ACTION]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [ADD_BANK_ACTION_SUCCESS]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    playerBanks: action.payload.data,
  }),
  [ADD_BANK_ACTION_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
