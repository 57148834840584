import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { API_PATH } from '../../../constants';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const CHECK_CBIO_AMOUNT = `CHECK_CBIO_AMOUNT${suffix}`;

export const checkCbioAmountAction = createAction<any>(CHECK_CBIO_AMOUNT);

const CHECK_CBIO_AMOUNT_SUCCESS = `CHECK_CBIO_AMOUNT_SUCCESS${suffix}`;
const checkCbioAmountSuccessAction = createAction<any[]>(CHECK_CBIO_AMOUNT_SUCCESS);

const CHECK_CBIO_AMOUNT_ERROR = `CHECK_CBIO_AMOUNT_ERROR${suffix}`;
const checkCbioAmountErrorAction = createAction<string>(CHECK_CBIO_AMOUNT_ERROR);

function* checkCbioAmountWatcher(): SagaIterator {
  yield takeEvery(CHECK_CBIO_AMOUNT, checkCbioAmountWorker);
}
mergeSaga(checkCbioAmountWatcher);

function* checkCbioAmountWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(checkCbioAmountApi, action.payload);
    yield put(checkCbioAmountSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(checkCbioAmountErrorAction(e));
  }
}

const checkCbioAmountApi = (data: any): Request => {
  return vipApi(API_PATH.CASHIER.CHECK_CBIO, 'post', {
    amount: data,
  });
};

const reduceHandlers = {
  [CHECK_CBIO_AMOUNT]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
    cbioAllowed: null,
  }),
  [CHECK_CBIO_AMOUNT_SUCCESS]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    cbioAllowed: 'allowed',
  }),
  [CHECK_CBIO_AMOUNT_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    cbioAllowed: null,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
