import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { CashierPageContainer } from '../../../../area/cashier/cashier-home-page/cashier.container';
import { TransactionInfoPageContainer } from '../../../../area/cashier/transaction-info-page/transaction-info-page.container';
import { TransactionMethodPageContainer } from '../../../../area/cashier/transaction-method-page/transaction-method-page.container';
import { ConfirmTransactionPageContainer } from '../../../../area/cashier/confirm-transaction-page/confirm-transaction-page.container';
import { RequestReceivedPageContainer } from '../../../../area/cashier/request-received-page/request-received-page.container';
import { CompletePagePageContainer } from '../../../../area/cashier/complete-page/complete-page.container';
import { BonusSelectPageContainer } from '../../../../area/cashier/bonus-select-page/bonus-select-page.container';
import { PlayerBankSelectPageContainer } from '../../../../area/cashier/player-bank-select-page/player-bank-select-page.container';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const useStyles: any = makeStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .75)',
  },
  dialogeHeader: {
    backgroundColor: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 20px',
    alignItems: 'center',
    '& p': {
      color: '#fff',
      fontWeight: 'bold',
    },
  },
});

export interface ExternalProps {
  cashierDialog: any;
}

export interface ExternalActionProps {
  onClose: () => void;
}

export const CashierDialog = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { cashierDialog, onClose } = props;

  const getComponent = () => {
    const type = cashierDialog?.type;
    switch (type) {
      case 'cashier':
        return <CashierPageContainer />;
        break;
      case 'cashier/method':
        return <TransactionMethodPageContainer />;
        break;
      case 'cashier/info':
        return <TransactionInfoPageContainer />;
        break;
      case 'cashier/confirm':
        return <ConfirmTransactionPageContainer />;
        break;
      case 'cashier/complete':
        return <CompletePagePageContainer />;
        break;
      case 'cashier/payment':
        return <RequestReceivedPageContainer />;
        break;
      case 'cashier/bonus':
        return <BonusSelectPageContainer />;
        break;
      case 'cashier/bank-select':
        return <PlayerBankSelectPageContainer />;
        break;
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={cashierDialog?.isShow}
        onClose={onClose}
        TransitionComponent={Transition as React.ComponentType}
        maxWidth='md'
        fullWidth
        aria-labelledby='max-width-dialog-title'
        className={classes.root}
      >
        <div className={classes.dialogeHeader}>
          <Typography>Cashier</Typography>
          <IconButton
            aria-label='close'
            onClick={onClose}
            style={{
              position: 'absolute',
              right: 2,
              color: 'white',
            }}
          >
            <CloseIcon style={{ fontSize: '17px' }} />
          </IconButton>
        </div>
        <DialogContent style={{ backgroundColor: '#121212', padding: '8px 1px' }}>{getComponent()}</DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
