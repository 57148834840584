import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import ConfirmTable from './cards/reviewTable';
import { Button, Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { Container, Typography } from '@material-ui/core';
import { getHistory } from '../../../utils/history.util';
import { Loading } from '../../../shared/loading/loading.component';
import { showToast } from '../../../utils/toast/show-errors';
import { TransactionTypeEnum } from '../../../enum/transactions/TransactionTypeEnum';
import { PaymentMethodEnum } from '../../../enum/transactions/PaymentMethodEnum';
import { BonusAmountTypeEnum } from '../../../enum/bonus/bonus-type-amount-enum';

const useStyles = makeStyles({
  container: {
    display: 'block',
    padding: 15,
    marginBottom: '3rem',
  },
  buttons: {
    paddingTop: 15,
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoBox: {
    width: '35px',
    display: 'inline-flex',
    marginRight: '10px',
    '& img': {
      maxWidth: '100%',
    },
  },
  greenBtn: {
    background: '#84b202',
    color: 'white',
    '&:hover': {
      background: '#678b02',
    },
  },
});

export interface ExternalProps {
  isLoading: any;
  account: any;
  amount: any;
  type: any;
  user: any;
  bonus: any;
  method: any;
  ipAddress: any;
  name: any;
  fingerprint: any;
  cbioAllowed: any;
  smsCodeCheck: boolean | null;
  routing_number: any;
  account_number: any;
  transactionRequest: any;
  isTransactionRequestSuccess: boolean;
  cashierDialog: any;
}

export interface ExternalActionProps {
  setTransactionNameAction: (name: any) => void;
  insertTransactionAction: (request: any) => void;
  checkCbioAmountAction: (amount: any) => void;
  sendSmsCodeAction: (username: string) => void;
  checkSmsCodeAction: (data: any) => void;
  setCashierDialogAction: (data: any) => void;
  insertTransactionResetAction: () => void;
  // setSmsCodeNullAction: () => void;
}

export const ConfirmTransactionPage = (props: ExternalProps & ExternalActionProps) => {
  const {
    isLoading,
    account,
    amount,
    type,
    user,
    bonus,
    method,
    ipAddress,
    name,
    fingerprint,
    cbioAllowed,
    smsCodeCheck,
    account_number,
    routing_number,
    transactionRequest,
    isTransactionRequestSuccess,
    setTransactionNameAction,
    insertTransactionAction,
    checkCbioAmountAction,
    sendSmsCodeAction,
    checkSmsCodeAction,
    setCashierDialogAction,
    cashierDialog,
    insertTransactionResetAction,
    // setSmsCodeNullAction,
  } = props;
  const [disableSubmit, setDisableSubmit] = useState(true);
  const classes = useStyles();
  const [agreed, setAgreed] = React.useState(false);
  const [agreedFee, setAgreedFee] = React.useState(false);
  const [verificationCode, setVerificationCode] = React.useState('');

  useEffect(() => {
    return () => {
      insertTransactionResetAction();
    };
  }, []);

  useEffect(() => {
    if (isTransactionRequestSuccess) {
      cashierDialog.isShow ? setCashierDialogAction({ isShow: true, type: 'cashier/payment' }) : getHistory().push('/cashier/payment');
    }
  }, [isTransactionRequestSuccess]);

  useEffect(() => {
    if (transactionRequest && transactionRequest.success != null) {
      if (transactionRequest.success == true) {
        cashierDialog.isShow ? setCashierDialogAction({ isShow: true, type: 'cashier/complete' }) : getHistory().push('/cashier/complete');
      }
    }
  }, [transactionRequest]);

  useEffect(() => {
    if (method == null) {
      cashierDialog.isShow ? setCashierDialogAction({ isShow: true, type: 'cashier' }) : getHistory().push('/cashier');
    }
    if (
      type == TransactionTypeEnum.WITHDRAWAL ||
      (type == TransactionTypeEnum.DEPOSIT && (method.id == PaymentMethodEnum.BITCOIN || method.id == PaymentMethodEnum.INSTANT_BITCOIN))
    ) {
      setDisableSubmit(false);
    }
    if (method.id == PaymentMethodEnum.INSTANT_PAY) {
      checkCbioAmountAction(amount);
    }
    if (type == TransactionTypeEnum.WITHDRAWAL) {
      sendSmsCodeAction(user.username);
    }
  }, []);

  const handleAgree = (event: any) => {
    if (name == '' || name == null) {
      showToast('Please enter your full name.', 'error');
    } else {
      setAgreed(event.target.checked);
    }
  };

  const handleAgreeFee = (event: any) => {
    setAgreedFee(event.target.checked);
  };

  function createData(dataType: any, dataAmount: any) {
    return { dataType, dataAmount };
  }

  const getFeeAmount = (amount: any, fee: any) => {
    const feePercent = fee / 100;

    amount = parseInt(amount);
    // feePercent = parseInt(feePercent);
    const finalFeeAmount = amount * feePercent;
    return finalFeeAmount < 1 && fee != 0 ? 1 : Math.round(finalFeeAmount);
  };

  const getBonusMatch = () => {
    if (bonus != null) {
      if (bonus?.type_amount_id == BonusAmountTypeEnum.FIXED) {
        return `$${bonus.match_multiplier * 100}`;
      }
      return `${bonus.match_multiplier * 100}%`;
    }
    return '';
  };

  const getBonusMatchAmount = (amount: number, bonus: any) => {
    if (bonus != null && bonus.category == 'deposit') {
      if (bonus.type_amount_id == BonusAmountTypeEnum.FIXED) {
        return bonus.match_multiplier * 100;
      }
      return amount * bonus.match_multiplier;
    }
    return 0;
  };

  const getTotal = (type: any, amount: any, bonusAmount: any) => {
    if (type == TransactionTypeEnum.DEPOSIT) {
      return parseInt(amount) + parseInt(bonusAmount);
    }
    return parseInt(amount);
  };

  const getTotalForTransaction = (type: any, amount: any, fee: any) => {
    if (type == TransactionTypeEnum.DEPOSIT) {
      return parseInt(amount) + parseInt(fee);
    }
    return parseInt(amount) - parseInt(fee);
  };

  const bitcoinRows = [
    createData('Amount', '$' + amount),
    createData('Fee', '$' + getFeeAmount(amount, method?.fee ?? 0)),
    createData(getBonusMatch() + ' Bonus', `$${getBonusMatchAmount(amount, bonus)}`),
    createData(
      type == TransactionTypeEnum.DEPOSIT ? 'Total Funded to Account' : 'Total Withdrawn from Account',
      '$' + getTotal(type, amount, getBonusMatchAmount(amount, bonus)),
    ),
    createData(
      <b style={{ color: 'limegreen' }}>
        <u>{type == TransactionTypeEnum.DEPOSIT ? 'Total to Send' : 'Total to Receive'}</u>
      </b>,
      <b style={{ color: 'limegreen' }}>
        <u>${getTotalForTransaction(type, amount, getFeeAmount(amount, method?.fee ?? 0))}</u>
      </b>,
    ),
  ];

  const cashappRows = [
    createData('Your Cash Tag', account),
    createData('Amount', '$' + amount),
    createData('Fee', '$' + getFeeAmount(amount, method?.fee ?? 0)),
    createData(getBonusMatch() + ' Bonus', `$${getBonusMatchAmount(amount, bonus)}`),
    createData(
      type == TransactionTypeEnum.DEPOSIT ? 'Total funded to account' : 'Total withdrawn',
      '$' + getTotal(type, amount, getBonusMatchAmount(amount, bonus)),
    ),
    createData(
      <b style={{ color: 'limegreen' }}>
        <u>
          {type == TransactionTypeEnum.DEPOSIT
            ? `Total to Send via ${type == TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'}`
            : 'Total to Receive'}
        </u>
      </b>,
      <b style={{ color: 'limegreen' }}>
        <u>{'$' + getTotalForTransaction(type, amount, getFeeAmount(amount, method?.fee ?? 0))}</u>
      </b>,
    ),
  ];

  const applepayRows = [
    createData('Apple Pay Account', account),
    createData('Amount', '$' + amount),
    createData('Fee', '$' + getFeeAmount(amount, method?.fee ?? 0)),
    createData(getBonusMatch() + ' Bonus', `$${getBonusMatchAmount(amount, bonus)}`),
    createData(
      type == TransactionTypeEnum.DEPOSIT ? 'Total funded to account' : 'Total withdrawn',
      '$' + getTotal(type, amount, getBonusMatchAmount(amount, bonus)),
    ),
    createData(
      <b style={{ color: 'limegreen' }}>
        <u>
          {type == TransactionTypeEnum.DEPOSIT
            ? `Total to Send via ${type == TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'}`
            : 'Total to Receive'}
        </u>
      </b>,
      <b style={{ color: 'limegreen' }}>
        <u>{'$' + getTotalForTransaction(type, amount, getFeeAmount(amount, method?.fee ?? 0))}</u>
      </b>,
    ),
  ];

  const otherRows = [
    createData('Amount', '$' + amount),
    createData('Fee', '$' + getFeeAmount(amount, method?.fee ?? 0)),
    createData(getBonusMatch() + ' Bonus', `$${getBonusMatchAmount(amount, bonus)}`),
    createData(
      type == TransactionTypeEnum.DEPOSIT ? 'Total funded to account' : 'Total withdrawn',
      '$' + getTotal(type, amount, getBonusMatchAmount(amount, bonus)),
    ),
    createData(
      <b style={{ color: 'limegreen' }}>
        <u>
          {type == TransactionTypeEnum.DEPOSIT
            ? `Total to Send via ${type == TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'}`
            : 'Total to Receive'}
        </u>
      </b>,
      <b style={{ color: 'limegreen' }}>
        <u>{'$' + getTotalForTransaction(type, amount, getFeeAmount(amount, method?.fee ?? 0))}</u>
      </b>,
    ),
  ];

  const getRows = (method: any) => {
    if (method.id == PaymentMethodEnum.BITCOIN || method.id == PaymentMethodEnum.INSTANT_BITCOIN) {
      return bitcoinRows;
    }
    if (method.id == PaymentMethodEnum.APPLE_PAY) {
      return applepayRows;
    }
    if (method.id == PaymentMethodEnum.CASH_APP) {
      return cashappRows;
    }
    return otherRows;
  };

  const getDate = () => {
    const today = new Date();
    const dd = today.getDate();

    let mmnew = null;
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();

    if (mm < 10) {
      mmnew = '0' + mm;
    } else {
      mmnew = mm;
    }

    // const date = mm + "/" + dd + "/" + yyyy;
    const date = yyyy + '-' + mmnew + '-' + (dd < 10 ? '0' + dd : dd);
    return date;
  };

  let tos =
    'VIPClub only accepts BTC to load player accounts. Your fiat currency will be converted to BTC via our integration with our digital currency trading partner, Paxful, in the next step. \n\nPaxful is a digital currency peer-to-peer trading platform. By signing your name below, you are acknowledging that you are purchasing Bitcoin from a trusted digital currency vendor on Paxful. Failing to sign below will stop the process of loading your account.\n\nBy signing below you are agreeing that this transaction is a peer-to-peer transaction with a 3rd party Bitcoin vendor, and this website is only facilitating the connection and plays no role in either side of the transaction. You acknowledge that this is an irreversible and indisputable transaction. Your electronic signature is the legal equivalent of your manual signature.';

  const paypalTos =
    'YMT is a lead generator in Blockchain Technology Services industry. YMT’s products include highly qualified leads containing contact information of individuals who have expressed their interest in the said industry through subscribing to an email or SMS list, in order to get updates related to available products and services.\n\nYMT is happy to serve you (the customer) with a list of high quality leads (the product) according to the purchased quantity of leads specified in this transaction. The leads are sold to you as the end consumer of the information and are not meant for resale.\n\nBy signing below, you are agreeing that this transaction is final and indisputable, you will not spam the contacts in the leads list for the purpose of anything other than the field of their expressed interest, as described above, and you will not resell the leads to other individuals or companies.';

  const stripeTos =
    'YMT is a lead generator in Blockchain Technology Services industry. YMT’s products include highly qualified leads containing contact information of individuals who have expressed their interest in the said industry through subscribing to an email or SMS list, in order to get updates related to available products and services.\n\nYMT is happy to serve you (the customer) with a list of high quality leads (the product) according to the purchased quantity of leads specified in this transaction. The leads are sold to you as the end consumer of the information and are not meant for resale.\n\nBy signing below, you are agreeing that this transaction is final and indisputable, you will not spam the contacts in the leads list for the purpose of anything other than the field of their expressed interest, as described above, and you will not resell the leads to other individuals or companies.';

  if (method.id == PaymentMethodEnum.PAYPAL) {
    tos = paypalTos;
  }

  if (method.id == PaymentMethodEnum.DEBIT_CARD || method.id == PaymentMethodEnum.INSTANT_APPLE_PAY) {
    tos = stripeTos;
  }

  if (agreedFee && agreed && disableSubmit) {
    setDisableSubmit(false);
  }

  return (
    <React.Fragment>
      <Loading loading={isLoading} />

      {!isLoading && method.id == PaymentMethodEnum.INSTANT_PAY && cbioAllowed != 'allowed' && (
        <Container className={classes.container} maxWidth='sm'>
          <Alert severity='error'>
            <AlertTitle>
              <Typography variant='h6' gutterBottom>
                Error
              </Typography>
              Instant eCheck is currently not available at this time, please check back later.
            </AlertTitle>
          </Alert>

          <div className={classes.buttons}>
            <Button
              variant='contained'
              onClick={() =>
                cashierDialog.isShow
                  ? setCashierDialogAction({ isShow: true, type: 'cashier/info', transactionType: type })
                  : getHistory().goBack()
              }
            >
              <Typography>Back</Typography>
            </Button>
          </div>
        </Container>
      )}

      {!isLoading && method.id == PaymentMethodEnum.INSTANT_PAY && cbioAllowed != 'allowed' ? (
        ''
      ) : (
        <>
          {!isLoading && (
            <Container className={classes.container} maxWidth='sm'>
              <Typography variant='h6' gutterBottom className={classes.titleBox}>
                <div className={classes.logoBox}>
                  <img src={`images/payment_methods/${method?.image}`} />
                </div>
                {method?.name} {type == TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'}
              </Typography>
              {method.id != PaymentMethodEnum.CASH_APP && (
                <Alert severity='warning'>
                  {method.id == PaymentMethodEnum.CASH_APP && (
                    <span>
                      Please double check <b>your cash app tag</b> before submitting your request.
                    </span>
                  )}
                  {(method.id == PaymentMethodEnum.BITCOIN || method.id == PaymentMethodEnum.INSTANT_BITCOIN) && (
                    <span>
                      Please review your <b>{type == TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'}</b> info before submitting your
                      request.
                    </span>
                  )}
                  {method.id == PaymentMethodEnum.APPLE_PAY && (
                    <span>
                      Please double check <b>your apple pay info</b> before submitting your request.
                    </span>
                  )}
                  {method.id == PaymentMethodEnum.PAYPAL && (
                    <span>
                      Please double check your <b>deposit information</b> before submitting your request.
                    </span>
                  )}
                  {method.id == PaymentMethodEnum.INSTANT_APPLE_PAY && (
                    <span>You MUST be starting this transaction on an Apple Pay enabled device, and have pop ups enabled.</span>
                  )}
                  {(method.id == PaymentMethodEnum.ZELLE || method.id == PaymentMethodEnum.DEBIT_CARD) && (
                    <span>
                      Please double check your <b>deposit information</b> before submitting your request.
                    </span>
                  )}
                  {method.id == PaymentMethodEnum.INSTANT_PAY && (
                    <span>Please confirm transaction details and verify the code that has been sent to your phone.</span>
                  )}
                </Alert>
              )}

              <div className={classes.buttons} style={{ marginBottom: '12px' }}>
                <Button
                  variant='contained'
                  onClick={() => {
                    // setSmsCodeNullAction();
                    cashierDialog.isShow
                      ? setCashierDialogAction({ isShow: true, type: 'cashier/info', transactionType: type })
                      : getHistory().goBack();
                  }}
                >
                  <Typography>Back</Typography>
                </Button>

                {type == TransactionTypeEnum.WITHDRAWAL && smsCodeCheck == null ? (
                  ''
                ) : (
                  <Button
                    variant='contained'
                    color='secondary'
                    disabled={disableSubmit}
                    className={classes.greenBtn}
                    onClick={() => {
                      setDisableSubmit(!disableSubmit);
                      insertTransactionAction({
                        type: type,
                        method: method.id,
                        bonus: bonus == null ? null : bonus.id,
                        playerNumber: user.username,
                        account:
                          type == TransactionTypeEnum.WITHDRAWAL && method.id == PaymentMethodEnum.INSTANT_PAY
                            ? user.email
                            : account == null
                            ? null
                            : account,
                        amount: amount,
                        name: name == null ? null : name,
                        fingerprint: fingerprint,
                        ip: ipAddress,
                        account_number: account_number,
                        routing_number: routing_number,
                      });
                    }}
                  >
                    <Typography>{type == TransactionTypeEnum.DEPOSIT ? 'Continue Deposit' : 'Request Withdrawal'}</Typography>
                  </Button>
                )}
              </div>

              <ConfirmTable rows={getRows(method)} bonus={bonus} />

              {/* TOS Section */}
              {type == TransactionTypeEnum.DEPOSIT &&
                method.id != PaymentMethodEnum.BITCOIN &&
                method.id != PaymentMethodEnum.INSTANT_BITCOIN && (
                  <>
                    {' '}
                    <TextField
                      multiline
                      rows={4}
                      defaultValue={tos}
                      style={{ marginTop: '1rem', width: '98%', minHeight: '30px' }}
                      variant='outlined'
                      label='Terms and conditions'
                      disabled
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          variant='filled'
                          label='Your Full Name'
                          color='primary'
                          onChange={e => setTransactionNameAction(e.target.value)}
                          value={name}
                          style={{
                            marginTop: '.5rem',
                            marginBottom: '.5rem',
                            width: '98%',
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type='date'
                          variant='filled'
                          label="Today's Date"
                          color='primary'
                          defaultValue={getDate()}
                          style={{
                            marginTop: '.5rem',
                            marginBottom: '.5rem',
                            width: '98%',
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <FormControlLabel
                      control={<Checkbox checked={agreed} onChange={handleAgree} />}
                      label='I have read and I agree with the terms and conditions of this transaction.'
                      style={{ color: 'white', marginTop: '.8rem' }}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={agreedFee} onChange={handleAgreeFee} />}
                      label='I agree to pay the posted fee above.'
                      style={{ color: 'white', marginTop: '0' }}
                    />
                  </>
                )}

              {/* Withdrawal SMS Section */}
              {type == TransactionTypeEnum.WITHDRAWAL && smsCodeCheck == null && (
                <div style={{ textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                  <Alert severity='info'>
                    <AlertTitle>
                      <Typography variant='h6' gutterBottom>
                        Confirm Code
                      </Typography>
                    </AlertTitle>
                    <Typography variant='subtitle1'>
                      We have sent a SMS confirmation code to your phone. <br />
                      Please confirm the verification code sent to your mobile device.
                    </Typography>

                    <TextField
                      variant='filled'
                      label='Verification Code'
                      color='primary'
                      onChange={e => setVerificationCode(e.target.value)}
                      value={verificationCode}
                      style={{
                        marginTop: '.5rem',
                        marginBottom: '.5rem',
                        width: '98%',
                        maxWidth: '200px',
                      }}
                    />

                    <br />
                    <Button
                      variant='outlined'
                      color='default'
                      onClick={() => {
                        sendSmsCodeAction(user.username);
                      }}
                      style={{
                        marginTop: '.5rem',
                        marginBottom: '.5rem',
                        marginLeft: '.5rem',
                        marginRight: '.5rem',
                      }}
                    >
                      Resend Code
                    </Button>

                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={() => {
                        checkSmsCodeAction({
                          username: user.username,
                          code: verificationCode,
                        });
                      }}
                      style={{
                        marginTop: '.5rem',
                        marginBottom: '.5rem',
                        marginLeft: '.5rem',
                        marginRight: '.5rem',
                      }}
                    >
                      Confirm Code
                    </Button>
                  </Alert>
                </div>
              )}
            </Container>
          )}
        </>
      )}
    </React.Fragment>
  );
};
