import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { API_PATH } from '../../../constants';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const GET_BANK_REFERENCE = `GET_BANK_REFERENCE${suffix}`;

export const getBankReferenceAction = createAction<any>(GET_BANK_REFERENCE);

const GET_BANK_REFERENCE_SUCCESS = `GET_BANK_REFERENCE_SUCCESS${suffix}`;
const getBankReferenceSuccessAction = createAction<any[]>(GET_BANK_REFERENCE_SUCCESS);

const GET_BANK_REFERENCE_ERROR = `GET_BANK_REFERENCE_ERROR${suffix}`;
const getBankReferenceErrorAction = createAction<string>(GET_BANK_REFERENCE_ERROR);

function* getBankReferenceWatcher(): SagaIterator {
  yield takeEvery(GET_BANK_REFERENCE, getBankReferenceWorker);
}
mergeSaga(getBankReferenceWatcher);

function* getBankReferenceWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getBankReferenceApi, action.payload);
    yield put(getBankReferenceSuccessAction(result));
  } catch (e: any) {
    yield put(getBankReferenceErrorAction(e));
  }
}

const getBankReferenceApi = (data: any): Request => {
  return vipApi(API_PATH.CASHIER.GET_BANK, 'post', {
    method: data.method,
    name: data.name,
  });
};

const reduceHandlers = {
  [GET_BANK_REFERENCE]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_BANK_REFERENCE_SUCCESS]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    bankReference: action.payload.data,
  }),
  [GET_BANK_REFERENCE_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
