import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { API_PATH } from '../../../constants';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const GET_INSTITUTIONS = `GET_INSTITUTIONS${suffix}`;

export const getInstitutionsAction = createAction(GET_INSTITUTIONS);

const GET_INSTITUTIONS_SUCCESS = `GET_INSTITUTIONS_SUCCESS${suffix}`;
const getInstitutionsSuccessAction = createAction<any[]>(GET_INSTITUTIONS_SUCCESS);

const GET_INSTITUTIONS_ERROR = `GET_INSTITUTIONS_ERROR${suffix}`;
const getInstitutionsErrorAction = createAction<string>(GET_INSTITUTIONS_ERROR);

function* getInstitutionsWatcher(): SagaIterator {
  yield takeEvery(GET_INSTITUTIONS, getInstitutionsWorker);
}
mergeSaga(getInstitutionsWatcher);

function* getInstitutionsWorker(): SagaIterator {
  try {
    const result: any = yield call(getInstitutionsApi);
    yield put(getInstitutionsSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data, 'error');
    yield put(getInstitutionsErrorAction(e));
  }
}

const getInstitutionsApi = (): Request => {
  return vipApi(API_PATH.CASHIER.INSTITUTIONS, 'get', {});
};

const reduceHandlers = {
  [GET_INSTITUTIONS]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
    institutions: [],
  }),
  [GET_INSTITUTIONS_SUCCESS]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    institutions: action.payload.data,
  }),
  [GET_INSTITUTIONS_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    institutions: [],
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
