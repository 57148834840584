import { connect } from 'react-redux';
import { TransactionMethodPage, ExternalProps, ExternalActionProps } from './transaction-method-page.component';
import { loggedInUserSelector } from './../../../api/user/user.selector';
import {
  cashierDialogShowSelector,
  freeFreeTimesSelector,
  isLoadingSliceSelector,
  paymentMethodsSelector,
  typeSelector,
} from '../../../api/cashier/cashier.selector';
import { getOnlineHoursAction } from '../../../api/cashier/actions/get-online-hours.action';
import { setTransactionMethodAction } from '../../../api/cashier/actions/set-transaction-method.action';
import { getPaymentMethodsAction } from '../../../api/cashier/actions/get-payment-methods.action';
import { setCashierDialogAction } from '../../../api/cashier/actions/set-cashier-dialog.action';
import { setTransactionTypeAction } from '../../../api/cashier/actions/set-transaction-type.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    user: loggedInUserSelector(state),
    paymentMethods: paymentMethodsSelector(state),
    isLoading: isLoadingSliceSelector(state),
    type: typeSelector(state),
    feeFreeTimes: freeFreeTimesSelector(state),
    cashierDialog: cashierDialogShowSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getOnlineHoursAction,
  setTransactionMethodAction,
  getPaymentMethodsAction,
  setCashierDialogAction,
  setTransactionTypeAction,
};

export const TransactionMethodPageContainer = connect(mapStateToProps, mapDispatchToProps)(TransactionMethodPage);
