import { createAction } from 'redux-actions';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
declare global {
  interface Window {
    LO: any;
  }
}

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const CLEAR_PAYMENT_METHODS = `CLEAR_PAYMENT_METHODS${suffix}`;

export const clearPaymentMethodsAction = createAction(CLEAR_PAYMENT_METHODS);

const reduceHandlers = {
  [CLEAR_PAYMENT_METHODS]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => {
    return { ...slice, isLoading: false, paymentMethods: [] };
  },
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
