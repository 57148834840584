import { connect } from 'react-redux';
import { loggedInUserSelector } from '../../../api/user/user.selector';
import {
  availableBonusesSelector,
  bonusSelector,
  cashierDialogShowSelector,
  isLoadingSliceSelector,
  methodSelector,
  typeSelector,
} from '../../../api/cashier/cashier.selector';
import { BonusSelectPage, ExternalProps, ExternalActionProps } from './bonus-select-page.component';
import { setTransactionBonusAction } from '../../../api/cashier/actions/set-transaction-bonus.action';
import { getBonusesAction } from '../../../api/cashier/actions/get-bonuses.action';
import { getBonusByCodeAction } from '../../../api/cashier/actions/get-bonus-by-code.action';
import { setCashierDialogAction } from '../../../api/cashier/actions/set-cashier-dialog.action';
import { isLoadingRollOverSliceSelector, rolloverSelector } from '../../../api/rollover/rollover.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    user: loggedInUserSelector(state) || isLoadingRollOverSliceSelector(state),
    isLoading: isLoadingSliceSelector(state),
    type: typeSelector(state),
    rollover: rolloverSelector(state),
    availableBonuses: availableBonusesSelector(state),
    method: methodSelector(state),
    bonus: bonusSelector(state),
    cashierDialog: cashierDialogShowSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  setTransactionBonusAction,
  getBonusesAction,
  getBonusByCodeAction,
  setCashierDialogAction,
};

export const BonusSelectPageContainer = connect(mapStateToProps, mapDispatchToProps)(BonusSelectPage);
