import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  Button,
  Card,
  Container,
  Grid,
  TextField,
  Typography,
  Chip,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Modal,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ArrowDownwardRounded from '@material-ui/icons/ArrowDownwardRounded';
import { makeStyles } from '@material-ui/core/styles';
import { getHistory } from '../../../utils/history.util';
import { useEffect } from 'react';
import { showToast } from '../../../utils/toast/show-errors';
import { toastr } from 'react-redux-toastr';
import { TransactionTypeEnum } from '../../../enum/transactions/TransactionTypeEnum';
import { PaymentMethodEnum } from '../../../enum/transactions/PaymentMethodEnum';
import { getAppSettings } from '../../../utils';
import { BonusAmountTypeEnum } from '../../../enum/bonus/bonus-type-amount-enum';

declare global {
  interface Window {
    LO: any;
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
    padding: 15,
    marginBottom: '3rem',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
  },
  card: {
    padding: 20,
    textAlign: 'center',
  },
  input: {
    paddingBottom: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 12,
  },
  selectField: {
    visibility: 'hidden',
  },
  greenBtn: {
    background: '#84b202',
    color: 'white',
    '&:hover': {
      background: '#678b02',
    },
  },
  mobileAlert: {
    '& .MuiAlert-message': {
      [theme.breakpoints.down('md')]: {
        width: '80%',
        overflowWrap: 'break-word',
      },
    },
  },
  alertLink: {
    color: 'white',
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

export interface ExternalProps {
  bonus: any;
  type: any;
  method: any;
  account: any;
  amount: any;
  user: any;
  accounts: string[];
  cashierDialog: any;
}

export interface ExternalActionProps {
  setTransactionAccountAction: (action: any) => void;
  setTransactionAmountAction: (amount: any) => void;
  setTransactionBonusAction: (bonus: any) => void;
  getDepositAccountsAction: (methodId: number) => void;
  setCashierDialogAction: (data: any) => void;
}

export const TransactionInfoPage = (props: ExternalProps & ExternalActionProps) => {
  const {
    bonus,
    type,
    method,
    account,
    amount,
    user,
    accounts,
    setTransactionAccountAction,
    setTransactionAmountAction,
    setTransactionBonusAction,
    getDepositAccountsAction,
    setCashierDialogAction,
    cashierDialog,
  } = props;

  const classes = useStyles();
  const validNameReg = RegExp(/[a-zA-Z&]+[\s]+([a-zA-Z&]+[\s]?)+/);
  const hasNumberReg = RegExp(/\d/);
  const btcAddressReg = RegExp(/^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/);

  const [openAccountSelect, setOpenAccountSelect] = React.useState<boolean>(false);

  const [windowOpened, setWindowOpened] = useState<Window | null>(null);
  const [acceptConditions, setAcceptConditions] = useState(false);
  const [instantPayContinueProcess, setInstantPayContinueProcess] = useState<boolean>(
    localStorage.getItem('instant_pay_accept_tos') ? true : false,
  );
  const [instantPayAcceptUpdateInformation, setInstantPayAcceptUpdateInformation] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState(false);
  const handleModalClose = () => {
    setOpenModal(false);
  };

  const claimedBonus = (bonus: any) => {
    return bonus != null;
  };

  const handlerAcepptConditionsRequiered = () => {
    toastr.warning('Notification', 'To continue, you must accept the terms and conditions.');
  };

  useEffect(() => {
    if (type == TransactionTypeEnum.DEPOSIT && method.id != PaymentMethodEnum.BITCOIN && method.id != PaymentMethodEnum.INSTANT_BITCOIN) {
      getDepositAccountsAction(method.id);
    }
  }, []);

  useEffect(() => {
    if (type == null) {
      cashierDialog.isShow ? setCashierDialogAction({ isShow: true, type: 'cashier' }) : getHistory().push('/cashier');
    }
  }, []);

  const getMinMax = (transactionType: any, bonus: any, method: any) => {
    if (bonus != null) {
      return {
        min: bonus.min_deposit,
        max: bonus.max_deposit,
      };
    }
    if (transactionType == TransactionTypeEnum.DEPOSIT) {
      return {
        min: method.deposit_min,
        max: method.deposit_max,
      };
    }
    return {
      min: method.withdrawal_min,
      max: method.withdrawal_max,
    };
  };

  const checkInfo = (transactionType: any, method: any, amount: any, account: any, min: any, max: any) => {
    try {
      min = parseFloat(min);
      amount = parseFloat(amount);
      max = parseFloat(max);

      if (transactionType == TransactionTypeEnum.DEPOSIT) {
        if (!account) {
          switch (method?.id) {
            case PaymentMethodEnum.ZELLE:
              showToast('Please enter your account name', 'error');
              return;
            default:
              break;
          }
        }

        if (account) {
          switch (method?.id) {
            case PaymentMethodEnum.ZELLE:
              if (!validNameReg.test(account) || hasNumberReg.test(account)) {
                setOpenModal(true);
                return;
              }
              break;
            default:
              break;
          }
        }
      }
      if (
        transactionType == TransactionTypeEnum.WITHDRAWAL &&
        (method?.id === PaymentMethodEnum.BITCOIN ||
          method?.id === PaymentMethodEnum.CASH_APP ||
          method?.id === PaymentMethodEnum.APPLE_PAY ||
          method?.id === PaymentMethodEnum.INSTANT_BITCOIN) &&
        !account
      ) {
        if (method?.id === PaymentMethodEnum.CASH_APP) {
          // 'Cash App'
          return showToast('Please enter your cash app tag.', 'error');
        } else if (method?.id === PaymentMethodEnum.APPLE_PAY) {
          // 'Apple Pay'
          return showToast('Please enter your apple pay phone or email.', 'error');
        } else if (method?.id === PaymentMethodEnum.BITCOIN || method?.id === PaymentMethodEnum.INSTANT_DEPOSIT) {
          // 'Bitcoin'
          return showToast('Please enter a Bitcoin address.', 'error');
        }
      } else if (!amount || amount == '' || amount == '0') {
        return showToast('Please enter an amount. Must be greater than $0.', 'error');
      } else if (amount < min || amount > max) {
        return showToast('Please enter an amount between $' + min + ' and $' + max + '.', 'error');
      } else if (
        transactionType == TransactionTypeEnum.WITHDRAWAL &&
        (getAppSettings().baseUrl == 'https://vipclub.lv/' || getAppSettings().baseUrl == '//vipclub.lv/') &&
        (method?.id === PaymentMethodEnum.BITCOIN || method?.id === PaymentMethodEnum.INSTANT_BITCOIN) &&
        !btcAddressReg.test(account)
      ) {
        return showToast('Please enter a valid BTC address.', 'error');
      } else {
        cashierDialog.isShow
          ? setCashierDialogAction({ isShow: true, type: 'cashier/confirm', transactionType: type })
          : getHistory().push('/cashier/confirm');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMethodAccountLabel = (method_id: number) => {
    switch (method_id) {
      case PaymentMethodEnum.BITCOIN:
      case PaymentMethodEnum.INSTANT_BITCOIN:
        return 'Bitcoin address';
        break;
      case PaymentMethodEnum.CASH_APP:
        return 'Cash Tag';
        break;
      case PaymentMethodEnum.APPLE_PAY:
        return 'Apple Pay Phone or Email';
        break;
      case PaymentMethodEnum.ZELLE:
        return '(e.g Dylan Smith)';
        break;
    }
  };

  const getMethodHelpText = (method: any, transactionType: any) => {
    switch (method.id) {
      case PaymentMethodEnum.BITCOIN:
      case PaymentMethodEnum.INSTANT_BITCOIN:
        return (
          <small>
            Please enter <b>your Bitcoin Address</b>.
          </small>
        );
        break;
      case PaymentMethodEnum.CASH_APP:
        return (
          <small>
            Please enter <b>your cash app tag</b> you will be {transactionType == TransactionTypeEnum.WITHDRAWAL ? 'receiving' : 'sending'}{' '}
            from.
          </small>
        );
        break;
      case PaymentMethodEnum.APPLE_PAY:
        return (
          <small>
            Please enter <b>Apple Pay Phone or Email</b> you will be sending from.
          </small>
        );
        break;
    }
  };

  const getMethodAccountPlaceholder = (method_id: number) => {
    switch (method_id) {
      case PaymentMethodEnum.BITCOIN:
      case PaymentMethodEnum.INSTANT_BITCOIN:
        return 'Bitcoin Address';
      case PaymentMethodEnum.CASH_APP:
        return '$CashTag';
      case PaymentMethodEnum.APPLE_PAY:
        return 'Apple Pay Phone or Email';
      case PaymentMethodEnum.ZELLE:
        return '(e.g Dylan Smith)';
    }
  };

  const selectCustomAmount = (amount: any) => {
    setTransactionAmountAction(amount);
  };

  const handleInstantPayCheck = () => {
    localStorage.setItem('instant_pay_accept_tos', 'success');
    setInstantPayContinueProcess(true);
    setInstantPayAcceptUpdateInformation(true);
  };

  const handleOpenWindow = () => {
    const new_window = window.open('https://app.checkbook.io/account/settings/accounts', 'check_box');
    if (new_window) {
      new_window.focus();
      setWindowOpened(new_window);
    }
  };

  return (
    <React.Fragment>
      {method == null && (cashierDialog.isShow ? setCashierDialogAction({ isShow: true, type: 'cashier' }) : getHistory().push('/cashier'))}

      {method && (
        <Container className={classes.container} maxWidth='sm'>
          <FormLabel component='legend'>
            <Typography variant='h6' classes={{ root: classes.title }}>
              Enter {type == TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'} Info
            </Typography>
          </FormLabel>
          <hr />
          {type == TransactionTypeEnum.DEPOSIT &&
            (method.id == PaymentMethodEnum.BITCOIN || method.id == PaymentMethodEnum.INSTANT_BITCOIN) && (
              <Alert severity='warning' style={{ marginBottom: '5px' }}>
                <Typography variant='body2'>
                  When sending Bitcoin, please makes sure you select the default/standard fee that your wallet selects. <br />
                  <b>Sending Bitcoin at a lower processing fee will delay your deposit.</b>
                </Typography>
              </Alert>
            )}

          {method.id == PaymentMethodEnum.INSTANT_PAY && instantPayContinueProcess == false && (
            <>
              <div>
                <Alert severity='error' style={{ marginBottom: '5px' }} className={classes.mobileAlert}>
                  <Typography variant='body2'>
                    Before submitting your withdrawal, please click the following link to ensure your card info is up to date:
                  </Typography>
                  <Typography className={classes.alertLink} variant='body1' onClick={handleOpenWindow}>
                    https://app.checkbook.io/account/settings/accounts
                  </Typography>
                </Alert>
                {windowOpened && (
                  <FormControlLabel
                    label={
                      <p style={{ width: '100%', fontSize: '0.9rem' }}>
                        I have updated my checkbook account details and these are correct.
                      </p>
                    }
                    control={
                      <Checkbox
                        checked={instantPayAcceptUpdateInformation}
                        onChange={event => {
                          setInstantPayAcceptUpdateInformation(event.target.checked);
                        }}
                      />
                    }
                  />
                )}
              </div>
              <div className={classes.buttons}>
                <Button
                  variant='outlined'
                  onClick={() => {
                    setTransactionBonusAction(null);
                    cashierDialog.isShow
                      ? setCashierDialogAction({ isShow: true, type: 'cashier/method', transactionType: type })
                      : getHistory().goBack();
                  }}
                >
                  <Typography>Back</Typography>
                </Button>
                {windowOpened && (
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={!instantPayAcceptUpdateInformation}
                    className={`${classes.greenBtn}`}
                    onClick={handleInstantPayCheck}
                  >
                    <Typography>Continue</Typography>
                  </Button>
                )}
              </div>
            </>
          )}
          {method.id == PaymentMethodEnum.INSTANT_PAY && instantPayContinueProcess == true && (
            <Alert severity='warning' style={{ marginBottom: '5px' }}>
              <Typography variant='body2'>
                This eCheck will be under your name{' '}
                <b>
                  <u>
                    {user.first_name} {user.last_name}
                  </u>
                </b>{' '}
                and be sent to{' '}
                <b>
                  <u>{user.email}</u>
                </b>
                . <br />
                The debit card you use with the Instant Pay check{' '}
                <b>
                  <u>MUST</u>
                </b>{' '}
                match the name above.
                <br />
                <b>If this information is incorrect, please update your profile..</b>
                <br />
                <Button
                  variant='contained'
                  onClick={() => {
                    getHistory().push('/profile');
                    cashierDialog.isShow && setCashierDialogAction({ isShow: false, type: '' });
                  }}
                  style={{ display: 'block', margin: '10px auto' }}
                >
                  Update Profile
                </Button>
              </Typography>
            </Alert>
          )}

          {(method.id != PaymentMethodEnum.INSTANT_PAY ||
            (method.id == PaymentMethodEnum.INSTANT_PAY && instantPayContinueProcess == true)) && (
            <>
              <Grid item xs={12}>
                <Card classes={{ root: classes.card }} style={{ paddingBottom: '0' }}>
                  {method.id != PaymentMethodEnum.DEBIT_CARD && method.id != PaymentMethodEnum.APPLE_PAY ? (
                    <Alert severity='error' style={{ marginBottom: '1rem' }}>
                      <Typography variant='body2'>
                        Any transactions with{' '}
                        <b>
                          <u>NOTES OR USERNAMES RELATED TO GAMBLING OR BETTING</u>
                        </b>{' '}
                        will automatically be returned, and you will be blocked from the platform.
                      </Typography>
                    </Alert>
                  ) : (
                    ''
                  )}
                  <>
                    {' '}
                    <div className='tour_deposit-customAmount'>
                      <Typography variant='subtitle1' gutterBottom>
                        Select an amount or enter a custom amount below.
                      </Typography>
                      {/* <Grid container> */}
                      <Grid container style={{ justifyContent: 'center' }}>
                        {(method.id == PaymentMethodEnum.APPLE_PAY ||
                          method.id == PaymentMethodEnum.INSTANT_APPLE_PAY ||
                          method.id == PaymentMethodEnum.INSTANT_DEPOSIT ||
                          method.id == PaymentMethodEnum.DEBIT_CARD) && (
                          <Grid item xs={3} sm={2}>
                            <Chip color='secondary' label='$50' onClick={() => selectCustomAmount(50)} clickable />
                          </Grid>
                        )}
                        <Grid item xs={3} sm={2}>
                          <Chip color='secondary' label='$100' onClick={() => selectCustomAmount(100)} clickable />
                        </Grid>
                        {(method.id == PaymentMethodEnum.APPLE_PAY ||
                          method.id == PaymentMethodEnum.INSTANT_APPLE_PAY ||
                          method.id == PaymentMethodEnum.INSTANT_DEPOSIT ||
                          method.id == PaymentMethodEnum.DEBIT_CARD) && (
                          <Grid item xs={3} sm={2}>
                            <Chip color='secondary' label='$200' onClick={() => selectCustomAmount(200)} clickable />
                          </Grid>
                        )}
                        <Grid item xs={3} sm={2}>
                          <Chip color='secondary' label='$250' onClick={() => selectCustomAmount(250)} clickable />
                        </Grid>
                        {method.id != PaymentMethodEnum.INSTANT_DEPOSIT && (
                          <Grid item xs={3} sm={2}>
                            <Chip color='secondary' label='$500' onClick={() => selectCustomAmount(500)} clickable />
                          </Grid>
                        )}
                      </Grid>
                      {/* <Grid item xs={4}>
                      <Chip color='secondary' label='$100' onClick={() => selectCustomAmount(100)} clickable />
                    </Grid>
                    <Grid item xs={4}>
                      <Chip color='secondary' label='$250' onClick={() => selectCustomAmount(250)} clickable />
                    </Grid>
                    <Grid item xs={4}>
                      <Chip color='secondary' label='$500' onClick={() => selectCustomAmount(500)} clickable />
                    </Grid> */}
                      {/* </Grid> */}
                    </div>
                    <div className='tour_deposit-amount' style={{ width: '350px', margin: '10px auto 0', maxWidth: '100%' }}>
                      <NumberFormat
                        label='Amount ($USD)'
                        value={amount == 0 ? null : amount}
                        customInput={TextField}
                        prefix={'$'}
                        thousandSeparator=','
                        onValueChange={(values: any) => setTransactionAmountAction(values.value == null ? 0 : values.value)}
                        classes={{ root: classes.input }}
                        color='primary'
                        fullWidth
                      />
                    </div>
                    <small style={{ marginBottom: '10px', display: 'block' }}>
                      {claimedBonus(bonus) && (
                        <p>
                          <b style={{ color: 'green' }}>
                            Claimed [{bonus.code}]{' '}
                            {bonus.description == 'NO rollover, risk free bet.' ? (
                              'Risk-Free Bet'
                            ) : (
                              <>
                                {bonus.type_amount_id == BonusAmountTypeEnum.FIXED
                                  ? `$${bonus.match_multiplier * 100} `
                                  : `${bonus.match_multiplier * 100}% `}
                                {bonus.type} Bonus w/ {bonus.rollover_multiplier * 1}x Rollover
                              </>
                            )}
                          </b>
                        </p>
                      )}
                      Fee: {method.fee !== null && method.fee}% | {type == TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'} Min: $
                      {Intl.NumberFormat().format(getMinMax(type, bonus, method).min)} |
                      {type == TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'} Max: $
                      {Intl.NumberFormat().format(getMinMax(type, bonus, method).max)}
                      <br />
                      {bonus && bonus.id == 93 && 'We recommend you wager your full deposit to maximize on this bonus.'}
                    </small>
                    <div className={classes.selectField} style={{ display: 'none' }}>
                      <Select
                        value={''}
                        open={openAccountSelect}
                        onClose={() => setOpenAccountSelect(false)}
                        onChange={e => {
                          setTransactionAccountAction(e.target.value);
                        }}
                        fullWidth
                        hidden
                      >
                        <MenuItem value={''} key={''}>
                          <em>Select</em>
                        </MenuItem>
                        {accounts.map((account: string) => {
                          return (
                            <MenuItem value={account} key={account}>
                              {account}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                    {(type == TransactionTypeEnum.WITHDRAWAL ||
                      method.id == PaymentMethodEnum.CASH_APP ||
                      method.id == PaymentMethodEnum.APPLE_PAY ||
                      method.id == PaymentMethodEnum.ZELLE) &&
                      method.id != PaymentMethodEnum.INSTANT_PAY && (
                        <div>
                          <TextField
                            label={getMethodAccountLabel(method.id)}
                            onChange={e => {
                              setTransactionAccountAction(e.target.value);
                            }}
                            value={account ?? ''}
                            placeholder={getMethodAccountPlaceholder(method.id)}
                            classes={{ root: classes.input }}
                            helperText={method.id == PaymentMethodEnum.ZELLE ? `Please enter the Zelle Account Holder's Exact Name` : ''}
                            color='primary'
                            style={{ width: '350px', margin: '0 auto', maxWidth: '100%', display: 'block' }}
                            InputProps={{
                              endAdornment:
                                type == TransactionTypeEnum.DEPOSIT &&
                                (method.id == PaymentMethodEnum.CASH_APP ||
                                  method.id == PaymentMethodEnum.APPLE_PAY ||
                                  method.id == PaymentMethodEnum.ZELLE) &&
                                accounts.length > 0 ? (
                                  <InputAdornment position='end'>
                                    <IconButton onClick={() => setOpenAccountSelect(true)} edge='end'>
                                      <ArrowDownwardRounded />
                                    </IconButton>
                                  </InputAdornment>
                                ) : (
                                  <></>
                                ),
                            }}
                            fullWidth
                          />
                          {getMethodHelpText(method, type)}
                          {type == TransactionTypeEnum.WITHDRAWAL && method.id == PaymentMethodEnum.INSTANT_BITCOIN && (
                            <div style={{ textAlign: 'left', margin: '10px' }}>
                              <Alert severity='warning'>
                                <p>
                                  <strong>Attention</strong>! <br />
                                  Bitcoin Withdrawals are instant. VIP Club can not be held responsible for wrong addresses entered, so
                                  please double check the address before hitting submit, if an incorrect address is entered, your money
                                  cannot be retrieved. Thank you!
                                  <br />
                                  <br />
                                  <small>VIP Club Team.</small>
                                  {amount && amount > 500 && (
                                    <>
                                      <Typography variant='body2' style={{ color: '#ff6961' }}>
                                        If your withdrawal is above $500, please note you will receive TWO SEPARATE transactions to the same
                                        address you provided, one for $500 and the remaining will be send a few minutes later.
                                      </Typography>
                                    </>
                                  )}
                                </p>
                              </Alert>
                              <FormControlLabel
                                control={
                                  <Checkbox checked={acceptConditions} onChange={event => setAcceptConditions(event.target.checked)} />
                                }
                                label='I accept terms and conditions.'
                                style={{ color: 'white', marginTop: '7px' }}
                              />
                            </div>
                          )}
                        </div>
                      )}
                  </>
                </Card>
              </Grid>

              <div className={classes.buttons}>
                <Button
                  variant='outlined'
                  onClick={() => {
                    setTransactionBonusAction(null);
                    cashierDialog.isShow
                      ? setCashierDialogAction({ isShow: true, type: 'cashier/method', transactionType: type })
                      : getHistory().goBack();
                  }}
                >
                  <Typography>Back</Typography>
                </Button>

                <Button
                  variant='contained'
                  color='primary'
                  className={`tour_deposit-continue ${classes.greenBtn}`}
                  onClick={() => {
                    if (
                      type == TransactionTypeEnum.WITHDRAWAL &&
                      method.id == PaymentMethodEnum.INSTANT_BITCOIN &&
                      acceptConditions == false
                    ) {
                      handlerAcepptConditionsRequiered();
                      return;
                    } else {
                      checkInfo(type, method, amount, account, getMinMax(type, bonus, method).min, getMinMax(type, bonus, method).max);
                    }
                  }}
                >
                  <Typography>Continue {type == 1 ? 'Deposit' : 'Withdrawal'}</Typography>
                </Button>
              </div>
            </>
          )}
        </Container>
      )}

      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        disableBackdropClick
      >
        <div
          style={{
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            position: 'absolute',
            width: '370px',
            maxWidth: '80%',
            backgroundColor: '#2d2d2ded',
            borderRadius: '5px',
            height: 'max-content',
            padding: '15px 15px',
          }}
        >
          <Alert severity='info' style={{ fontSize: '17px' }}>
            <div>Alert</div>
            Please, ONLY use first and last name of the sender&apos;s account (e.g Dylan Smith).
            <strong> Phone numbers or emails are not allowed</strong>
            <div>
              <Button
                variant='contained'
                color='primary'
                className={`${classes.greenBtn}`}
                onClick={handleModalClose}
                style={{ margin: '20px 0px 0px 0px', float: 'right' }}
              >
                <Typography>Agreed</Typography>
              </Button>
            </div>
          </Alert>
        </div>
      </Modal>
    </React.Fragment>
  );
};
