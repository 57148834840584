import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { API_PATH } from '../../../constants';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const GET_PLAYER_BANKS = `GET_PLAYER_BANKS${suffix}`;

export const getPlayerBanksAction = createAction(GET_PLAYER_BANKS);

const GET_PLAYER_BANKS_SUCCESS = `GET_PLAYER_BANKS_SUCCESS${suffix}`;
const getPlayerBanksSuccessAction = createAction<any[]>(GET_PLAYER_BANKS_SUCCESS);

const GET_PLAYER_BANKS_ERROR = `GET_PLAYER_BANKS_ERROR${suffix}`;
const getPlayerBanksErrorAction = createAction<string>(GET_PLAYER_BANKS_ERROR);

function* getPlayerBanksWatcher(): SagaIterator {
  yield takeEvery(GET_PLAYER_BANKS, getPlayerBanksWorker);
}
mergeSaga(getPlayerBanksWatcher);

function* getPlayerBanksWorker(): SagaIterator {
  try {
    const result: any = yield call(getPlayerBanksApi);
    yield put(getPlayerBanksSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(getPlayerBanksErrorAction(e));
  }
}

const getPlayerBanksApi = (): Request => {
  return vipApi(API_PATH.CASHIER.GET_PLAYER_BANK, 'get', {});
};

const reduceHandlers = {
  [GET_PLAYER_BANKS]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
    playerBanks: [],
  }),
  [GET_PLAYER_BANKS_SUCCESS]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    playerBanks: action.payload.data.banks,
  }),
  [GET_PLAYER_BANKS_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    playerBanks: [],
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
