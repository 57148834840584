import { connect } from 'react-redux';
import {
  isLoggedInSelector,
  loggedInUserSelector,
  isLoadingSelector as isLoadingUserSelector,
  userBalanceSelector,
  tourStepsSelector,
  runTourSelector,
  verifyIdentityOpenSelector,
  shareableUrlSelector,
  windowOpenedSelector,
} from '../../api/user/user.selector';
import { AppRoot, AppRootProps, ExternalActionProps } from './app-root.component';
import { getUserBalanceAction } from './../../api/user/actions/get-balance.action';
import { tvOnOffAction } from './../../api/game-selection/actions/tv-on-off.action';
import { setTvUrlAction } from './../../api/game-selection/actions/set-tv-url.action';
import {
  rightOpenSelector,
  tvChannelsListSelector,
  tvOnOffResponsesSelector,
  tvUrlSelector,
} from './../../api/game-selection/game-selection.selector';
import { pathSelector } from '../nav/router.selectors';
import { getTourStepsAction } from '../../api/user/actions/tours/get-tour-steps.action';
import { promotionsSelector } from '../../api/promotion/promotion.selector';
import { getPromotionAction } from '../../api/promotion/actions/get-promotion.action';
import { cashierDialogShowSelector, isLoadingSliceSelector, typeSelector } from '../../api/cashier/cashier.selector';
import { ptpiGetCasinoBonusesAction } from '../../api/tpi/actions/casino-bonuses.action';
import { casinoBonusesSelector, casinoBonusSelector } from '../../api/tpi/tpi.selector';
import { ptpiGetCasinoBonusAction } from '../../api/tpi/actions/casino-bonus.action';
import { setCashierDialogAction } from '../../api/cashier/actions/set-cashier-dialog.action';
import { changeUserBalanceAction } from '../../api/user/actions/change-balance.action';
import { getUserInfoAction } from '../../api/user/actions/get-user-info.action';
import { isLoadingRollOverSliceSelector, rolloverSelector } from '../../api/rollover/rollover.selector';
import { getPlayerRolloverAction } from '../../api/rollover/actions/get-player-rollover.action';
import { SocketMessageByDateSelector, SocketMessageSelector } from '../../api/socket/socket-selector';
import { SetSocketMessagesPlayerAction } from '../../api/socket/actions/get-so-message-player.action';
import { MarkNotifiedAsReadPlayerAction } from '../../api/socket/actions/mark-notified-so-message-player.action';
import { SetSocketMessagesPlayerByDateAction } from '../../api/socket/actions/set-so-message-player-by-date.action';
import { MarkNotifiedAsReadPlayerByDateAction } from '../../api/socket/actions/mark-notified-so-message-player-by-date.action';
import { getTvChannelsAction } from '../../api/game-selection/actions/get-tv-channels.action';
import { SetRunTourAction } from '../../api/user/actions/tours/set-run-tour.action';
import { MarkTourAsDisplayedAction } from '../../api/user/actions/tours/mark-tour-as-displayed.action';
import { toggleVerifyIdentityDialogAction } from '../../api/user/actions/toggle-verify-identity-dialog.action';
import { SetRights } from '../../api/user/actions/set-rights.action';
import { closeCognitoFlowSessionAction } from '../../api/user/actions/cognito/close-cognito-flow-session.action';
import { createCognitoRetryAction } from '../../api/user/actions/cognito/cognito-retry-flow-session.action';

function mapStateToProps(state: any): AppRootProps {
  return {
    isLoggedIn: isLoggedInSelector(state),
    user: loggedInUserSelector(state),
    userBalance: userBalanceSelector(state),
    tvOnOffResponses: tvOnOffResponsesSelector(state),
    tvUrl: tvUrlSelector(state),
    path: pathSelector(state),
    tourSteps: tourStepsSelector(state),
    rightOpen: rightOpenSelector(state),
    promotions: promotionsSelector(state),
    rollover: rolloverSelector(state),
    casinoBonuses: casinoBonusesSelector(state),
    casinoBonus: casinoBonusSelector(state),
    cashierDialog: cashierDialogShowSelector(state),
    isLoading: isLoadingSliceSelector(state) || isLoadingRollOverSliceSelector(state) || isLoadingUserSelector(state),
    socketMessages: SocketMessageSelector(state),
    transactionType: typeSelector(state),
    socketMessagesByDate: SocketMessageByDateSelector(state),
    tvChannelsList: tvChannelsListSelector(state),
    runTour: runTourSelector(state),
    identityDialogOpen: verifyIdentityOpenSelector(state),
    shareableUrl: shareableUrlSelector(state),
    window_opened: windowOpenedSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getUserBalanceAction,
  tvOnOffAction,
  setTvUrlAction,
  getUserInfoAction,
  getTourStepsAction,
  getPromotionAction,
  getPlayerRolloverAction,
  ptpiGetCasinoBonusesAction,
  ptpiGetCasinoBonusAction,
  setCashierDialogAction,
  changeUserBalanceAction,
  SetSocketMessagesPlayerAction,
  MarkNotifiedAsReadPlayerAction,
  SetSocketMessagesPlayerByDateAction,
  MarkNotifiedAsReadPlayerByDateAction,
  getTvChannelsAction,
  SetRunTourAction,
  MarkTourAsDisplayedAction,
  toggleVerifyIdentityDialogAction,
  createCognitoRetryAction,
  SetRights,
  closeCognitoFlowSessionAction,
};

export const AppRootContainer = connect(mapStateToProps, mapDispatchToProps)(AppRoot);
