import { connect } from 'react-redux';
import { addPlayerBankAction } from '../../../api/cashier/actions/add-bank.action';
import { completeChallengeAction } from '../../../api/cashier/actions/answer-cb-challenge.action';
import { getInstitutionsAction } from '../../../api/cashier/actions/get-bank-institutions.action';
import { getPlayerBanksAction } from '../../../api/cashier/actions/get-player-banks.action';
import { loginToBankAction } from '../../../api/cashier/actions/login-to-bank.action';
import { removePlayerBankAction } from '../../../api/cashier/actions/remove-bank.action';
import { setDefaultPlayerBankAction } from '../../../api/cashier/actions/set-default-bank.action';
import {
  cashierDialogShowSelector,
  institutionsSelector,
  isLoadingSliceSelector,
  mfaChallengeSelector,
  playerBanksSelector,
  selectableBanksSelector,
  typeSelector,
} from '../../../api/cashier/cashier.selector';
import { PlayerBankSelectPage, ExternalActionProps, ExternalProps } from './player-bank-select-page.component';
import { setCashierDialogAction } from '../../../api/cashier/actions/set-cashier-dialog.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    loading: isLoadingSliceSelector(state),
    playerBanks: playerBanksSelector(state),
    institutions: institutionsSelector(state),
    selectableBanks: selectableBanksSelector(state),
    mfaChallenge: mfaChallengeSelector(state),
    cashierDialog: cashierDialogShowSelector(state),
    type: typeSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getPlayerBanksAction,
  getInstitutionsAction,
  loginToBankAction,
  completeChallengeAction,
  addPlayerBankAction,
  removePlayerBankAction,
  setDefaultPlayerBankAction,
  setCashierDialogAction,
};

export const PlayerBankSelectPageContainer = connect(mapStateToProps, mapDispatchToProps)(PlayerBankSelectPage);
