import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import QRCode from 'qrcode.react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Card, TextField, CardContent, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  details: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  textarea: {
    resize: 'both',
    fontFamily: 'sans-serif !important',
    fontSize: '20px !important',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  spacer: {
    width: 20,
  },
  infoText: {
    textAlign: 'left',
  },
});

export interface ExternalProps {
  transactionRequest: any;
}

export const BTCDepositCard = (props: ExternalProps) => {
  const { transactionRequest } = props;
  const [copySuccess, setCopySuccess] = useState(false);

  const classes = useStyles();

  return (
    <Card>
      <div className={classes.details}>
        <CardContent>
          {transactionRequest?.address && (
            <QRCode id='123456' value={transactionRequest?.address} size={290} level={'H'} includeMargin={true} />
          )}
          <Typography component='h6' variant='h6'>
            Please send ${transactionRequest?.totalToSend} ({transactionRequest?.totalToSendBtc} BTC)
          </Typography>
          <Typography variant='subtitle1'>to the BTC Address (1 BTC = ${transactionRequest?.exchangeRate}):</Typography>
          <TextField
            id='bitcoinAddress'
            multiline
            variant='outlined'
            inputProps={{ className: classes.textarea }}
            value={transactionRequest?.address}
            fullWidth
          />
          <br />
          <br />
          <div className={classes.buttons} style={{ display: 'flex', alignItems: 'center' }}>
            {/* <Button
              variant="contained"
              href={`bitcoin:${transactionRequest?.address}?amount=${transactionRequest?.totalToSendBtc}`}
              target="_blank"
            >
              <Typography>Pay with Wallet</Typography>
            </Button> */}
            <div className={classes.spacer} />
            <Button
              variant='contained'
              onClick={() => {
                navigator.clipboard.writeText(transactionRequest?.address);
                setCopySuccess(true);
              }}
            >
              <Typography style={{ marginLeft: '10px' }}>Copy Address</Typography>
            </Button>
            {copySuccess && <Typography variant='subtitle2'>Copied!</Typography>}
          </div>
          <br />
          <br />

          <Alert severity='error' className={classes.infoText}>
            <AlertTitle>Warning</AlertTitle>
            <Typography variant='body2'>
              <strong>This address can only be used once.</strong> Every deposit must go through cashier.{' '}
              <strong>Do not re-send to an old address.</strong> Failure to follow rules, will lead to loss of bitcoin method.
            </Typography>
          </Alert>
        </CardContent>
      </div>
    </Card>
  );
};
