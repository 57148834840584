import { createAction } from 'redux-actions';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const RESET_SET_TRANSACTION = `RESET_SET_TRANSACTION${suffix}`;

export const resetTransactionAction = createAction(RESET_SET_TRANSACTION);

const reduceHandlers = {
  [RESET_SET_TRANSACTION]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => {
    return {
      ...slice,
      isLoading: false,
      bonus: null,
      name: null,
      account: null,
      amount: null,
      method: null,
      type: null,
      bankReference: null,
    };
  },
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
