import { createAction } from 'redux-actions';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
declare global {
  interface Window {
    LO: any;
  }
}

const logStartTransactionTypeEventInLuckyOrange = (type: any) => {
  if (window.LO != null) {
    if (window.LO.events) {
      if (type == 1) {
        window.LO.events.track('Clicked Deposit Button', {});
      }
      if (type == 2) {
        window.LO.events.track('Clicked Withdrawal Button', {});
      }
      console.log('[LO Event]: Clicked Tx Type Button');
    }
  }
};

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const SET_TRANSACTION_TYPE = `SET_TRANSACTION_TYPE${suffix}`;

export const setTransactionTypeAction = createAction<number>(SET_TRANSACTION_TYPE);

const reduceHandlers = {
  [SET_TRANSACTION_TYPE]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => {
    logStartTransactionTypeEventInLuckyOrange(action.payload);
    return { ...slice, isLoading: false, type: action.payload };
  },
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
