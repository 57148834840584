import { createAction } from 'redux-actions';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const SET_CLEAN_TRANSACTION = `SET_CLEAN_TRANSACTION${suffix}`;

export const setTransactionCleanAction = createAction(SET_CLEAN_TRANSACTION);

const reduceHandlers = {
  [SET_CLEAN_TRANSACTION]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    routing_number: null,
    account_number: null,
    transactionRequest: null,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
