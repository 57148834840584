export enum PaymentMethodEnum {
  CASH = 1,
  BITCOIN = 2,
  CASH_APP = 3,
  APPLE_PAY = 4,
  ZELLE = 5,
  PAYPAL = 6,
  DEBIT_CARD = 7,
  INSTANT_APPLE_PAY = 8,
  INSTANT_PAY = 9,
  INSTANT_DEPOSIT = 10,
  INSTANT_BITCOIN = 11,
  CASH_APP_STRIPE = 12,
}
