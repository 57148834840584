import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { API_PATH } from '../../../constants';
declare global {
  interface Window {
    LO: any;
  }
}

const logEventInLuckyOrange = (status: any) => {
  if (window.LO != null) {
    if (window.LO.events) {
      window.LO.events.track('Verified WD SMS Code', { status: status });
      console.log('[LO Event]: Verified WD SMS Code');
    }
  }
};
const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const CHECK_SMS_CODE = `CHECK_SMS_CODE${suffix}`;
const CLEAR_CHECK_SMS_CODE = `CLEAR_CHECK_SMS_CODE${suffix}`;

export const checkSmsCodeAction = createAction<any>(CHECK_SMS_CODE);
export const clearCheckSmsCodeAction = createAction(CLEAR_CHECK_SMS_CODE);

const CHECK_SMS_CODE_SUCCESS = `CHECK_SMS_CODE_SUCCESS${suffix}`;
const checkSmsCodeSuccessAction = createAction<any[]>(CHECK_SMS_CODE_SUCCESS);

const CHECK_SMS_CODE_ERROR = `CHECK_SMS_CODE_ERROR${suffix}`;
const checkSmsCodeErrorAction = createAction<string>(CHECK_SMS_CODE_ERROR);

function* checkSmsCodeWatcher(): SagaIterator {
  yield takeEvery(CHECK_SMS_CODE, checkSmsCodeWorker);
}
mergeSaga(checkSmsCodeWatcher);

function* checkSmsCodeWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(checkSmsCodeApi, action.payload);
    yield put(checkSmsCodeSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data, 'error');
    yield put(checkSmsCodeErrorAction(e));
  }
}

const checkSmsCodeApi = (data: any): Request => {
  return vipApi(API_PATH.CASHIER.CHECK_CODE, 'post', {
    username: data.username,
    code: data.code,
  });
};

const reduceHandlers = {
  [CHECK_SMS_CODE]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
    smsCodeCheck: null,
  }),
  [CHECK_SMS_CODE_SUCCESS]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => {
    logEventInLuckyOrange(true);
    return { ...slice, isLoading: false, smsCodeCheck: true };
  },
  [CHECK_SMS_CODE_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => {
    logEventInLuckyOrange(false);
    return { ...slice, isLoading: false, smsCodeCheck: null };
  },
  [CLEAR_CHECK_SMS_CODE]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => {
    return { ...slice, smsCodeCheck: null };
  },
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
