import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { API_PATH } from '../../../constants';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const GET_FEE_FREE_TIMES = `GET_FEE_FREE_TIMES${suffix}`;

export const getFeeFreeTimesAction = createAction(GET_FEE_FREE_TIMES);

const GET_FEE_FREE_TIMES_SUCCESS = `GET_FEE_FREE_TIMES_SUCCESS${suffix}`;
const getFeeFreeTimesSuccessAction = createAction<any[]>(GET_FEE_FREE_TIMES_SUCCESS);

const GET_FEE_FREE_TIMES_ERROR = `GET_FEE_FREE_TIMES_ERROR${suffix}`;
const getFeeFreeTimesErrorAction = createAction<string>(GET_FEE_FREE_TIMES_ERROR);

function* getFeeFreeTimesWatcher(): SagaIterator {
  yield takeEvery(GET_FEE_FREE_TIMES, getFeeFreeTimesWorker);
}
mergeSaga(getFeeFreeTimesWatcher);

function* getFeeFreeTimesWorker(): SagaIterator {
  try {
    const result: any = yield call(getFeeFreeTimesApi);
    yield put(getFeeFreeTimesSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(getFeeFreeTimesErrorAction(e));
  }
}

const getFeeFreeTimesApi = (): Request => {
  return vipApi(API_PATH.CASHIER.GET_FREE_TIMES, 'get', {});
};

const reduceHandlers = {
  [GET_FEE_FREE_TIMES]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_FEE_FREE_TIMES_SUCCESS]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    feeFreeTime: action.payload.data,
  }),
  [GET_FEE_FREE_TIMES_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
