import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Card, CardContent, Typography } from '@material-ui/core';
import { PaypalBtn } from './paypalBtn';
import { getAppSettings } from '../../../../utils/app-settings.util';
import { PaymentMethodEnum } from '../../../../enum/transactions/PaymentMethodEnum';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  content: {
    flex: '2 0 auto',
  },
  cover: {
    width: 200,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  spacer: {
    width: 20,
  },
  infoText: {
    textAlign: 'left',
  },
});

export interface ExternalProps {
  transactionRequest: any;
  bankReference: any;
}

export interface ExternalActionProps {
  getBankReferenceAction: (x: any) => void;
  setCashierDialogAction: (x: any) => void;
}

export const ManualPaymentCard = (props: ExternalProps & ExternalActionProps) => {
  const { transactionRequest, bankReference, getBankReferenceAction, setCashierDialogAction } = props;

  const [copySuccess, setCopySuccess] = useState(false);

  const classes = useStyles();

  let paymentUrl = '';

  if (getAppSettings().baseUrl == 'https://vipclub.lv/' || getAppSettings().baseUrl == '//vipclub.lv/') {
    paymentUrl = 'https://cisso.services/process-transaction.php?transaction=' + btoa(JSON.stringify({ url: transactionRequest.url }));
  } else {
    paymentUrl = 'https://cisso.services/test-process-transaction.php?transaction=' + btoa(JSON.stringify({ url: transactionRequest.url }));
  }

  useEffect(() => {
    if (bankReference == null) {
      getBankReferenceAction({ method: transactionRequest?.method, name: transactionRequest?.bank });
    }

    if (transactionRequest?.method == '7' || transactionRequest?.method == '8') {
      window.open(paymentUrl, 'mywindow', 'menubar=1,resizable=1,width=450,height=600');
    }
    if (transactionRequest?.method == '10') {
      let chexUrl = `https://portal-sandbox.seamlesschex.com/#/checkout/c656d190-968d-11ec-b2e0-193ff184b79a/0ffd7a00-6a9a-11ec-9e9b-95e15013e42d/0/1`;
      if (
        getAppSettings().baseUrl == 'https://vipclub.lv/' ||
        getAppSettings().baseUrl == '//vipclub.lv/'
        // getAppSettings().baseUrl == 'https://staging-wager.vipclub.lv/'
      ) {
        chexUrl =
          'https://portal.seamlesschex.com/#/checkout/522366a0-968d-11ec-835f-6bec78d1f56e/0ffd7a00-6a9a-11ec-9e9b-95e15013e42d/0/1';
      }
      chexUrl += `/${transactionRequest.totalToSend}/${transactionRequest.player_number}`;
      // console.log(chexUrl);
      window.open(chexUrl, 'Bank Payment', 'menubar=1,resizable=1,width=450,height=800');
    }
  }, []);

  const handleCloseCashier = (windows: any) => {
    let time = 500;
    const interval = setInterval(function () {
      if (time == 30000) {
        clearInterval(interval);
        setCashierDialogAction({ isShow: false, type: '' });
      }
      time += 500;
      if (windows.closed !== false) {
        clearInterval(interval);
        setCashierDialogAction({ isShow: false, type: '' });
      }
    }, 500);
  };

  return (
    <Card>
      <div className={classes.details}>
        <CardContent>
          {transactionRequest?.method == '6' && (
            <>
              <Typography component='h6' variant='h6'>
                Click the paypal button below to begin and complete payment.
              </Typography>
              <br />
              <PaypalBtn transactionRequest={transactionRequest} />
            </>
          )}

          {(transactionRequest?.method == PaymentMethodEnum.DEBIT_CARD ||
            transactionRequest?.method == PaymentMethodEnum.INSTANT_APPLE_PAY ||
            transactionRequest?.method == PaymentMethodEnum.INSTANT_DEPOSIT ||
            transactionRequest?.method == PaymentMethodEnum.CASH_APP_STRIPE) && (
            <>
              <Typography component='h6' variant='h6'>
                Please complete payment in the new window.
              </Typography>
              <Typography>Note: You must have pop ups enabled.</Typography>
              <br />
              {(transactionRequest?.method == PaymentMethodEnum.DEBIT_CARD ||
                transactionRequest?.method == PaymentMethodEnum.INSTANT_APPLE_PAY ||
                transactionRequest?.method == PaymentMethodEnum.CASH_APP_STRIPE) && (
                <>
                  <Button
                    variant='contained'
                    onClick={() => {
                      const windows = window.open(paymentUrl, 'mywindow', 'menubar=1,resizable=1,width=450,height=600');
                      handleCloseCashier(windows);
                    }}
                  >
                    Open popup
                  </Button>
                  <br />
                </>
              )}
              {transactionRequest?.method == PaymentMethodEnum.INSTANT_DEPOSIT && (
                <>
                  <Typography>
                    If you have any problem opening the pop up or processing the payment, please contact customer service.
                  </Typography>
                  <br />
                </>
              )}
            </>
          )}

          {transactionRequest?.method != PaymentMethodEnum.PAYPAL &&
            transactionRequest?.method != PaymentMethodEnum.DEBIT_CARD &&
            transactionRequest?.method != PaymentMethodEnum.INSTANT_APPLE_PAY &&
            transactionRequest?.method != PaymentMethodEnum.INSTANT_DEPOSIT &&
            transactionRequest?.method != PaymentMethodEnum.CASH_APP_STRIPE && (
              <>
                <Typography component='h6' variant='h6'>
                  Please send ${transactionRequest?.totalToSend} to {bankReference}
                </Typography>
                <br />
                <div className={classes.buttons}>
                  <div className={classes.spacer} />
                  <Button
                    variant='contained'
                    onClick={() => {
                      navigator.clipboard.writeText(bankReference);
                      setCopySuccess(true);
                      setTimeout(() => setCopySuccess(false), 2000);
                    }}
                  >
                    {copySuccess ? <Typography variant='subtitle2'>Copied!</Typography> : <Typography>Copy Account to Send</Typography>}
                  </Button>
                </div>
                <br />
                <Alert severity='info' className={classes.infoText}>
                  <AlertTitle>Need Help?</AlertTitle>
                  To complete your payment, please send the funds to the account listed above. <br />
                </Alert>
              </>
            )}
        </CardContent>
      </div>
    </Card>
  );
};
