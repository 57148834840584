import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    logoBox: {
      width: '80px',
      height: '80px',
      position: 'absolute',
      background: '#000000b0',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      justifyContent: 'center',
      '& img': {
        maxWidth: '100%',
      },
    },
  }),
);

interface LoadingProps {
  loading: boolean;
}

export const Loading = ({ loading }: LoadingProps) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <div className={classes.logoBox}>
        <img alt='logo' src={`${process.env.PUBLIC_URL}/logo.png`} />
      </div>
      <CircularProgress color='inherit' size={80} thickness={2.3} />
    </Backdrop>
  );
};
