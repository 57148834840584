import React from 'react';
import { PLAY_LINE } from '../../../enum';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { BonusGameWinnerEnum } from '../../../enum/bonus/bonus-game-winner-enum';

export const ValidatePickBonus = (BonusGame: any, PlayLine: any): boolean | null => {
  if (!BonusGame) return null;
  switch (PlayLine) {
    case PLAY_LINE.VISITORSPREAD:
      if ((BonusGame.use_v_spread && BonusGame.winner == BonusGameWinnerEnum.VISITOR) || (BonusGame.use_v_spread && !BonusGame.winner))
        return true;
      else return false;

    case PLAY_LINE.VISITORMONEYLINE:
      if (
        (BonusGame.use_v_moneyline && BonusGame.winner == BonusGameWinnerEnum.VISITOR) ||
        (BonusGame.use_v_moneyline && !BonusGame.winner)
      )
        return true;
      else return false;

    case PLAY_LINE.VISITORTOTAL:
      if ((BonusGame.use_v_total && BonusGame.winner == BonusGameWinnerEnum.VISITOR) || (BonusGame.use_v_total && !BonusGame.winner))
        return true;
      else return false;

    case PLAY_LINE.HOMESPREAD:
      if ((BonusGame.use_h_spread && BonusGame.winner == BonusGameWinnerEnum.HOME) || (BonusGame.use_h_spread && !BonusGame.winner))
        return true;
      else return false;
    case PLAY_LINE.HOMEMONEYLINE:
      if ((BonusGame.use_h_moneyline && BonusGame.winner == BonusGameWinnerEnum.HOME) || (BonusGame.use_h_moneyline && !BonusGame.winner))
        return true;
      else return false;
    case PLAY_LINE.HOMETOTAL:
      if ((BonusGame.use_h_total && BonusGame.winner == BonusGameWinnerEnum.HOME) || (BonusGame.use_h_total && !BonusGame.winner))
        return true;
      else return false;

    default:
      return null;
  }
};
export const PickBonusGame = (props: any) => {
  const { BonusGame, PlayLine } = props;

  const SuccessIconOdd = () => {
    return <CheckCircleIcon style={{ color: '#4caf50' }}> </CheckCircleIcon>;
  };
  const ErrorIconOdd = () => {
    return <CancelIcon style={{ color: '#ff6961' }}> </CancelIcon>;
  };

  if (BonusGame) {
    switch (ValidatePickBonus(BonusGame, PlayLine)) {
      case true:
        return <SuccessIconOdd />;
      case false:
        return <ErrorIconOdd />;
      default:
        return <></>;
    }
  }
  return <></>;
};
