import { createAction } from 'redux-actions';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
declare global {
  interface Window {
    LO: any;
  }
}

const logTransactionMethodEventInLuckyOrange = (method: any) => {
  if (window.LO != null) {
    if (window.LO.events) {
      window.LO.events.track('Selected Transaction Method', method);
      console.log('[LO Event]: Selected Transaction Method');
    }
  }
};

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const SET_TRANSACTION_METHOD = `SET_TRANSACTION_METHOD${suffix}`;

export const setTransactionMethodAction = createAction<number>(SET_TRANSACTION_METHOD);

const reduceHandlers = {
  [SET_TRANSACTION_METHOD]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => {
    logTransactionMethodEventInLuckyOrange(action.payload);
    return { ...slice, isLoading: false, method: action.payload };
  },
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
