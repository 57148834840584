import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiAppBar } from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Typography, Button, IconButton, Theme, Link, Divider, Hidden, Dialog, FormControl, Select, MenuItem } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserIconPopover } from '../user-icon-popover/user-icon-popover.component';
import { getHistory } from '../../utils/history.util';
//import CancelIcon from '@material-ui/icons/Cancel';
//import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
//import { FormControl } from '@material-ui/core';
//import { Player } from '../right-nav/player';
//import Draggable from 'react-draggable';
import AdDialog from './components/ad-dialog.componenent';
import Tour from './components/tour.component';
import { DialogBonusCard } from './components/dialog-bonus-card.component';
import { CasinoBonusesDto } from '../../models/CasinoLobbyV2/CasinoBonusesDto';
import { CashierDialog } from './components/cashier-components/cashier-dialog.component';
import { GetAmountWithDecimals, GetAmountWithOutDecimals } from '../functions/common';
import { Player } from '../right-nav/player';
import Draggable from 'react-draggable';
import CancelIcon from '@material-ui/icons/Cancel';
import { ITvChannels } from '../../models/tv-channels';
import IdentityDialog from './components/identity-dialog.component';
import { IIdentityDialog } from '../../models/users';
declare global {
  interface Window {
    LO: any;
  }
}

// const openLOLiveChat = () => {
//   if (window.LO != null) {
//     if (window.LO.messenger != undefined && window.LO.messenger != null) {
//       window.LO.messenger.open();
//       console.log('opened LO Chat');
//     }
//   }
// };

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/top-bg.png` + ')',
    color: 'white',
    '& hr': {
      margin: theme.spacing(0, 2),
    },
  },
  toolbarRightItems: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: 64,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '0px auto',
    },
  },
  shrinkLogo: {
    maxHeight: '50px',
    maxWidth: '90px',
    color: theme.palette.secondary.main,
  },
  fixedVideoPlayer: {
    position: 'fixed',
    width: '200px',
    right: '50px',
    bottom: '20px',
    zIndex: 1240,
    [theme.breakpoints.up('sm')]: {
      width: '655px',
      maxWidth: '100%',
    },
  },
  channelSelect: {
    backgroundColor: '#000',
    position: 'relative',
    zIndex: 1240,
    '& .MuiSelect-select.MuiSelect-selectMenu': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '20px',
    },
  },
  tvClose: {
    position: 'relative',
    width: '20px',
    float: 'right',
    top: '10px',
    zIndex: 10000,
    right: '10px',
  },
  dialogBackgroundColor: {
    backgroundColor: '#eee6e680',
  },
  frame: {
    width: '655px',
    maxWidth: '100%',
    height: '375px',
    backgroundColor: '#dddddd',
    marginTop: '20px',
    borderRadius: '5px',
  },
}));

// const routes = [
//   RoutesDictionary[RoutesEnum.Cashier],
//   RoutesDictionary[RoutesEnum.Sportsbook],
//   RoutesDictionary[RoutesEnum.LiveSports],
//   RoutesDictionary[RoutesEnum.Casino],
// ];

export interface AppBarProps {
  user: any;
  runTour: boolean;
  userBalance: any;
  tvOnOffResponses: boolean;
  tvOnOffAction: (a: boolean) => void;
  setTvUrlAction: (ch: string) => void;
  tvUrl: string;
  isTvOn: boolean;
  getTourStepsAction: () => void;
  tourSteps: any;
  getPromotionAction: () => void;
  promotions: any;
  rollover: any;
  ptpiGetCasinoBonusesAction: () => void;
  casinoBonuses: CasinoBonusesDto[];
  casinoBonus: CasinoBonusesDto | null;
  getPlayerRolloverAction: (x: any) => void;
  ptpiGetCasinoBonusAction: (x: any) => void;
  setCashierDialogAction: (data: any) => void;
  cashierDialog: any;
  isLoading: boolean;
  tvChannelsList: ITvChannels[];
  getTvChannelsAction: () => void;
  SetRunTourAction: (data: boolean) => void;
  identityDialogOpen: IIdentityDialog;
  toggleVerifyIdentityDialogAction: (state: IIdentityDialog) => void;
  shareableUrl: string | null;
  createCognitoRetryAction: () => void;
}

export const AppBar = ({
  createCognitoRetryAction,
  shareableUrl,
  toggleVerifyIdentityDialogAction,
  identityDialogOpen,
  user,
  userBalance,
  tourSteps,
  rollover,
  promotions,
  casinoBonuses,
  getPromotionAction,
  getPlayerRolloverAction,
  getTourStepsAction,
  ptpiGetCasinoBonusesAction,
  ptpiGetCasinoBonusAction,
  setCashierDialogAction,
  cashierDialog,
  //**LIVE TV Vars
  tvOnOffResponses,
  isTvOn,
  tvOnOffAction,
  getTvChannelsAction,
  tvChannelsList,
  isLoading,
  runTour,
  SetRunTourAction,
}: AppBarProps) => {
  const classes = useStyles();
  const [showBonus, setShowBonus] = React.useState<boolean>(false);

  //**LIVE TV Vars
  const defChannel = 'https://tvproj.live/channel1';
  const [channel, setChannel] = React.useState<string>(defChannel);
  const [nUrl, setNUrl] = React.useState<string>('');
  const [v, setV] = React.useState<boolean>(true);
  const handleChannel = (value: string) => {
    setV(false);
    const _value = `${value.slice(0, -5)}/prog_index.m3u8`;
    setChannel(value);
    setNUrl(_value);
    setTimeout(() => setV(true), 500);
  };

  useEffect(() => {
    getTvChannelsAction();
  }, []);

  const getRealAvailableBalanceLabel = (value: number) => {
    return (
      <span style={value < 0 ? { color: 'red' } : value > 0 ? { color: 'green' } : {}}>
        {value < 0 ? `-$${Math.abs(value)}` : `$${value}`}
      </span>
    );
  };

  return (
    <>
      <MuiAppBar position='fixed' color='transparent' elevation={3}>
        <Tour SetRunTourAction={SetRunTourAction} runTour={runTour} getTourStepsAction={getTourStepsAction} tourSteps={tourSteps} />
        <Toolbar classes={{ root: classes.toolbar }}>
          <Hidden xsDown>
            <Link href='#/sportsbook' className='tour-logoCnt'>
              <img className={classes.shrinkLogo} alt='logo' src={`${process.env.PUBLIC_URL}/logo.png`} />
            </Link>
          </Hidden>

          <div className={classes.toolbarRightItems}>
            {window.innerWidth >= 1024 && (
              <Button
                variant='contained'
                color='primary'
                style={{ marginRight: '50px' }}
                className='tour_header-watchBtn'
                onClick={() => {
                  tvOnOffAction(!tvOnOffResponses);
                }}
              >
                {tvOnOffResponses ? 'Close Live TV' : 'Watch Live TV'}
              </Button>
            )}

            <Hidden only={['xs']}>
              <div className='tour_header-username' style={{ display: 'flex' }}>
                <Typography style={{ lineHeight: '1rem', textAlign: 'right' }}>
                  {getRealAvailableBalanceLabel(
                    GetAmountWithOutDecimals(user?.player_type_id === 1 ? userBalance.RealAvailBalance : userBalance.CurrentBalance),
                  )}
                  <br />
                  <small style={{ fontSize: '.8rem' }}>{user.username}</small>
                </Typography>
                <UserIconPopover user={user} userBalance={userBalance} />
              </div>
            </Hidden>

            <Hidden xsDown>
              <Divider orientation='vertical' flexItem />
              <Typography className='tour_header-bonus' style={{ lineHeight: '1rem', textAlign: 'center' }}>
                <Button variant='contained' color='primary' className='buttonWithMotion' onClick={() => setShowBonus(true)}>
                  <span className='buttonWithMotionSpan' style={{ color: '#fff', fontSize: '12px' }}>
                    Bonus
                  </span>
                </Button>
                <small style={{ fontSize: '12px' }}>${GetAmountWithDecimals(userBalance?.FreePlayAmount ?? 0, 2)}</small>
              </Typography>

              {/* <Typography className='tour_header-username' style={{ lineHeight: '1rem', textAlign: 'center' }}>
                <Button
                  variant='contained'
                  color='primary'
                  className='tour_header-cashier buttonWithMotion'
                  onClick={() => {
                    getHistory().push('/bracket');
                  }}
                >
                  <span className='buttonWithMotionSpan'>
                    <img
                      src={`https://api.pdub.lv/src/images/providers//026d3491-df9b-43f8-9e28-4edf1c3eab4d.png`}
                      width='60'
                      style={{ marginTop: '0.5rem' }}
                    />
                  </span>
                </Button>
                <small style={{ fontSize: '.8rem' }}>WC 2022</small>
              </Typography> */}

              {/* <Typography className='tour_header-username' style={{ lineHeight: '1rem', textAlign: 'center' }}>
                <Button
                  variant='contained'
                  color='primary'
                  className='tour_header-cashier buttonWithMotion'
                  onClick={() => {
                    getHistory().push('/picks');
                  }}
                >
                  <span className='buttonWithMotionSpan'>
                    <img src={`https://api.pdub.lv/src/images/picks/ncaa.png`} width='60' style={{ marginTop: '0.5rem' }} />
                  </span>
                </Button>
                <small style={{ fontSize: '.8rem' }}>Contest</small>
              </Typography> */}
            </Hidden>

            <Hidden xsDown>
              <Divider orientation='vertical' flexItem />

              <Button
                variant='contained'
                color='secondary'
                className='tour_header-cashier'
                startIcon={<FontAwesomeIcon icon={['fad', 'sack-dollar']} />}
                onClick={() => setCashierDialogAction({ isShow: true, type: 'cashier' })}
              >
                <Typography variant='button'>Cashier</Typography>
              </Button>
            </Hidden>

            <Hidden xsDown>
              <Divider orientation='vertical' flexItem />

              <IconButton
                color='inherit'
                className='tour_header-chat'
                onClick={() => {
                  // openLOLiveChat();
                  getHistory().push('/live-chat');
                }}
              >
                <FontAwesomeIcon icon={['fad', 'comment']} />
              </IconButton>
            </Hidden>

            <Hidden only={['sm', 'md', 'lg', 'xl']}>
              {/* <Divider orientation='vertical' flexItem /> */}

              <Button
                variant='contained'
                color='secondary'
                startIcon={<FontAwesomeIcon icon={['fad', 'sack-dollar']} />}
                className='tour_header-cashier'
                onClick={() => setCashierDialogAction({ isShow: true, type: 'cashier' })}
                size='small'
              >
                <Typography variant='button'>Cashier</Typography>
              </Button>
            </Hidden>

            <Hidden only={['sm', 'md', 'lg', 'xl']}>
              <Divider orientation='vertical' flexItem className={'dividerTop'} />
              <Typography className='tour_header-bonus' style={{ lineHeight: '1rem', textAlign: 'center' }}>
                <Button variant='contained' color='primary' className='buttonWithMotion' onClick={() => setShowBonus(true)}>
                  <span className='buttonWithMotionSpan' style={{ color: '#fff', fontSize: '12px' }}>
                    Bonus
                  </span>
                </Button>
                <small style={{ fontSize: '12px' }}>${GetAmountWithDecimals(userBalance?.FreePlayAmount ?? 0, 2)}</small>
              </Typography>

              {/* <Typography className='tour_header-username' style={{ lineHeight: '1rem', textAlign: 'center' }}>
                <Button
                  variant='contained'
                  color='primary'
                  className='tour_header-cashier buttonWithMotion'
                  onClick={() => {
                    getHistory().push('/bracket');
                  }}
                >
                  <span className='buttonWithMotionSpan'>
                    <img
                      src={`https://api.pdub.lv/src/images/providers//026d3491-df9b-43f8-9e28-4edf1c3eab4d.png`}
                      width='60'
                      style={{ marginTop: '0.5rem' }}
                    />
                  </span>
                </Button>
                <small style={{ fontSize: '.8rem' }}>WC 2022</small>
              </Typography> */}
              {/* <Typography className='tour_header-username' style={{ lineHeight: '1rem', textAlign: 'center' }}>
                <Button
                  variant='contained'
                  color='primary'
                  className='tour_header-cashier buttonWithMotion'
                  onClick={() => {
                    getHistory().push('/picks');
                  }}
                >
                  <span className='buttonWithMotionSpan'>
                    <img src={`https://api.pdub.lv/src/images/picks/ncaa.png`} width='60' style={{ marginTop: '0.5rem' }} />
                  </span>
                </Button>
                <small style={{ fontSize: '.8rem' }}>Contest</small>
              </Typography> */}
            </Hidden>

            <Hidden only={['sm', 'md', 'lg', 'xl']}>
              <Divider orientation='vertical' flexItem className={`dividerTop`} />
              <Typography style={{ lineHeight: '1rem', textAlign: 'right' }} className={`tour_header-username`}>
                {getRealAvailableBalanceLabel(
                  GetAmountWithOutDecimals(user?.player_type_id === 1 ? userBalance.RealAvailBalance : userBalance.CurrentBalance),
                )}
                <br />
                <small style={{ fontSize: '.8rem' }}>{user.username}</small>
              </Typography>
              <UserIconPopover user={user} userBalance={userBalance} />
            </Hidden>
          </div>
        </Toolbar>
        <AdDialog />
        {identityDialogOpen.open == true && (
          <IdentityDialog
            toggleIdentityDialog={toggleVerifyIdentityDialogAction}
            identityDialogOpen={identityDialogOpen.open}
            shareableUrl={shareableUrl}
            createCognitoRetryAction={createCognitoRetryAction}
            cognitoMatch={user.cognito_match}
            message={identityDialogOpen.message}
            isLoading={isLoading}
            success={identityDialogOpen.result}
          />
        )}
      </MuiAppBar>
      <Dialog
        open={showBonus}
        onClose={() => setShowBonus(false)}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        PaperProps={{
          style: {
            backgroundColor: '#fff8f833',
            margin: '1%',
          },
        }}
      >
        <DialogBonusCard
          promotions={promotions}
          getPromotionAction={getPromotionAction}
          setShowBonus={setShowBonus}
          rollover={rollover}
          getPlayerRolloverAction={getPlayerRolloverAction}
          playerNumber={user.username}
          casinoBonuses={casinoBonuses}
          ptpiGetCasinoBonusesAction={ptpiGetCasinoBonusesAction}
          ptpiGetCasinoBonusAction={ptpiGetCasinoBonusAction}
          isLoading={isLoading}
        ></DialogBonusCard>
      </Dialog>
      <CashierDialog cashierDialog={cashierDialog} onClose={() => setCashierDialogAction({ isShow: false, type: 'cashier' })} />

      {isTvOn && (
        <Draggable handle='#draggable-fixed-video' cancel={'[class*="MuiDialogContent-root"]'}>
          <div className={classes.fixedVideoPlayer} aria-labelledby='draggable-fixed-video'>
            <div className={classes.tvClose}>
              <CancelIcon onClick={() => tvOnOffAction(!tvOnOffResponses)} />
            </div>
            {v && (
              <div id='draggable-fixed-video'>
                <Player url={nUrl} />
              </div>
              // <iframe id='draggable-fixed-video' src={channel} className={classes.frame} frameBorder='0'></iframe>
            )}
            <FormControl fullWidth className={classes.channelSelect}>
              <Select fullWidth value={channel} onChange={e => handleChannel((e.target.value as unknown) as string)}>
                <MenuItem value={defChannel}>
                  <em>Select Channel</em>
                </MenuItem>
                {tvChannelsList
                  .filter(item => item.is_visible && item.channel_embed_url !== '')
                  .map((item: any, i: number) => {
                    return (
                      <MenuItem value={item.channel_embed_url} key={i}>
                        <img
                          src={`https://dev-api.pdub.lv/api/tv/get-image-from-url?url=${encodeURIComponent(item.logo)}`}
                          width='50'
                          height='50'
                          style={{ marginRight: '10px' }}
                        />
                        {item?.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
        </Draggable>
      )}
    </>
  );
};
