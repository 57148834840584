import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { API_PATH } from '../../../constants';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const GET_BONUSES = `GET_BONUSES${suffix}`;

export const getBonusesAction = createAction<any>(GET_BONUSES);

const GET_BONUSES_SUCCESS = `GET_BONUSES_SUCCESS${suffix}`;
const getBonusesSuccessAction = createAction<any[]>(GET_BONUSES_SUCCESS);

const GET_BONUSES_ERROR = `GET_BONUSES_ERROR${suffix}`;
const getBonusesErrorAction = createAction<string>(GET_BONUSES_ERROR);

function* getBonusesWatcher(): SagaIterator {
  yield takeEvery(GET_BONUSES, getBonusesWorker);
}
mergeSaga(getBonusesWatcher);

function* getBonusesWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getBonusesApi, action.payload);
    yield put(getBonusesSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(getBonusesErrorAction(e));
  }
}

const getBonusesApi = (data: any): Request => {
  const param = `?player_number=${data.player_number}&method=${data.methodId}`;
  return vipApi(API_PATH.CASHIER.GET_BONUSES(param), 'get', {
    player_number: data.player_number,
    method: data.methodId,
  });
};

const reduceHandlers = {
  [GET_BONUSES]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_BONUSES_SUCCESS]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    availableBonuses: action.payload.data,
  }),
  [GET_BONUSES_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
